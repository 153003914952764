import { databaseKey } from "./common/database_key";
import { fetchData, pushData } from "./firebase/firebase_methods";

export const getNotificationRef = (uid) => {
  const collectionName = databaseKey();
  return fetchData(`${collectionName}/notification/${uid}`);
};

export const notificationRef = (collectionName, userName) => {
  return fetchData(`${collectionName}/notification/${userName}`);
};

export const sendNotification = async (userName, message) => {
  const notificationRef = getNotificationRef(userName);
  await pushData(notificationRef, {
    message: message,
    read: false,
    timestamp: new Date().toString(),
  });
};
