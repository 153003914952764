import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebaseConfig";
import { databaseKey } from "./common/database_key";
import { onValue } from "firebase/database";
import { notificationRef } from "./notification_ref";
import MathApk from "./math_apk";

function Headers() {
  const [user, setUser] = useState(localStorage.getItem("userName") || "");
  const [count, setCount] = useState(0);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.displayName) {
          setUser(user.displayName || "");
        } else if (user.isAnonymous) {
          setUser(user.uid);
        }
      } else {
        setUser("");
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const handleUnreadMessages = async () => {
      if (user) {
        try {
          const collectionName = databaseKey();
          const notification_ref = notificationRef(collectionName, user);
          const unsubscribe = onValue(notification_ref, (snapshot) => {
            const data = snapshot.val();
            if (data) {
              let unreadCount = 0;
              for (const messageId in data) {
                if (!data[messageId].read) {
                  unreadCount++;
                }
              }
              setCount(unreadCount);
            }
          });

          return () => unsubscribe();
        } catch (error) {
          console.error("Error fetching messages:", error);
        }
      }
    };

    handleUnreadMessages();
  }, [user]);

  const handleMobileNavToggle = () => {
    const navbar = document.querySelector("#navbar");
    const mobileNavToggle = document.querySelector(".mobile-nav-toggle");

    navbar.classList.toggle("navbar-mobile");
    mobileNavToggle.classList.toggle("bi-list");
    mobileNavToggle.classList.toggle("bi-x");
  };

  const handleDropdownToggle = (e) => {
    const navbar = document.querySelector("#navbar");
    const dropdown = e.currentTarget.nextElementSibling;

    if (navbar.classList.contains("navbar-mobile")) {
      e.preventDefault();
      dropdown.classList.toggle("dropdown-active");
    }
  };

  return (
    <header id="header" className="fixed-top">
      <div className="container d-flex align-items-center">
        <h1 className="logo me-auto">
          {/* <img src={logo} className="App-logo" alt="Increase math calculation speed " title="Improve your Skills | Math Speed Up | Practice Maths"/> */}

          <a href="/" title="home" aria-label="home">
            <img
              src={process.env.PUBLIC_URL + "/assets/img/logo.webp"}
              alt="Increase math calculation speed "
              title="Improve your Skills | Math Speed Up | Practice Maths"
              style={{ width: "150px", height: "50px" }}
            />
          </a>
        </h1>
        <nav id="navbar" className="navbar order-last order-lg-0">
          <ul>
            <li>
              <a href="/" title="Home">
                Home
              </a>
            </li>
            <li className="dropdown">
              <a
                onClick={handleDropdownToggle}
                title="Challenges"
                href="/practice"
              >
                <span>Challenges</span> <i className="bi bi-chevron-down"></i>
              </a>
              <ul>
                <li>
                  <a href="/practice" title="Practice">
                    Practice
                  </a>
                </li>
                <li>
                  <a href="/against_time" title="Against Time">
                    Against Time
                  </a>
                </li>
                <li>
                  <a href="/missing-number" title="Missing Number">
                    Missing Number
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href="/readers" title="Readers">
                Readers
              </a>
            </li>
            <li>
              <a href="/player-vs-player" title="Player Vs Player">
                Player vs Player
              </a>
            </li>
            <li>
              <a href="/quiz" title="Quiz">
                Quiz
              </a>
            </li>

            <li className="dropdown">
              <a onClick={handleDropdownToggle} title="Services" href="/about">
                <span>Services</span> <i className="bi bi-chevron-down"></i>
              </a>
              <ul>
                <li>
                  <a href="/about" title="About">
                    About
                  </a>
                </li>
                <li>
                  <a href="/contact" title="Contact">
                    Contact
                  </a>
                </li>
                <li>
                  <a href="/feedback" title="Feedback">
                    Feedback
                  </a>
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <a
                href="/games/multiplication"
                onClick={handleDropdownToggle}
                title="Games"
              >
                <span>Games</span> <i className="bi bi-chevron-down"></i>
              </a>
              <ul>
                <li>
                  <a href="/games/multiplication" title="Multiplication">
                    Multiplication
                  </a>
                </li>
                <li>
                  <a href="/games/quoridor_game" title="QuoridorGame">
                    QuoridorGame
                  </a>
                </li>
                <li>
                  <a href="/games/memorygame" title="Memory Game">
                    Memory Game
                  </a>
                </li>
                <li>
                  <a
                    href="/games/arithmetic-memory-game"
                    title="Arithmetic Memory Game"
                  >
                    Arithmetic Memory Game
                  </a>
                </li>
              </ul>
            </li>
            {user ? (
              <>
                <li className="dropdown">
                  <a
                    href="/user_profile"
                    onClick={handleDropdownToggle}
                    title="Account"
                  >
                    <span>Account</span> <i className="bi bi-chevron-down"></i>
                  </a>
                  <ul>
                    <li>
                      <a href="/user_profile" title="Profile">
                        Profile
                      </a>
                    </li>
                    <li>
                      <a href="/sign_out" title="Logout">
                        Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              <li>
                <a href="/sign-up" title="Sign Up">
                  SignUp
                </a>
              </li>
            )}
            {user && (
              <li>
                <a href="/notification" title="Notification">
                  <span className="notification-icon">
                    <i
                      className="bi bi-bell-fill"
                      style={{ fontSize: "24px" }}
                    ></i>
                  </span>
                  <span className="notification-text">Notification</span>
                  {count > 0 && <span className="unread-count">{count}</span>}
                </a>
              </li>
            )}
          </ul>
          <MathApk />
          <i
            className="bi bi-list mobile-nav-toggle"
            onClick={handleMobileNavToggle}
          ></i>
        </nav>

        {/* <a href="courses.html" className="get-started-btn" title="Get Started" >Get Started</a> */}
      </div>
    </header>
  );
}

export default Headers;
