import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import blogData from "./blog_data";
import BlogMeta from "../../metas/blog";
import BlogContent from "./blog-content";
import "../../assets/css/blog.css";
import VedicMath from "./vedic_math";
import { useNavigate } from "react-router-dom";

const Blog = () => {
  const { id } = useParams();
  const selectedBlog = blogData.find((blog) => blog.id === id);
  const currentIndex = blogData.findIndex((blog) => blog.id === id);
  const [index, setIndex] = useState(currentIndex);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  if (!selectedBlog) {
    return (
      <div className="container">
        <h2>Blog not found</h2>
        <p>Sorry, the blog you're looking for doesn't exist.</p>
      </div>
    );
  }

  let h4Headings = [];
  let keywords = [];

  if (id !== "vedic-math") {
    h4Headings = selectedBlog.content.match(/<h4[^>]*>.*?<\/h4>/gs) || [];
    keywords = h4Headings.map((heading) =>
      heading.replace(/<\/?h4[^>]*>|<strong[^>]*>|<\/strong>/g, "")
    );
  }

  const handlePrevious = () => {
    const previousIndex = currentIndex - 1;
    if (previousIndex >= 0) {
      setIndex(previousIndex);
      navigate(`/readers/${blogData[previousIndex].id}`);
    }
  };

  const handleNext = () => {
    const nextIndex = currentIndex + 1;
    if (nextIndex < blogData.length) {
      setIndex(nextIndex);
      navigate(`/readers/${blogData[nextIndex].id}`);
    }
  };

  const previousBlog = currentIndex > 0 ? blogData[currentIndex - 1] : null;
  const nextBlog =
    currentIndex < blogData.length - 1 ? blogData[currentIndex + 1] : null;
  const isLastBlog = currentIndex === blogData.length - 1;

  return (
    <>
      <BlogMeta title={selectedBlog.title} keywords={keywords} />
      <div className="breadcrumbs" data-aos="fade-in">
        <div className="container">
          <h2>Welcome to our Math Blog</h2>
          <p>
            Explore the fascinating world of mathematics through our insightful
            blog posts.
          </p>
        </div>
      </div>
      <div className="blog-container">
        {id !== "vedic-math" ? (
          <BlogContent
            title={selectedBlog.title}
            content={selectedBlog.content}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            index={currentIndex}
            previousTitle={previousBlog ? previousBlog.title : null}
            nextTitle={nextBlog ? nextBlog.title : null}
            isLastBlog={isLastBlog}
          />
        ) : (
          <VedicMath />
        )}
      </div>
    </>
  );
};

export default Blog;
