import React from "react";

const RulesSection = () => {
  return (
    <>
      <div className="container">
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#howToCreateRoom"
                aria-expanded="false"
                aria-controls="howToCreateRoom"
              >
                How To Create Room
              </button>
            </h2>
            <div
              id="howToCreateRoom"
              className="accordion-collapse collapse"
              aria-labelledby="howToCreateRoom"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul>
                  <li>
                    <h5>Step 1: Host Creates a Room</h5>
                    <div>
                      <p>
                        The game starts with the host creating a room. To create
                        a room, the host can:
                      </p>
                      <ol>
                        <li>
                          Select a question type from the available options.
                        </li>
                        <li>Enter the number of questions for the game.</li>
                        <li>Enter the number of rounds for the game.</li>
                        <li>Click the "Create Room" button.</li>
                      </ol>
                      <p>
                        After creating the room, the host can click the "Copy
                        URL to Clipboard" button to copy the room link and share
                        it with friends and family.
                      </p>
                    </div>
                  </li>
                  <li>
                    <h5>Step 2: Participants Join the Room</h5>
                    <p>
                      Participants can join the room by accessing the game URL .
                      They will need to provide their email address and click
                      the "Submit" button. Once a participant joins, they need
                      to click "Ready" button.
                    </p>
                  </li>
                  <li>
                    <h5>Step 3: Host Starts the Game</h5>
                    <p>
                      The host can start the game by clicking the "Start" button
                      when all participants are ready.
                    </p>
                  </li>
                  <li>
                    <h5>Step 4: Answer Questions</h5>
                    <p>
                      After the host starts the game, participants will receive
                      questions one by one. They need to answer each question.
                      If a participants provides the correct answer, they will
                      proceed to the next question.
                    </p>
                  </li>
                  <li>
                    <h5>Step 5: Game End</h5>
                    <p>
                      The participant who answers all questions correctly and is
                      fastest, will be declared as winner, and the game will end
                      and all participants see the result.
                    </p>
                  </li>
                  <li>
                    <h5>Step 6: Restart the Game (Host Only)</h5>
                    <p>
                      If the host wishes to restart the game, they can click the
                      "Restart" button. This action resets the questions, and
                      game status, allowing for a new round and also host add
                      another participnats.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="howToPlayHeader">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#howToPlay"
                aria-expanded="false"
                aria-controls="howToPlay"
              >
                Player vs Player Math Game - MathSpeedUp.com
              </button>
            </h2>
            <div
              id="howToPlay"
              className="accordion-collapse collapse"
              aria-labelledby="howToPlay"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <section>
                  <h2>Host Your Game Room</h2>
                  <p>
                    Get ready for hours of competitive math gaming with
                    MathSpeedUp.com's Player vs Player feature!
                  </p>
                  <ul>
                    <li>Create rooms with your preferred question types:</li>
                    <ul>
                      <li>Multiplier (2 digit)</li>
                      <li>Multiplier (3 digit)</li>
                      <li>Table (up to 30)</li>
                      <li>Sum (2 digit)</li>
                      <li>Sum (3 digit)</li>
                      <li>Sum (4 digit)</li>
                      <li>Sum (5 digit)</li>
                      <li>Sum (Mix of digits)</li>
                      <li>Only Square</li>
                      <li>Only Cube</li>
                      <li>Square and Cube Mix</li>
                      <li>Subtract (2 digit)</li>
                      <li>Subtract (3 digit)</li>
                      <li>Subtract (4 digit)</li>
                      <li>Subtract (5 digit)</li>
                      <li>Subtract (Mix of digits)</li>
                    </ul>
                    <li>Set the number of questions and difficulty level.</li>
                    <li>
                      Host the ultimate math showdown with your own unique room
                      URL.
                    </li>
                  </ul>
                </section>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="challengeFriendsHeader">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#challengeFriends"
                aria-expanded="false"
                aria-controls="challengeFriends"
              >
                Challenge Friends and Family
              </button>
            </h2>
            <div
              id="challengeFriends"
              className="accordion-collapse collapse"
              aria-labelledby="challengeFriends"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <section>
                  <h2>Challenge Friends and Family</h2>
                  <p>
                    Once your room is ready, it's time to invite your loved ones
                    to join the fun.
                  </p>
                  <ul>
                    <li>Share the unique room URL with friends and family.</li>
                    <li>
                      Compete head-to-head in an exciting multiplayer math
                      challenge.
                    </li>
                    <li>Enjoy flexible gameplay at your own pace.</li>
                    <li>Sharpen your math skills while having a blast!</li>
                  </ul>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RulesSection;
