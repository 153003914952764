// Todo : Make different type of missing number series.
import React, { useState, useEffect } from "react";
import MissingNumberMetas from "../metas/missingnumber";
import userWon from "../components/game_record";
import "../assets/css/common_margin.css";
import { trackButtonClick } from "./event_track";

const MissingNumber = () => {
  const sequenceType = 10;
  const startingPrime = [
    2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 43, 47, 53, 59, 61, 67, 71,
    73, 79, 83, 89, 97,
  ];
  const [sequence, setSequence] = useState([]);
  const [missingIndex, setMissingIndex] = useState(0);
  const [correctAnswer, setCorrectAnswer] = useState(0);
  const [userAnswer, setUserAnswer] = useState("");
  const [isCorrect, setIsCorrect] = useState(null);
  const [randomSequenceType, setRandomSequenceType] = useState(0);
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const storedName = localStorage.getItem("userName") || "";
    setUserName(storedName);
  }, []);

  const generateRandomNumber = () => {
    const randomNumber = Math.floor(Math.random() * sequenceType);
    setRandomSequenceType(randomNumber);
  };

  const generateSequence = () => {
    trackButtonClick("Next Sequence Button");

    generateRandomNumber();
    const newSequence = [];
    const baseNumber = Math.floor(Math.random() * 10) + 1; // Generate a random base number between 1 and 10 1
    const multiplier = Math.floor(Math.random() * 10) + 1; // Generate a random multiplier between 1 and 10 2
    setMissingIndex(Math.floor(Math.random() * 6)); // Index of the missing number 5
    const startingPrimeIndex = Math.floor(
      Math.random() * startingPrime.length - 7
    );
    for (let i = 0; i < 6; i++) {
      if (i === missingIndex) {
        newSequence.push(null); // Placeholder for the missing number
      } else {
        const randomNumber = generateSequenceNumber(
          baseNumber,
          i,
          multiplier,
          startingPrimeIndex
        );

        newSequence.push(randomNumber);
      }
    }

    setSequence(newSequence);
    setCorrectAnswer(
      generateSequenceNumber(
        baseNumber,
        missingIndex,
        multiplier,
        startingPrimeIndex
      )
    );
    setUserAnswer("");
    setIsCorrect(null);
  };

  const generateSequenceNumber = (
    baseNumber,
    index,
    multiplier,
    startingPrimeIndex
  ) => {
    if (startingPrimeIndex < 0 || startingPrimeIndex >= startingPrime.length) {
      startingPrimeIndex = 0;
    }

    switch (randomSequenceType) {
      case 0:
        return baseNumber * baseNumber * (index + 1);
      case 1:
        return baseNumber * baseNumber * baseNumber * (index + 1);
      case 2:
        return baseNumber * (index + 1) * multiplier;
      case 3:
        return baseNumber * (index + 1) - multiplier;
      case 4:
        return baseNumber * (index + 1) * 0.5;
      case 5:
        return baseNumber * (index + 1) + 0.5;
      case 6:
        return baseNumber * (index + 1) * startingPrime[startingPrimeIndex];
      case 7:
        return baseNumber * (index + 1) + multiplier;
      case 8:
        const fibonacciSequence = generateFibonacciSequence(6);
        return baseNumber * (index + 1) * fibonacciSequence[index];
      case 9:
        const nthPrime = getNthPrime(index + 1);
        return baseNumber * (index + 1) * nthPrime;
      default:
        return baseNumber * (index + 1) * multiplier;
    }
  };

  const generateFibonacciSequence = (length) => {
    const fibonacciSequence = [1, 1];
    for (let i = 2; i < length; i++) {
      fibonacciSequence[i] =
        fibonacciSequence[i - 1] + fibonacciSequence[i - 2];
    }
    return fibonacciSequence;
  };

  const isPrime = (num) => {
    for (let i = 2; i <= num / 2; i++) {
      if (num % i === 0) return false;
    }
    return num > 1;
  };

  const getNthPrime = (n) => {
    let count = 0,
      num = 1;
    while (count < n) isPrime(++num) && count++;
    return num;
  };

  const checkAnswer = (ans = "") => {
    trackButtonClick("Missing Number check answer Button");

    const userEnteredAnswer =
      ans === "" ? parseFloat(userAnswer) : parseFloat(ans);

    const isCorrectAnswerInteger = Number.isInteger(correctAnswer);

    if (
      isCorrectAnswerInteger &&
      parseInt(userEnteredAnswer) === correctAnswer
    ) {
      setIsCorrect(true);
      generateSequence();
      userWon(userName, "other");
    } else if (
      !isCorrectAnswerInteger &&
      parseFloat(userEnteredAnswer) === correctAnswer
    ) {
      setIsCorrect(true);
      generateSequence();
    } else {
      setIsCorrect(false);
    }
  };

  const cardStyle = {
    width: "90px",
    height: "90px",
    backgroundColor: "#e0e0e0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px",
    cursor: "pointer",
    float: "left",
    margin: "10px",
  };

  useEffect(() => {
    generateSequence();
  }, []);

  return (
    <>
      <MissingNumberMetas />
      <main id="main">
        <div className="breadcrumbs" data-aos="fade-in">
          <div className="container">
            <h2>Missing Number</h2>
            <p>Find the missing number.</p>
          </div>
        </div>
        <section id="memoryGame" className="memoryGame">
          <div className="container" data-aos="fade-up">
            <div className="row mt-5">
              <div className="missing-number-game text-center">
                <h2>Missing Number Game</h2>
                <div className="sequence text-center">
                  <h1>
                    {sequence.map((number, index) => (
                      <span key={index} style={cardStyle}>
                        {number !== null ? number : "??"}
                      </span>
                    ))}
                  </h1>
                  <br />
                </div>
                <div className="answer">
                  <input
                    type="number"
                    value={userAnswer}
                    id="answer"
                    name="answer"
                    onChange={(e) => {
                      setUserAnswer(e.target.value);
                      checkAnswer(e.target.value);
                    }}
                    style={{ margin: "5px" }}
                    aria-label="answer"
                  />
                  {/*randomSequenceType} - 
                  {correctAnswer*/}
                  <button onClick={checkAnswer} className="btn btn-success">
                    Check
                  </button>
                </div>
                {isCorrect === true && <p className="correct">Correct!</p>}
                <button onClick={generateSequence} className="btn btn-success">
                  Next Sequence
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default MissingNumber;
