import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const ContactMetas = () => {
  const location = useLocation();

  useEffect(() => {
    // Check if the path contains "contact-us"
    if (location.pathname.includes('contact-us')) {
      const link = document.createElement('link');
      link.rel = 'canonical';
      link.href = 'https://www.mathspeedup.com/contact';
      document.head.appendChild(link);

       // Add hreflang="en" for English version
       link.hreflang = 'en';

      // Cleanup when the component is unmounted
      return () => {
        document.head.removeChild(link);
      };
    }
  }, [location]);

  return (
    <div>
      <Helmet>
        <title>Contact Us | Get in Touch with Us</title>
        <meta charSet="UTF-8" /> 
        <meta name="title" content="Contact Us | Get in Touch with Us | MathSpeedUp" />
        <meta name="description" content="Have questions, suggestions, or inquiries? Contact us and get in touch! We're here to assist you. Reach out to our team for any inquiries or feedback you have. Whether you're looking for support, partnership opportunities, or just want to share your thoughts, we'd love to hear from you!" />
        <meta name="keywords" content="Contact Us, Get in Touch, Contact MathSpeedUp, Support, Inquiries, Feedback, Partnership Opportunities, Contact Information" />
        <meta property="article:published_time" content="2023-08-23T01:30:57+00:00" />
      </Helmet>
    </div>
  );
};

export default ContactMetas;
