import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import favIconImg from "../../../assets/img/favicon.webp";

const Header = ({ handleChatClose }) => {
  return (
    <div className="chat-header">
      <h4>
        <img src={favIconImg} height={35} /> Messages
      </h4>
      <button
        onClick={handleChatClose}
        className="close-button"
        aria-label="close chat"
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
  );
};

export default Header;
