import React, { useState } from "react";

const Dropdown = ({ questionType, questionTypeChangeHandler }) => {
  const [practicePage, setPracticePage] = useState(
    window.location.pathname.includes("/practice")
  );

  let className = practicePage
    ? "form-control text-center"
    : "form-control text-left";

  return (
    <>
      <select
        name="question_type"
        id="question_type"
        className={className}
        value={questionType}
        onChange={(e) => questionTypeChangeHandler(e)}
        aria-label="Select a question type"
      >
        <option value="0">Multiplier (2 digit)</option>
        <option value="7">Multiplier (3 digit)</option>
        <option value="1">Table (till 30)</option>
        <option value="2">Sum (2 digit)</option>
        <option value="3">Sum (3 digit)</option>
        <option value="6">Sum (Mix of digits)</option>
        <option value="11">Subtract (2 digit)</option>
        <option value="12">Subtract (3 digit)</option>
        <option value="15">Subtract (Mix of digit)</option>
        <option value="8">Only Square</option>
        <option value="9">Only Cube</option>
        <option value="10">Square and Cube Mix</option>
        <option value="random">Random Questions</option>
      </select>
    </>
  );
};

export default Dropdown;
