const WhyUs = () => {
  return (
    <>
      <section id="why-us" className="why-us">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-4 d-flex align-items-stretch">
              <div className="content">
                <h1>Why Choose MathSpeedUp?</h1>
                <p>
                  MathSpeedUp's Practice Area is a versatile feature that caters
                  to users of all skill levels. Whether you're a beginner or an
                  advanced learner, you can access a wide range of random
                  arithmetic questions to challenge and strengthen your math
                  abilities.
                </p>
                <div className="text-center">
                  <a
                    href="/about"
                    className="more-btn"
                    title="Learn More About Us"
                  >
                    Learn More About Us <i className="bx bx-chevron-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-8 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className="icon-boxes d-flex flex-column justify-content-center">
                <div className="row">
                  <div className="col-xl-4 d-flex align-items-stretch">
                    <div className="icon-box mt-4 mt-xl-0">
                      <i className="bx bx-receipt"></i>
                      <h2 style={{ fontSize: "x-large" }}>
                        Engaging Memory Game
                      </h2>
                      <p>
                        The Memory Game offered by MathSpeedUp adds a delightful
                        twist to learning. Users can improve their memory
                        retention while having fun with this interactive
                        activity. It not only enhances math-related memory but
                        also helps users develop cognitive skills.
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-4 d-flex align-items-stretch">
                    <div className="icon-box mt-4 mt-xl-0">
                      <i className="bx bx-cube-alt"></i>
                      <h2 style={{ fontSize: "x-large" }}>
                        Adaptive Learning:
                      </h2>
                      <p>
                        {" "}
                        MathSpeedUp employs adaptive learning technology that
                        tailors the difficulty level of questions to match each
                        user's proficiency. This ensures that learners are
                        continually challenged while avoiding frustration from
                        overly complex questions or boredom from repetitive
                        ones.
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-4 d-flex align-items-stretch">
                    <div className="icon-box mt-4 mt-xl-0">
                      <i className="bx bx-images"></i>
                      <h2 style={{ fontSize: "x-large" }}>
                        User-Friendly Interface
                      </h2>
                      <p>
                        The intuitive and user-friendly interface of MathSpeedUp
                        makes navigation effortless for users of all ages.
                        Whether you're a tech-savvy individual or just starting
                        with digital learning, you'll find the application easy
                        to use and enjoy.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyUs;
