import React from "react";
import { Helmet } from "react-helmet";

const FeedbackMeta = () => {
  return (
    <div>
      <Helmet>
        <title>MathSpeedUp - Feedback</title>
        <meta charSet="UTF-8" />
        <meta name="title" content="MathSpeedUp - Feedback" />
        <meta
          name="description"
          content="Share your thoughts, ideas, and feedback with MathSpeedUp. Your input helps us improve and serve you better. We value your opinion!"
        />
        <meta
          name="keywords"
          content="MathSpeedUp, Feedback, Thoughts, Ideas, Improvement"
        />
        <meta
          property="article:published_time"
          content="2023-08-23T01:30:57+00:00"
        />
      </Helmet>
    </div>
  );
};

export default FeedbackMeta;
