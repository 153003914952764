import React from 'react';
import { Helmet } from 'react-helmet';

const ReadersMeta = () => {
  return (
    <div>
      <Helmet>
        <title>Math Speed Boost - Blogs for Calculation Mastery</title>
        <meta charSet="UTF-8" /> 
        <meta name="title" content="Math Speed Boost - Blogs for Calculation Mastery" />
        <meta name="description" content="Explore blogs and content designed to enhance your math calculation speed. Find valuable tips, techniques, and strategies for mastering mathematical calculations efficiently." />
        <meta name="keywords" content="math, calculation speed, mathematics, learning, blogs, techniques, tips" />
        <meta property="article:published_time" content="2023-09-17T01:30:57+00:00" />
      </Helmet>
    </div>
  );
};

export default ReadersMeta;

