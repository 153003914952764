import React from "react";

const ParticipantList = ({
  participants,
  roomStatus,
  questionLength,
  hostName,
  handleLeaveClick,
}) => {
  return (
    <div className="col-lg mt-2 mt-lg-0">
      <div className="current-participants-details">
        <div
          className="text-center"
          style={{ height: "100px", overflow: "auto" }}
        >
          <button
            onClick={handleLeaveClick}
            className="btn btn-danger"
            style={{ display: "inline-block" }}
          >
            Leave
          </button>
          <div style={{ clear: "both" }}></div>
          {participants
            .filter(
              (participant) =>
                roomStatus !== "inProgress" || participant.status !== "pending"
            )
            .sort((a, b) => b.questionIndex - a.questionIndex)
            .map((participant, index) => {
              const isHostName = participant.userName === hostName;
              return (
                !isHostName && (
                  <p key={index}>
                    {`${participant.userName.slice(0, 9) + " "} : ${
                      participant.questionIndex < questionLength
                        ? participant.questionIndex + 1
                        : questionLength
                    } : ${participant.status}`}
                  </p>
                )
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ParticipantList;
