import { database } from "../../firebaseConfig";
import {
  push,
  ref,
  update,
  get,
  set,
  onValue,
  off,
  remove,
} from "firebase/database";
import { handleError } from "../common/error_method";

export const fetchData = (path) => {
  return ref(database, path);
};

export const getData = async (ref) => {
  return await get(ref);
};

export const updateData = async (path, data) => {
  await update(path, data);
};

export const setData = async (path, data) => {
  await set(path, data);
};

export const pushData = async (path, data) => {
  const ref = await push(path, data);
  return ref;
};

export const subscribeToData = (ref, callback) => {
  const onDataChange = (snapshot) => {
    callback(snapshot.exists() ? snapshot.val() : null);
  };

  onValue(ref, onDataChange);

  return () => {
    off(ref, "value", onDataChange);
  };
};

export const deleteData = (path) => {
  const ref = fetchData(path);
  remove(ref)
    .then(() => {
      console.log("delete data successfully ");
    })
    .catch((error) => {
      handleError("Data could not be removed. " + error);
    });
};
