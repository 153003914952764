import React, { useState, useEffect } from "react";
import MemoryGameMetas from "../metas/memorygame";
import userWon from "../components/game_record";
import "../assets/css/common_margin.css";
import { trackButtonClick } from "./event_track";

const MemoryGame = () => {
  const [cards, setCards] = useState([]);
  const [initialReveal, setInitialReveal] = useState(true);
  const [selectedCardValue, setSelectedCardValue] = useState(null);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [matchedCards, setMatchedCards] = useState([]);
  const [gameCompleted, setGameCompleted] = useState(false);
  const [remainingTime, setRemainingTime] = useState(10);
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const storedUserName = localStorage.getItem("userName") || "";
    setUserName(storedUserName);
  }, []);

  useEffect(() => {
    // Generate an array of 18 elements with values from 1 to 9, each repeated twice
    const numbers = Array.from({ length: 9 }, (_, index) => index + 1);
    const duplicatedNumbers = [...numbers, ...numbers];
    const shuffledNumbers = duplicatedNumbers.sort(() => Math.random() - 0.5);
    setCards(shuffledNumbers);

    // Start the game with an initial reveal of 10 seconds
    const initialRevealTimer = setTimeout(() => {
      setInitialReveal(false);
    }, 10000);

    // Start the timer
    let timer;
    if (initialReveal) {
      timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    }

    // Clear the timer when the component unmounts
    return () => {
      clearTimeout(initialRevealTimer);
      clearInterval(timer);
    };
  }, []);

  const handleCardClick = (index) => {
    // If the initial reveal is not over or the game is completed, do nothing
    if (initialReveal || gameCompleted) return;

    // If the card is already matched, do nothing
    if (matchedCards.includes(index)) return;

    // If the card is already selected, deselect it
    if (selectedCardIndex === index) {
      setSelectedCardValue(null);
      setSelectedCardIndex(null);
      return;
    }

    // If no card is currently selected, store the value and index of the clicked card
    if (selectedCardValue === null) {
      setSelectedCardValue(cards[index]);
      setSelectedCardIndex(index);
    } else {
      // Check if the clicked card matches the previously selected card
      if (cards[index] === selectedCardValue && index !== selectedCardIndex) {
        // Mark both cards as matched
        setMatchedCards([...matchedCards, index, selectedCardIndex]);
        setSelectedCardValue(null);
        setSelectedCardIndex(null);

        // Check if all cards are matched and set the game as completed
        if (matchedCards.length + 2 === cards.length) {
          setGameCompleted(true);
          userWon(userName, "other");
        }
      } else {
        // Unselect the previously selected card after a short delay
        setSelectedCardValue(cards[index]);
        setSelectedCardIndex(index);
      }
    }
  };

  const cardStyle = {
    width: "90px",
    height: "90px",
    backgroundColor: "#e0e0e0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px",
    cursor: "pointer",
    float: "left",
    margin: "10px",
  };

  const flippedCardStyle = {
    ...cardStyle,
    backgroundColor: "#8bc34a",
  };

  const handleRestart = () => {
    trackButtonClick("Memory game page");

    setInitialReveal(true);
    setSelectedCardValue(null);
    setSelectedCardIndex(null);
    setMatchedCards([]);
    setGameCompleted(false);

    // Start the game with an initial reveal of 10 seconds
    const initialRevealTimer = setTimeout(() => {
      setInitialReveal(false);
    }, 10000);

    // Shuffle the cards again
    const shuffledNumbers = cards.sort(() => Math.random() - 0.5);
    setCards([...shuffledNumbers]);

    clearTimeout(initialRevealTimer);
  };

  return (
    <>
      <MemoryGameMetas />
      <main id="main">
        <div className="breadcrumbs" data-aos="fade-in">
          <div className="container">
            <h2>Memory Game</h2>
            <p>
              Go ahead and remember all the values you got only 10 seconds to
              remember it! Find pair of numbers.
            </p>
          </div>
        </div>
        <section id="memoryGame" className="memoryGame">
          <div className="container" data-aos="fade-up">
            <div className="row mt-5">
              <div className="memory-game-container">
                <h2 className="game-title">
                  {gameCompleted && (
                    <button
                      className="restart-button btn btn-success"
                      onClick={handleRestart}
                    >
                      Restart
                    </button>
                  )}
                  <p>
                    {remainingTime > 0
                      ? `Time Remaining: ${remainingTime}s`
                      : ""}
                  </p>
                </h2>
                <div className="grid">
                  {cards.map((value, index) => (
                    <div
                      key={index}
                      className={`card ${
                        matchedCards.includes(index) ? "matched" : ""
                      }`}
                      onClick={() => handleCardClick(index)}
                      style={
                        initialReveal || matchedCards.includes(index)
                          ? flippedCardStyle
                          : cardStyle
                      }
                    >
                      {initialReveal ||
                      matchedCards.includes(index) ||
                      selectedCardIndex === index
                        ? value
                        : ""}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default MemoryGame;
