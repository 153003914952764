import React from "react";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { handleError } from "../components/common/error_method";
import "../assets/css/google-guest-login.css";
import { useNavigate } from "react-router-dom";
import { sendNotification } from "../components/notification_ref";
import { trackButtonClick } from "./event_track";
import GoogleIcon from "../components/google_icon";

const GoogleLogin = () => {
  const navigate = useNavigate();

  const handleGoogleLogin = () => {
    trackButtonClick("Google Login Button");

    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        localStorage.setItem("toastMessage", "login successful!");
        localStorage.setItem(
          "userName",
          JSON.stringify(result.user.displayName)
        );
        await sendNotification(
          result.user.displayName,
          "welcome! You've successfully logged in with google account."
        );
        navigate("/practice");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        handleError("Error signing in with Google:" + errorCode + errorMessage);
      });
  };

  return (
    <div className="google-login">
      <span className="google-login-text" onClick={handleGoogleLogin}>
        <GoogleIcon />
        <span> Login with Google</span>
      </span>
    </div>
  );
};

export default GoogleLogin;
