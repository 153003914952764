import React from "react";
import { Helmet } from "react-helmet";

const NotificationMeta = () => {
  return (
    <div>
      <Helmet>
        <title>MathSpeedUp - Notification</title>
        <meta charSet="UTF-8" />
        <meta
          name="title"
          content="MathSpeedUp Challenge Notification | Stay Updated with Math Challenges"
        />
        <meta
          name="description"
          content="Stay updated with the latest math challenges and competitions on MathSpeedUp.com!"
        />
        <meta
          name="keywords"
          content="Math Notifications, Challenge Updates, Math Competitions, Notification Alerts, Math Challenges, Educational Notifications, MathSpeedUp Updates, Speed Math Alerts, Arithmetic Challenges, Mental Math Notifications, Learning Notifications, Problem Solving Updates, Math Events"
        />
        <meta
          property="article:published_time"
          content="2023-09-17T01:30:57+00:00"
        />
      </Helmet>
    </div>
  );
};

export default NotificationMeta;
