import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/headers";
import Footer from "../components/footer";
import AOS from "aos";
import React, { useEffect, useState } from "react";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import LayoutMetas from "../metas/layout";
import PageLoader from "../components/page_loader";
import RightClickDisabled from "../components/right_click_disabled";
import DisabledInspect from "../components/disabled_inspect";

const Layout = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [rightClickDisabled, setRightClickDisabled] = useState(
    !location.pathname.includes("/readers")
  );

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 0);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <RightClickDisabled rightClickDisabled={rightClickDisabled} />
      <DisabledInspect rightClickDisabled={rightClickDisabled} />
      <div className="App">
        <Header />
      </div>

      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          <Outlet />
          <Footer />
          <LayoutMetas />
        </>
      )}
    </>
  );
};

export default Layout;
