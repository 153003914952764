import React, { useState, useEffect } from "react";
import NameForm from "../components/p2p/submit_user_name_form";
import CreateQuizeQuestions from "../components/quiz/create_quiz_questions";
import ShareUrl from "../components/p2p/share_url";
import { databaseKey } from "../components/common/database_key";
import { handleError } from "../components/common/error_method";
import CurrentParticipants from "../components/p2p/current_participants";
import CurrentQuestion from "../components/quiz/quiz_questions";
import QuizMeta from "../metas/quiz";
import JoinQuiz from "../components/quiz/join_quiz";
import "../assets/css/quiz.css";
import {
  fetchData,
  getData,
  pushData,
  subscribeToData,
  updateData,
} from "../components/firebase/firebase_methods";
import PreviousQuiz from "../components/quiz/previous_quiz";
import RulesSection from "../components/quiz/quiz_rules";
import { trackButtonClick } from "./event_track";

const Quiz = () => {
  const [userName, setUserName] = useState(
    localStorage.getItem("userName") || ""
  );
  const [create, setCreate] = useState(false);
  const [join, setJoin] = useState(false);
  const [roomID, setRoomID] = useState("");
  const [currentUserHost, setCurrentUserHost] = useState(false);
  const [roomDetails, setRoomDetails] = useState({});
  const [roomStatus, setRoomStatus] = useState("pending");
  const [key] = useState(localStorage.getItem("key") || "");
  const [waitingForHost, setWaitingForHost] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [moreQuestions, setMoreQuestions] = useState(true);
  const [currentParticipant, setCurrentParticipant] = useState("");
  const [questionLength, setQuestionLength] = useState();
  const [currentOptions, setCurrentOptions] = useState([]);
  const [showPreviousQuiz, setShowPreviousQuiz] = useState(false);
  const [questionType, setQuestionType] = useState();
  const [winners, setWinners] = useState([]);
  const collectionName = databaseKey();

  useEffect(() => {
    const queryRoomID = getRoomId();
    if (queryRoomID) {
      setRoomID(queryRoomID);
      addParticipant(queryRoomID, userName);
      const quizRef = fetchData(`${collectionName}/quiz/${queryRoomID}`);
      const unsubscribe = subscribeToData(quizRef, (snapshot) => {
        const data = snapshot;
        if (data) {
          setRoomStatus(data.status);
          setRoomDetails(data);
        }
      });

      return () => unsubscribe();
    }
  }, [userName]);

  useEffect(() => {
    if (roomDetails && roomDetails.questions) {
      if (
        currentQuestionIndex >= 0 &&
        currentQuestionIndex < roomDetails.questions.length
      ) {
        setCurrentQuestion(
          roomDetails.questions[currentQuestionIndex].question
        );

        setQuestionType(
          roomDetails.questions[currentQuestionIndex].questionType
        );
        setCurrentOptions(roomDetails.questions[currentQuestionIndex].options);
      } else {
        updateParticipantField(key, "endTime", new Date());
        setMoreQuestions(false);
      }
    }
  }, [
    roomDetails,
    currentQuestionIndex,
    roomStatus,
    currentParticipant.status,
  ]);

  const handleCreate = () => {
    setCreate(true);
    trackButtonClick("Create Quiz");
  };

  const handleJoin = () => {
    setJoin(true);
    trackButtonClick("Join Quiz");
  };

  const getRoomId = () => {
    const query = new URLSearchParams(window.location.search);
    return query.get("roomID");
  };

  const fetchRoomData = async (queryRoomID, participantsRef) => {
    if (queryRoomID) {
      const quizRef = fetchData(`${collectionName}/quiz/${queryRoomID}`);

      const snapshot = await getData(quizRef);
      const participantsData = await getData(participantsRef);
      const participants = participantsData.val();
      if (snapshot.exists()) {
        const data = snapshot.val();
        setCurrentUserHost(userName === data.host);
        setRoomDetails(data);
        setRoomStatus(data.status);
        setQuestionLength(data.questions.length);
        for (const participantID in participants) {
          if (participantID === key) {
            const matchingParticipant = participants[participantID];
            setCurrentParticipant(matchingParticipant);
          }
        }
      }
    }
  };

  const addParticipant = async (queryRoomID, userName) => {
    const participantsRef = fetchData(
      `${collectionName}/participants/${queryRoomID}`
    );
    await fetchRoomData(queryRoomID, participantsRef);

    const participantsSnapshot = await getData(participantsRef);
    if (participantsSnapshot.exists()) {
      const participantsData = participantsSnapshot.val();
      const existingParticipant = Object.values(participantsData).some(
        (participant) => participant.userName === userName
      );
      if (!existingParticipant) {
        if (userName && !currentUserHost) {
          const response = await fetch("https://api.ipify.org?format=json");
          const data = await response.json();
          const newParticipant = {
            status: "pending",
            questionIndex: 0,
            userName: userName,
            questions: [],
            userIP: data.ip,
          };
          let newkey = await pushData(participantsRef, newParticipant);
          localStorage.setItem("key", newkey.key);
          window.location.href = `/quiz?roomID=${queryRoomID}`;
        }
      }
    }
  };

  const handleStartClick = async () => {
    trackButtonClick("Start Quiz");

    roomDetails.status = "inProgress";
    setRoomStatus("inProgress");
    await updateQuizStatus(roomID, "inProgress");
    setRoomDetails(roomDetails);

    const participantsRef = fetchData(
      `${collectionName}/participants/${roomID}`
    );
    try {
      const participantsSnapshot = await getData(participantsRef);
      if (participantsSnapshot.exists()) {
        const participantDetails = participantsSnapshot.val();

        for (const participantID in participantDetails) {
          const participant = participantDetails[participantID];

          if (participant.status === "ready") {
            let date = new Date();
            updateParticipantField(participantID, "status", "inGame");
            updateParticipantField(participantID, "startTime", date);
          }
        }
      } else {
        handleError("No participants found for this room ID:");
      }
    } catch (error) {
      handleError("Error updating participants status in Firebase:" + error);
    }
  };

  const updateParticipantField = async (id, field, newValue) => {
    try {
      const participantRef = fetchData(
        `${collectionName}/participants/${roomID}/${id}`
      );
      const participantSnapshot = await getData(participantRef);
      if (participantSnapshot.exists()) {
        const updates = {};
        updates[field] = newValue;
        await updateData(participantRef, updates);
      } else {
        handleError(`Participant not found with id: ${id}`);
      }
    } catch (error) {
      handleError(`Error updating participant ${field}:` + error);
    }
  };

  const updateQuizStatus = async (roomId, newStatus) => {
    const quizRef = fetchData(`${collectionName}/quiz/${roomId}`);
    try {
      const quizSnapshot = await getData(quizRef);
      if (quizSnapshot.exists()) {
        await updateData(quizRef, { status: newStatus });
      } else {
        handleError("No quiz found for this room ID:");
      }
    } catch (error) {
      handleError("Error updating quiz status in Firebase:", error);
    }
  };

  const handleReadyClick = async () => {
    trackButtonClick("Ready Quiz");

    setWaitingForHost(true);
    try {
      const participantRef = fetchData(
        `${collectionName}/participants/${roomID}/${key}`
      );
      const participantSnapshot = await getData(participantRef);
      if (participantSnapshot.exists()) {
        const updates = {};
        updates["status"] = "ready";
        await updateData(participantRef, updates);
      } else {
        handleError(`Participant not found with userToken: ${key}`);
      }
    } catch (error) {
      handleError(`Error updating participant status:` + error);
    }
  };

  const redirectToGameRoot = (msg = "") => {
    let url = "/quiz";
    if (msg !== "") {
      url += "/?msg=" + msg;
    }
    window.location.href = url;
  };

  const handleCheckAnswer = (event) => {
    event.preventDefault();
    let selectedAnswer;
    const optionElement = event.target.elements.option;
    const answerElement = event.target.elements.answer;

    if (optionElement && optionElement.value.trim()) {
      selectedAnswer = optionElement.value.trim();
    } else if (answerElement && answerElement.value.trim()) {
      selectedAnswer = answerElement.value.trim();
    } else {
      return;
    }

    if (
      selectedAnswer ||
      questionType === "Short Answer" ||
      questionType === "Long Answer"
    ) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      questionTypeChangeHandler(selectedAnswer);
    }

    event.target.reset();
  };

  const questionTypeChangeHandler = async (selectedAnswer) => {
    const participantRef = fetchData(
      `${collectionName}/participants/${roomID}`
    );
    const participantSnapshot = await getData(participantRef);
    const participantDetails = participantSnapshot.val();
    const currentUserParticipant = participantDetails[key];

    if (currentUserParticipant) {
      currentUserParticipant.questionIndex += 1;
      const updatedQuestions = [...(currentUserParticipant.questions || [])];

      const currentQuestionData = {
        question: currentQuestion,
        answer: selectedAnswer,
      };
      updatedQuestions.push(currentQuestionData);

      currentUserParticipant.questions = updatedQuestions;
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      updateParticipantField(key, "questionIndex", currentQuestionIndex + 1);
      updateParticipantField(key, "questions", updatedQuestions);
    }
  };

  const handleButtonClick = () => {
    setShowPreviousQuiz(true);
    trackButtonClick("Previous Quiz");
  };

  const handleUserWon = () => {};

  return (
    <>
      <QuizMeta />
      <div className="breadcrumbs" data-aos="fade-in">
        <div className="container">
          <h2>Quiz</h2>
          <p>This quiz will test your knowledge.</p>
        </div>
      </div>
      <div className="container" data-aos="fade-up">
        <div className="row mt-5" style={{ marginTop: "1rem!important" }}>
          <NameForm
            setUserName={setUserName}
            userName={userName}
            roomStatus={roomStatus}
            roomID={roomID}
          />{" "}
          <div className="col-lg-6 mt-5 mt-lg-0 text-center">
            {userName && !create && !join && !roomID && !showPreviousQuiz && (
              <>
                <button
                  className="btn btn-outline-success"
                  onClick={handleButtonClick}
                  style={{ marginBottom: "10px" }}
                >
                  Previous Quiz
                </button>
                <br />
                <button
                  className="btn btn-outline-success"
                  onClick={handleCreate}
                  style={{ marginBottom: "10px" }}
                >
                  Create Quiz
                </button>
                <br />
                <button
                  className="btn btn-outline-success join-btn"
                  onClick={handleJoin}
                  style={{ marginTop: "1px" }}
                >
                  Join Quiz
                </button>
                <br />
                <br />
              </>
            )}
            {showPreviousQuiz && <PreviousQuiz />}
            {create && (
              <CreateQuizeQuestions userName={userName} setRoomID={setRoomID} />
            )}
            {roomStatus === "pending" && (
              <>
                {currentUserHost && (
                  <>
                    <button
                      onClick={handleStartClick}
                      className="btn btn-success"
                    >
                      Start
                    </button>
                  </>
                )}
              </>
            )}
            {currentParticipant.status === "ready" ||
              (roomStatus === "pending" &&
                !currentUserHost &&
                roomID &&
                !waitingForHost &&
                userName && (
                  <>
                    <button
                      onClick={handleReadyClick}
                      className="btn btn-primary"
                    >
                      Ready
                    </button>
                  </>
                ))}
            {(waitingForHost || currentParticipant.status === "ready") &&
              roomStatus === "pending" &&
              !currentUserHost &&
              userName && <p>Waiting for the host to start...</p>}

            {roomStatus === "inProgress" &&
              !currentUserHost &&
              (currentParticipant.status !== "ready" ||
                currentParticipant.status !== "inGame") &&
              (!winners || !winners.includes(userName)) && (
                <CurrentQuestion
                  question={currentQuestion}
                  options={currentOptions}
                  handleCheckAnswer={handleCheckAnswer}
                  moreQuestions={moreQuestions}
                  questionType={questionType}
                />
              )}

            {roomStatus === "inProgress" &&
              currentUserHost &&
              !winners.length > 0 && (
                <p>Please wait until all users have finished the game.</p>
              )}

            {join && <JoinQuiz />}
          </div>
          {roomID && (
            <>
              <CurrentParticipants
                roomID={roomID}
                redirectToGameRoot={redirectToGameRoot}
                userName={userName}
                setMoreQuestions={setMoreQuestions}
                moreQuestions={moreQuestions}
                roomStatus={roomStatus}
                questionLength={questionLength}
                handleUserWon={handleUserWon}
                winners={winners}
                setWinners={setWinners}
                updateParticipantField={updateParticipantField}
              />
              <ShareUrl currentQuestion={currentQuestion} roomID={roomID} />
            </>
          )}{" "}
          <RulesSection />
        </div>
        <br></br>
      </div>
    </>
  );
};

export default Quiz;
