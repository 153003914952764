import React, { useState, useEffect, useRef } from "react";
import { databaseKey } from "../common/database_key";
import "../../assets/css/create_quiz.css";
import { fetchData, pushData } from "../firebase/firebase_methods";
import { trackButtonClick } from "../../pages/event_track";
import { fetchIpAddress } from "../common/fetch_ip";

const CreateQuizQuestions = ({ userName, setRoomID }) => {
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState(["", "", "", ""]);
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [questions, setQuestions] = useState([]);
  const [questionCount, setQuestionCount] = useState(1);
  const [questionType, setQuestionType] = useState("");
  const [addButton, setAddButton] = useState(false);
  const [userIP, setUserIP] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const collectionName = databaseKey();
  const inputRef = useRef(null);
  fetchIpAddress(setUserIP);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const handleCorrectAnswerChange = (optionValue) => {
    setCorrectAnswer(optionValue);
  };

  const handleQuestionTypeChange = (type) => {
    setQuestionType(type);
  };

  const handlePreviousQuestion = () => {
    const index = questionCount - 2;
    const prevQuestions = questions[index];
    setQuestionType(prevQuestions.questionType);
    setQuestion(prevQuestions.question);
    prevQuestions.options ? setOptions(prevQuestions.options) : setOptions("");
    setCorrectAnswer(prevQuestions.correctAnswer);
    setQuestionCount(index + 1);
    setAddButton(true);
  };

  const handleNextQuestion = () => {
    if (questionCount < questions.length) {
      const index = questionCount;
      const prevQuestions = questions[index];
      setQuestionType(prevQuestions.questionType);
      setQuestion(prevQuestions.question);
      prevQuestions.options
        ? setOptions(prevQuestions.options)
        : setOptions("");
      setCorrectAnswer(prevQuestions.correctAnswer);
      setQuestionCount(index + 1);
      setAddButton(true);
    } else {
      setQuestion("");
      setOptions(["", "", "", ""]);
      setCorrectAnswer("");
      setQuestionCount(questionCount + 1);
      setAddButton(false);
    }
  };

  const handleAddQuestions = async () => {
    if (!question) return;

    const newQuestion = {
      question: question,
      questionType: questionType,
    };

    if (
      questionType === "MCQ" &&
      options.every((option) => option.trim()) &&
      correctAnswer.trim()
    ) {
      newQuestion.options = options;
      newQuestion.correctAnswer = correctAnswer;
    } else if (["Short Answer", "Long Answer"].includes(questionType)) {
      newQuestion.correctAnswer = "";
    } else if (
      ["Boolean", "Fill in the Blanks", "One Word"].includes(questionType) &&
      correctAnswer.trim()
    ) {
      newQuestion.correctAnswer = correctAnswer;
    } else {
      return;
    }

    setQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
    setQuestionCount((prevCount) => prevCount + 1);
    setQuestion("");
    setOptions(["", "", "", ""]);
    setCorrectAnswer("");
  };

  const handleSaveQuiz = async () => {
    trackButtonClick("Save quiz");

    if (questions.length !== 0) {
      setIsLoading(true);
      const roomsRef = fetchData(`${collectionName}/quiz/`);
      let roomData = {
        created_at: new Date().toString(),
        host: userName,
        status: "pending",
        questions: questions,
      };

      let roomref = await pushData(roomsRef, roomData);
      setRoomID(roomref.key);

      const participantsRef = fetchData(
        `${collectionName}/participants/${roomref.key}`
      );
      const newParticipantData = {
        status: "ready",
        userName: userName,
        userIP: userIP,
      };

      await pushData(participantsRef, newParticipantData);
      const joinQuizRef = fetchData(`${collectionName}/join_quize/`);
      await pushData(joinQuizRef, `/quiz?roomID=${roomref.key}`);
      setIsLoading(false);
      window.location.href = `/quiz?roomID=${roomref.key}`;
    }
  };

  return (
    <div className="create-quiz-container">
      <select
        value={questionType}
        onChange={(e) => handleQuestionTypeChange(e.target.value)}
        className="form-control text-center"
      >
        <option value="">Select Question Type</option>
        {[
          "MCQ",
          "Boolean",
          "One Word",
          "Short Answer",
          "Long Answer",
          "Fill in the Blanks",
        ].map((type, index) => (
          <option key={index} value={type}>
            {type}
          </option>
        ))}
      </select>

      {questionType && (
        <>
          <div className="question">
            <span>
              {questions.length > 0 && questionCount > 1 && (
                <>
                  <i
                    className="bi bi-arrow-left"
                    style={{ fontSize: "24px" }}
                    onClick={handlePreviousQuestion}
                  ></i>
                </>
              )}
            </span>
            <h2>Question {questionCount}</h2>
            {addButton && (
              <>
                <i
                  className="bi bi-arrow-right"
                  style={{ fontSize: "24px" }}
                  onClick={handleNextQuestion}
                ></i>
              </>
            )}
          </div>
          <input
            ref={inputRef}
            type="text"
            placeholder="Enter your question"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            className="question-input"
          />
          {questionType === "MCQ" &&
            options.map((option, index) => (
              <div key={index} className="option-container">
                <input
                  type="radio"
                  id={`option${index}`}
                  name="options"
                  value={option}
                  checked={correctAnswer === option}
                  onChange={() => handleCorrectAnswerChange(option)}
                  className="radio-button"
                />
                <input
                  type="text"
                  placeholder={`Option ${index + 1}`}
                  value={option}
                  onChange={(e) => handleOptionChange(index, e.target.value)}
                  className="option-input"
                />
              </div>
            ))}
          {(questionType === "Fill in the Blanks" ||
            questionType === "One Word") && (
            <input
              type="text"
              placeholder="answer"
              value={correctAnswer}
              onChange={(e) => setCorrectAnswer(e.target.value)}
              className="question-input"
            />
          )}
          {questionType === "Boolean" && (
            <select
              value={correctAnswer}
              onChange={(e) => handleCorrectAnswerChange(e.target.value)}
              className="question-input"
            >
              <option value="">Select</option>
              <option value={true}>True</option>
              <option value={false}>False</option>
            </select>
          )}
          <button
            className="add-btn"
            onClick={handleAddQuestions}
            disabled={addButton}
          >
            Add Question
          </button>
          &nbsp;
          <button className="save-btn" onClick={handleSaveQuiz}>
            {isLoading ? "Loading..." : "Save Quiz"}
          </button>
        </>
      )}
    </div>
  );
};

export default CreateQuizQuestions;
