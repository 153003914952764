import React from 'react';
import { Helmet } from 'react-helmet';

const P2pMetas = () => {
  return (
    <div>
      <Helmet>
        <title>Player vs player | Get in Touch with Us</title>
        <meta charSet="UTF-8" /> 
        <meta name="title" content="Contact Us | Get in Touch with Us | MathSpeedUp" />
        <meta name="description" content="Test your skills in multipliers, sums, squares, cubes, and more on MathSpeedUp.com!" />
        <meta name="keywords" content="Multiplayer math games, Math competition, Math speed challenge, Calculation race, Head-to-head math, Multiply and conquer, Math table battles, Sum showdown, Square and cube challenge, Mental math duel, Competitive math practice, Quick calculation games, Mathematics gaming, Speed math challenges, Fun math contests, Improve math skills, Math gaming community, Fast math challenges, Real-time math battles, Math competition platform, how to increase math calculation speed, increase math calculation speed, math Speed Up, Quick calculations, Maths games, Memory based games, math practice, Math, Speed, Speed Maths, Practice, Shortcuts, Mental maths, Special Techniques" />
        <meta property="article:published_time" content="2023-09-17T01:30:57+00:00" />
      </Helmet>
    </div>
  );
};

export default P2pMetas;
 