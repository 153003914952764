import React, { useEffect, useState } from "react";
import QuoridorMetas from "../../metas/quoridor_game";
import NameForm from "../../components/p2p/submit_user_name_form";
import { push, ref, get } from "firebase/database";
import { database } from "../../firebaseConfig";
import { databaseKey } from "../../components/common/database_key";
import ShareUrl from "../../components/p2p/share_url";
import "../../assets/css/quoridor.css";
import {
  fetchData,
  pushData,
  subscribeToData,
  updateData,
} from "../../components/firebase/firebase_methods";
import Grid from "../../components/games/grid";
import QuoridorWinner from "../../components/games/quoridor_winner";
import RulesSection from "../../components/games/quoridor_rules";
import { occupiedCell } from "../../components/games/occupied_cell";
import { trackButtonClick } from "../event_track";

const QoridorGame = () => {
  const [userName, setUserName] = useState(
    localStorage.getItem("userName") || ""
  );
  const [create, setCreate] = useState(false);
  const [roomID, setRoomID] = useState("");
  const [currentUserHost, setCurrentUserHost] = useState(false);
  const [participantDetails, setParticipantDetails] = useState({});
  const [roomDetails, setRoomDetails] = useState({});
  const [currentParticipant, setCurrentParticipant] = useState({});
  const [hasMoved, setHasMoved] = useState(false);
  const [key] = useState(localStorage.getItem("key") || "");
  const [currentTurn, setCurrentTurn] = useState("");
  const [roomStatus, setRoomStatus] = useState("pending");

  const collectionName = databaseKey();

  useEffect(() => {
    const queryRoomID = getRoomId();
    if (queryRoomID) {
      setRoomID(queryRoomID);
      addParticipant(queryRoomID, userName);
      const participantsRef = fetchData(
        `${collectionName}/participants/${queryRoomID}`
      );
      const unsubscribe = subscribeToData(participantsRef, async (snapshot) => {
        const participantsnapshot = await get(participantsRef);
        if (participantsnapshot.exists()) {
          const participantData = participantsnapshot.val();
          setParticipantDetails(participantData);
          for (const Id in participantData) {
            if (Id === key) {
              const matchingParticipant = participantData[Id];
              setCurrentParticipant(matchingParticipant);
            }
          }
        }
      });

      return () => unsubscribe();
    }
  }, [userName]);

  const fetchRoomData = async (queryRoomID) => {
    if (queryRoomID) {
      const quoridorRef = ref(
        database,
        `${collectionName}/quoridor/${queryRoomID}`
      );
      subscribeToData(quoridorRef, (snapshot) => {
        if (snapshot) {
          const data = snapshot;
          setCurrentUserHost(userName === data.host);
          setRoomDetails(data);
          setRoomStatus(data.status);
          setCurrentTurn(data.currentTurn);
        }
      });
    }
  };

  const addParticipant = async (queryRoomID, userName) => {
    await fetchRoomData(queryRoomID);
    const participantsRef = ref(
      database,
      `${collectionName}/participants/${queryRoomID}`
    );
    const participantsnapshot = await get(participantsRef);
    if (participantsnapshot.exists()) {
      const participantData = participantsnapshot.val();
      setParticipantDetails(participantData);
      const existingParticipant = Object.values(participantData).some(
        (participant) => participant.userName === userName
      );

      if (Object.keys(participantData).length >= 2) {
        return;
      }

      if (!existingParticipant) {
        if (
          !Object.keys(participantData).includes(userName) &&
          userName &&
          !currentUserHost
        ) {
          const newParticipant = {
            status: "ready",
            userName: userName,
            row: 12,
            col: 6,
            fan: 6,
            position: 8,
            borderObject: [],
          };
          let newkey = await pushData(participantsRef, newParticipant);
          localStorage.setItem("key", newkey.key);
          window.location.href = `/games/quoridor_game?roomID=${queryRoomID}`;
        }
      }
    }
  };

  const handleCreate = async () => {
    trackButtonClick("Create Quoridor Game");
    setCreate(true);
    let generatedRoomID = "";
    const quoridorRef = ref(database, `${collectionName}/quoridor/`);

    let data = {
      created_at: new Date().toString(),
      host: userName,
      currentTurn: userName,
      status: "inProgress",
      winner: "",
      draw: false,
    };

    let roomRef = await push(quoridorRef, data);
    generatedRoomID = roomRef.key;

    const participantsRef = fetchData(
      `${collectionName}/participants/${generatedRoomID}`
    );
    const newParticipantData = {
      status: "ready",
      userName: userName,
      row: 0,
      col: 6,
      fan: 6,
      position: 0,
      borderObject: [],
    };

    let participantRef = await pushData(participantsRef, newParticipantData);
    localStorage.setItem("key", participantRef.key);

    window.location.href = `/games/quoridor_game?roomID=${generatedRoomID}`;
  };

  const getRoomId = () => {
    const query = new URLSearchParams(window.location.search);
    return query.get("roomID");
  };

  const handleCellClick = async (row, col, borderObjects) => {
    if (
      Object.keys(participantDetails).length === 1 ||
      currentParticipant.userName !== currentTurn ||
      hasMoved
    ) {
      return;
    }

    if (row % 2 === 1 || col % 2 === 1) {
      if (currentParticipant.fan === 0) {
        return;
      } else {
        updateCurrentTurnAndParticipant();

        const updatedBorderObject = currentParticipant.borderObject
          ? [...currentParticipant.borderObject, { row, col }]
          : [{ row, col }];

        const updatedParticipant = {
          ...currentParticipant,
          fan: currentParticipant.fan - 1,
        };

        await updateData(
          ref(database, `${collectionName}/participants/${roomID}/${key}`),
          { borderObject: updatedBorderObject, fan: updatedParticipant.fan }
        );
      }
    } else {
      const isOccupiedByParticipant = Object.values(participantDetails).some(
        (participant) => participant.row === row && participant.col === col
      );

      if (isOccupiedByParticipant) {
        return;
      }

      const isAdjacentCell =
        (row === currentParticipant.row - 2 &&
          col === currentParticipant.col) ||
        (row === currentParticipant.row + 2 &&
          col === currentParticipant.col) ||
        (row === currentParticipant.row &&
          col === currentParticipant.col - 2) ||
        (row === currentParticipant.row && col === currentParticipant.col + 2);

      occupiedCell(
        row,
        col,
        borderObjects,
        currentParticipant,
        participantDetails,
        updateParticipantsPosition,
        checkForBorder
      );

      if (isAdjacentCell) {
        const isBorderPresent = checkForBorder(
          currentParticipant.row,
          currentParticipant.col,
          row,
          col,
          borderObjects
        );

        if (!isBorderPresent) {
          updateParticipantsPosition(row, col);
        }
      }
    }
  };

  const checkForBorder = (
    currentRow,
    currentCol,
    targetRow,
    targetCol,
    borderObjects
  ) => {
    const minRow = Math.min(currentRow, targetRow);
    const maxRow = Math.max(currentRow, targetRow);
    const minCol = Math.min(currentCol, targetCol);
    const maxCol = Math.max(currentCol, targetCol);

    for (let row = minRow; row <= maxRow; row++) {
      for (let col = minCol; col <= maxCol; col++) {
        if (borderObjects[`${row}-${col}`]) {
          return true;
        }
      }
    }
    return false;
  };

  const winnerResult = (row) => {
    const goalRow = currentParticipant.position === 0 ? 12 : 0;
    if (row === goalRow) {
      updateData(ref(database, `${collectionName}/quoridor/${roomID}`), {
        winner: currentParticipant.userName,
      });
    }
  };

  const updateCurrentTurnAndParticipant = () => {
    setHasMoved(true);
    let nextParticipant = Object.values(participantDetails).find(
      (participant) => participant.userName !== currentTurn
    )?.userName;
    setCurrentTurn(nextParticipant);
    updateData(ref(database, `${collectionName}/quoridor/${roomID}`), {
      currentTurn: nextParticipant,
    });
    setHasMoved(false);
  };

  const updateDraw = () => {
    updateData(ref(database, `${collectionName}/quoridor/${roomID}`), {
      draw: true,
    });
  };

  const updateParticipantsPosition = async (row, col) => {
    updateCurrentTurnAndParticipant();
    const updatedParticipant = { ...currentParticipant, row, col };
    await updateData(
      ref(database, `${collectionName}/participants/${roomID}/${key}`),
      updatedParticipant
    );
    winnerResult(row);
  };

  return (
    <>
      <QuoridorMetas />
      <main id="main">
        <div className="breadcrumbs" data-aos="fade-in">
          <div className="container">
            <h2>Quoridor Game</h2>
            <p>Welcome to the Quoridor Game </p>
          </div>
        </div>
        <div className="container" data-aos="fade-up">
          <div className="row mt-5" style={{ marginTop: "1rem!important" }}>
            <NameForm
              setUserName={setUserName}
              userName={userName}
              roomStatus={roomStatus}
              roomID={roomID}
            />
            <div className="col-lg-6 mt-5 mt-lg-0 text-center">
              {userName && !create && !roomID && (
                <>
                  <button
                    className="btn btn-success"
                    onClick={handleCreate}
                    style={{ marginBottom: "15px" }}
                  >
                    Create Quoridor
                  </button>
                  <br />
                </>
              )}
              {roomDetails.draw === false ? (
                <>
                  {roomID &&
                    userName &&
                    (Object.values(participantDetails).some(
                      (participant) => participant.userName === userName
                    ) ? (
                      roomDetails.winner === "" ? (
                        <>
                          <div className="participant-names">
                            {Object.values(participantDetails).map(
                              (participant, index) => (
                                <span
                                  key={index}
                                  className="participant"
                                  style={{ marginTop: "30px" }}
                                >
                                  {participant.userName ===
                                    roomDetails.host && (
                                    <>{participant.userName}</>
                                  )}
                                </span>
                              )
                            )}
                          </div>
                          <Grid
                            roomDetails={roomDetails}
                            handleCellClick={handleCellClick}
                            participantDetails={participantDetails}
                            currentTurn={currentTurn}
                            currentParticipant={currentParticipant}
                            checkForBorder={checkForBorder}
                            updateDraw={updateDraw}
                          />
                          {Object.values(participantDetails).map(
                            (participant, index) => (
                              <span key={index} className="participant">
                                {participant.userName !== roomDetails.host && (
                                  <>{participant.userName}</>
                                )}
                              </span>
                            )
                          )}
                          <br></br>
                          <ShareUrl currentQuestion={true} roomID={roomID} />
                        </>
                      ) : (
                        <QuoridorWinner
                          roomDetails={roomDetails}
                          participantDetails={participantDetails}
                        />
                      )
                    ) : (
                      <h5>
                        Game is already in progress. Please join another game.
                      </h5>
                    ))}
                </>
              ) : (
                <>{roomID && <h1>Game Draw</h1>}</>
              )}
              <RulesSection />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default QoridorGame;
