import React from "react";
import { Helmet } from "react-helmet";

const LoginSignupMetas = () => {
  return (
    <div>
      <Helmet>
        <title>Login/Signup | MathSpeedUp</title>
        <meta charSet="UTF-8" />
        <meta name="title" content="Login or Sign Up | MathSpeedUp" />
        <meta
          name="description"
          content="Join MathSpeedUp for free math challenges, competitions, and fun games to improve your math skills!"
        />
        <meta
          name="keywords"
          content="MathSpeedUp login, MathSpeedUp signup, Math challenges, Math competitions, Math games, Improve math skills, Math learning platform, Interactive math challenges, Fun math games, Math practice, Sign up for math challenges, Join math community, Arithmetic challenges, Algebra challenges, Geometry challenges, Trigonometry challenges, Calculus challenges, Number theory challenges, Mathematical puzzles, Problem-solving, Logical reasoning, Brain teasers, Mental math challenges"
        />
        <meta
          property="article:published_time"
          content="2023-08-23T01:30:57+00:00"
        />
      </Helmet>
    </div>
  );
};

export default LoginSignupMetas;
