import { useEffect, useState } from "react";

const ParticipantView = ({ participants, index, quiz }) => {
  const [sortedParticipants, setSortedParticipants] = useState([]);

  useEffect(() => {
    const participantsWithScores = participants.slice(1).map((participant) => {
      const score = participant.questions.reduce((total, question) => {
        const matchingQuestion = quiz.questions.find(
          (q) => q.question === question.question
        );
        if (
          matchingQuestion &&
          matchingQuestion.correctAnswer === question.answer
        ) {
          return total + 1;
        }
        return total;
      }, 0);
      return { ...participant, score };
    });

    const sorted = participantsWithScores.sort((a, b) => {
      if (a.score === b.score) {
        const timeDifferenceA =
          new Date(a.endTime).getTime() - new Date(a.startTime).getTime();
        const timeDifferenceB =
          new Date(b.endTime).getTime() - new Date(b.startTime).getTime();
        return timeDifferenceA - timeDifferenceB;
      }

      return b.score - a.score;
    });
    setSortedParticipants(sorted);
  }, [participants, quiz]);

  return (
    <div className="accordion-body">
      {sortedParticipants.map((participant, participantIndex) => (
        <div key={participantIndex} className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#quiz${index}-participant${participantIndex}`}
              aria-expanded="false"
              aria-controls={`quiz${index}-participant${participantIndex}`}
            >
              {participant.userName}
            </button>
          </h2>
          <div
            id={`quiz${index}-participant${participantIndex}`}
            className="accordion-collapse collapse"
            aria-labelledby={`quiz${index}-participant${participantIndex}`}
            data-bs-parent={`#quiz${index}`}
          >
            <div className="accordion-body">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Question</th>
                    <th>Answer</th>
                  </tr>
                </thead>
                <tbody>
                  {participant.questions &&
                    participant.questions.map((question, questionIndex) => (
                      <tr key={questionIndex}>
                        <td>{question.question}</td>
                        <td>{question.answer}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ParticipantView;
