import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/css/popular_games.css";
import againstWebPage from "../assets/img/popular-area/against_time.webp";
import memoryGame from "../assets/img/popular-area/memory_game.webp";
import missingNumber from "../assets/img/popular-area/missing_number.webp";
import multiplication from "../assets/img/popular-area/multiplication.webp";
import readers from "../assets/img/popular-area/readers.webp";
import playerVsPlayer from "../assets/img/popular-area/player-vs-player.webp";
import practice from "../assets/img/popular-area/practice.webp";
import quiz from "../assets/img/popular-area/quiz.webp";
import quaridorGame from "../assets/img/popular-area/quoridor_game.webp";

const PopularGames = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <>
      <section id="popular-courses" className="courses">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <p>Popular</p>
          </div>

          <div className="row" data-aos="zoom-in" data-aos-delay="100">
            <Slider {...settings}>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div className="course-item">
                  <img
                    src={readers}
                    className="img-fluid"
                    alt="Readers Area"
                    title="MathSpeedUp - Readers Area"
                    width={400}
                    height={300}
                  />
                  <div className="course-content">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h4>Readers Area</h4>
                      <p className="price">Free</p>
                    </div>

                    <h3>
                      <a href="/readers" title="Readers Area" tabIndex="-1">
                        Readers Area
                      </a>
                    </h3>
                    <p>
                      MathSpeedUp's Practice Area is a versatile feature that
                      caters to users of all skill levels. Whether you're a
                      beginner or an advanced learner, you can access a wide
                      range of random arithmetic questions to challenge and
                      strengthen your math abilities.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div className="course-item">
                  <img
                    src={practice}
                    className="img-fluid"
                    alt="Practice Area"
                    title="MathSpeedUp - Practice Area"
                    width={400}
                    height={300}
                  />
                  <div className="course-content">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h4>Practice Area</h4>
                      <p className="price">Free</p>
                    </div>

                    <h3>
                      <a href="/practice" title="Practice Area" tabIndex="-1">
                        Practice Area
                      </a>
                    </h3>
                    <p>
                      MathSpeedUp's Practice Area is a versatile feature that
                      caters to users of all skill levels. Whether you're a
                      beginner or an advanced learner, you can access a wide
                      range of random arithmetic questions to challenge and
                      strengthen your math abilities.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                <div className="course-item">
                  <img
                    src={memoryGame}
                    className="img-fluid"
                    alt="Memory Game"
                    title="MathSpeedUp - Memory Game"
                    width={400}
                    height={300}
                  />
                  <div className="course-content">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h4>Memory Game</h4>
                      <p className="price">Free</p>
                    </div>

                    <h3>
                      <a
                        href="/games/memorygame"
                        title="Memory Game"
                        tabIndex="-1"
                      >
                        Memory Game
                      </a>
                    </h3>
                    <p>
                      The Memory Game offered by MathSpeedUp adds a delightful
                      twist to learning. Users can improve their memory
                      retention while having fun with this interactive activity.
                      It not only enhances math-related memory but also helps
                      users develop cognitive skills.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
                <div className="course-item">
                  <img
                    src={againstWebPage}
                    className="img-fluid"
                    alt="Against Time"
                    title="MathSpeedUp - Against Time"
                    width={400}
                    height={300}
                  />
                  <div className="course-content">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h4>Against Time</h4>
                      <p className="price">Free</p>
                    </div>

                    <h3>
                      <a
                        href="/against_time"
                        title="Against Time"
                        tabIndex="-1"
                      >
                        Against Time
                      </a>
                    </h3>
                    <p>
                      MathSpeedUp's Against the Timer feature provides an
                      exhilarating experience for users seeking an
                      adrenaline-packed math challenge. With only 60 seconds on
                      the clock, users can test their calculation speed and
                      accuracy as they aim to score as high as possible. It's a
                      thrilling way to push one's limits and see noticeable
                      progress over time.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                <div className="course-item">
                  <img
                    src={missingNumber}
                    className="img-fluid"
                    alt="Missing Number"
                    title="MathSpeedUp - Missing Number"
                    width={400}
                    height={300}
                  />
                  <div className="course-content">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h4>Missing Number</h4>
                      <p className="price">Free</p>
                    </div>

                    <h3>
                      <a
                        href="/missing-number"
                        title="Missing Number"
                        tabIndex="-1"
                      >
                        Missing Number
                      </a>
                    </h3>
                    <p>
                      The Memory Game offered by MathSpeedUp adds a delightful
                      twist to learning. Users can improve their memory
                      retention while having fun with this interactive activity.
                      It not only enhances math-related memory but also helps
                      users develop cognitive skills.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                <div className="course-item">
                  <img
                    src={playerVsPlayer}
                    className="img-fluid"
                    alt="Player vs Player"
                    title="MathSpeedUp - Player vs Player"
                    width={400}
                    height={300}
                  />
                  <div className="course-content">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h4>Player vs Player</h4>
                      <p className="price">Free</p>
                    </div>

                    <h3>
                      <a
                        href="/player-vs-player"
                        title="Player vs Player"
                        tabIndex="-1"
                      >
                        Player vs Player
                      </a>
                    </h3>
                    <p>
                      The Memory Game offered by MathSpeedUp adds a delightful
                      twist to learning. Users can improve their memory
                      retention while having fun with this interactive activity.
                      It not only enhances math-related memory but also helps
                      users develop cognitive skills.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                <div className="course-item">
                  <img
                    src={quiz}
                    className="img-fluid"
                    alt="Quiz"
                    title="MathSpeedUp - Quiz"
                    width={400}
                    height={300}
                  />
                  <div className="course-content">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h4>Quiz</h4>
                      <p className="price">Free</p>
                    </div>

                    <h3>
                      <a href="/quiz" title="Quiz" tabIndex="-1">
                        Quiz
                      </a>
                    </h3>
                    <p>
                      The Memory Game offered by MathSpeedUp adds a delightful
                      twist to learning. Users can improve their memory
                      retention while having fun with this interactive activity.
                      It not only enhances math-related memory but also helps
                      users develop cognitive skills.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                <div className="course-item">
                  <img
                    src={multiplication}
                    className="img-fluid"
                    alt="Multiplication"
                    title="MathSpeedUp - Multiplication"
                    width={400}
                    height={300}
                  />
                  <div className="course-content">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h4>Multiplication</h4>
                      <p className="price">Free</p>
                    </div>

                    <h3>
                      <a
                        href="/games/multiplication"
                        title="Multiplication"
                        tabIndex="-1"
                      >
                        Multiplication
                      </a>
                    </h3>
                    <p>
                      The Memory Game offered by MathSpeedUp adds a delightful
                      twist to learning. Users can improve their memory
                      retention while having fun with this interactive activity.
                      It not only enhances math-related memory but also helps
                      users develop cognitive skills.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                <div className="course-item">
                  <img
                    src={quaridorGame}
                    className="img-fluid"
                    alt="Quoridor Game"
                    title="MathSpeedUp - Quoridor Game"
                    width={400}
                    height={300}
                  />
                  <div className="course-content">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h4>Quoridor Game</h4>
                      <p className="price">Free</p>
                    </div>

                    <h3>
                      <a
                        href="/games/quoridor_game"
                        title="Quoridor Game"
                        tabIndex="-1"
                      >
                        Quoridor Game
                      </a>
                    </h3>
                    <p>
                      The Memory Game offered by MathSpeedUp adds a delightful
                      twist to learning. Users can improve their memory
                      retention while having fun with this interactive activity.
                      It not only enhances math-related memory but also helps
                      users develop cognitive skills.
                    </p>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default PopularGames;
