import React from "react";
import CommonChat from "./chat/chat";
import { databaseKey } from "../common/database_key";
import { fetchData } from "../firebase/firebase_methods";

const RoomChat = ({ roomID, userName, onClose }) => {
  const collectionName = databaseKey();
  const messagesRef = fetchData(`${collectionName}/messages/${roomID}`);

  return (
    <CommonChat
      chatRef={messagesRef}
      userName={userName}
      onClose={onClose}
      chat="room-chat"
    />
  );
};

export default RoomChat;
