import React from 'react';

const ShareButton = ({title, text, url}) => {
  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: title,
        text: text,
        url: url,
      })
      .then(() => console.log('Shared successfully'))
      .catch((error) => console.error('Sharing failed:', error));
    } else {
      console.log('Sharing not supported in this browser.');
    }
  };  

  const buttonStyle = {
    marginBottom: '10px',
    marginRight: '10px'
  };

  return (
    <>
      <button onClick={ handleShare } className='btn btn-primary' style={buttonStyle}>
        Share
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-share" viewBox="0 0 16 16" style={{marginLeft: '10px'}}>
          <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"/>
        </svg>
      </button>
    </>
  );
};

export default ShareButton;
