import React from "react";
import { Helmet } from "react-helmet";

const BlogMeta = ({ title, keywords }) => {
  return (
    <div>
      <Helmet>
        <title>MathSpeedUp Blogs - {title}</title>
        <meta charSet="UTF-8" />
        <meta name="title" content={`MathSpeedUp Blogs - ${title}`} />
        <meta
          name="description"
          content="Explore our collection of blogs meticulously crafted to supercharge your mental math skills and unlock new levels of calculation speed. Dive into the world of rapid mental calculations, shortcuts, and mathematical patterns."
        />
        <meta name="keywords" content={keywords.join(", ")} />
        <meta
          property="article:published_time"
          content="2023-09-17T01:30:57+00:00"
        />
      </Helmet>
    </div>
  );
};

export default BlogMeta;
