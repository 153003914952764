export const table = (till = 30, setCurrentQuestionAnswer) => {
  var arr = [randomIntFromInterval(1, till), randomIntFromInterval(1, 10)];
  let answer = arr.reduce((a, b) => a * b, 1);
  let question = arr.slice(0, arr.length).join(" X ");
  setCurrentQuestionAnswer(answer);
  return { question, answer };
};

export const generateQuestion = (type, setCurrentQuestionAnswer) => {
  let question;
  let answer;
  let questionAnswer;

  if (type === "random") {
    const questionTypes = ['1', '6', '7', '8', '9', '15'];
    const index = Math.floor(Math.random() * questionTypes.length);
    type = questionTypes[index];
  }

  switch (type) {
    case "1":
      questionAnswer = table(30, setCurrentQuestionAnswer);
      question = questionAnswer.question;
      answer = questionAnswer.answer;
      break;
    case "2":
      questionAnswer = bigSum(2, setCurrentQuestionAnswer);
      question = questionAnswer.question;
      answer = questionAnswer.answer;
      break;
    case "3":
      questionAnswer = bigSum(3, setCurrentQuestionAnswer);
      question = questionAnswer.question;
      answer = questionAnswer.answer;
      break;
    case "6":
      questionAnswer = bigSum(6, setCurrentQuestionAnswer);
      question = questionAnswer.question;
      answer = questionAnswer.answer;
      break;
    case "7":
      questionAnswer = multiplier(3, setCurrentQuestionAnswer);
      question = questionAnswer.question;
      answer = questionAnswer.answer;
      break;
    case "8":
      questionAnswer = squareAndCubeMix("onlySquare", setCurrentQuestionAnswer);
      question = questionAnswer.question;
      answer = questionAnswer.answer;
      break;
    case "9":
      questionAnswer = squareAndCubeMix("onlyCube", setCurrentQuestionAnswer);
      question = questionAnswer.question;
      answer = questionAnswer.answer;
      break;
    case "10":
      questionAnswer = squareAndCubeMix("", setCurrentQuestionAnswer);
      question = questionAnswer.question;
      answer = questionAnswer.answer;
      break;
    case "11":
      questionAnswer = subtract(2, setCurrentQuestionAnswer);
      question = questionAnswer.question;
      answer = questionAnswer.answer;
      break;
    case "12":
      questionAnswer = subtract(3, setCurrentQuestionAnswer);
      question = questionAnswer.question;
      answer = questionAnswer.answer;
      break;
    case "15":
      questionAnswer = subtract(6, setCurrentQuestionAnswer);
      question = questionAnswer.question;
      answer = questionAnswer.answer;
      break;
    default:
      questionAnswer = multiplier(2, setCurrentQuestionAnswer);
      question = questionAnswer.question;
      answer = questionAnswer.answer;
  }
  return { question, answer };
};

export const subtract = (digit = 4, setCurrentQuestionAnswer) => {
  var arr = [];
  switch (digit) {
    case 6:
      arr = shuffle([
        randomIntFromInterval(1, 99),
        randomIntFromInterval(100, 999),
        randomIntFromInterval(1000, 9999),
        randomIntFromInterval(10000, 99999),
      ]);
      break;
    case 5:
      arr = initializeArray(2, 99999, 10000);
      break;
    case 4:
      arr = initializeArray(2, 9999, 1000);
      break;
    case 3:
      arr = initializeArray(2, 999, 100);
      break;
    case 2:
      arr = initializeArray(2, 99, 10);
      break;
    default:
      arr = initializeArray(2, 9999, 10);
  }
  let question = arr.slice(0, arr.length).join(" - ");
  let answer = eval(question);
  setCurrentQuestionAnswer(answer);
  return { question, answer };
};

export const squareAndCubeMix = (onlyOpt = "", setCurrentQuestionAnswer) => {
  let question_type = "";
  let vals = [];
  let arr = ["square", "squareRoot", "cube", "cubeRoot"];
  question_type = squareOrCubeQuestionType(arr, onlyOpt);
  switch (question_type) {
    case arr[0]:
      vals = square(setCurrentQuestionAnswer);
      break;
    case arr[1]:
      vals = squareRoot(setCurrentQuestionAnswer);
      break;
    case arr[2]:
      vals = cube(setCurrentQuestionAnswer);
      break;
    case arr[3]:
      vals = cubeRoot(setCurrentQuestionAnswer);
      break;
    default:
      vals = square(setCurrentQuestionAnswer);
  }
  let answer = vals[1];
  let question = vals[0];
  setCurrentQuestionAnswer(answer);
  return { question, answer };
};

export const multiplier = (digit = 2, setCurrentQuestionAnswer) => {
  var arr = [];
  switch (digit) {
    case 3:
      arr = initializeArray(2, 100, 999);
      break;
    default:
      arr = initializeArray(2, 10, 99);
      break;
  }
  let answer = arr.reduce((a, b) => a * b, 1);
  setCurrentQuestionAnswer(answer);
  let question = arr.slice(0, arr.length).join(" X ");

  return { question, answer };
};

export const bigSum = (digit = 4, setCurrentQuestionAnswer) => {
  let arr = [];
  switch (digit) {
    case 6:
      arr = shuffle([
        randomIntFromInterval(1, 99),
        randomIntFromInterval(100, 999),
        randomIntFromInterval(1000, 9999),
        randomIntFromInterval(10000, 99999),
      ]);
      break;
    case 5:
      arr = initializeArray(4, 10000, 99999);
      break;
    case 4:
      arr = initializeArray(4, 1000, 9999);
      break;
    case 3:
      arr = initializeArray(4, 100, 999);
      break;
    case 2:
      arr = initializeArray(4, 10, 99);
      break;
    default:
      arr = initializeArray(4, 10, 9999);
  }
  let answer = arr.reduce((a, b) => a + b, 0);
  setCurrentQuestionAnswer(answer);
  let question = arr.slice(0, arr.length).join(" + ");
  return { question, answer };
};

export const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const initializeArray = (total_elements, min, max) => {
  let arr = [];
  for (let i = 0; i < total_elements; i++) {
    arr.push(randomIntFromInterval(min, max));
  }
  return arr;
};

export const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  return array;
};

export const squareOrCubeQuestionType = (arr, onlyOpt = "") => {
  if (onlyOpt !== "") {
    let random = Math.floor(Math.random() * 2);
    if (onlyOpt === "onlySquare") {
      return ["square", "squareRoot"][random];
    }
    if (onlyOpt === "onlyCube") {
      return ["cube", "cubeRoot"][random];
    }
  } else {
    return arr[Math.floor(Math.random() * arr.length)];
  }
};

export const square = (setCurrentQuestionAnswer) => {
  let num = randomIntFromInterval(2, 50);
  let question = "Square of " + num;
  let answer_val = num * num;
  setCurrentQuestionAnswer(answer_val);
  return [question, answer_val];
};

export const squareRoot = (setCurrentQuestionAnswer) => {
  var num = randomIntFromInterval(2, 50);
  var square_num = num * num;
  var question = "Square root of " + square_num;
  setCurrentQuestionAnswer(num);
  return [question, num];
};

export const cube = (setCurrentQuestionAnswer) => {
  var num = randomIntFromInterval(2, 30);
  var question = "Cube of " + num;
  var answer_val = num * num * num;
  setCurrentQuestionAnswer(answer_val);
  return [question, answer_val];
};

export const cubeRoot = (setCurrentQuestionAnswer) => {
  var num = randomIntFromInterval(2, 30);
  var cubeNum = num * num * num;
  var question = "Cube root of " + cubeNum;
  setCurrentQuestionAnswer(num);
  return [question, num];
};

export const checkAnswer = (
  ans,
  userAnswer,
  currentQuestionAnswer,
  setIsCorrect,
  questionTypeChangeHandler,
  ansInputRef,
  questionType
) => {
  let userEnteredAnswer = parseInt(ansInputRef.current.value);
  if (!isNaN(userEnteredAnswer)) {
    if (userEnteredAnswer === currentQuestionAnswer) {
      ansInputRef.current.focus();
      setIsCorrect(true);
      questionTypeChangeHandler({ target: { value: questionType } });
      ansInputRef.current.value = "";
    } else {
      setIsCorrect(false);
    }
  }
};

