import React, { useState, useEffect, useRef } from "react";
import TimerClock from "./../components/timerclock";
import AgainsttimeMetas from "../metas/againsttime";
import userWon from "../components/game_record";
import { sendNotification } from "../components/notification_ref";
import "../assets/css/common_margin.css";
import { trackButtonClick } from "./event_track";

const Againsttime = () => {
  const [timeLeft, setTimeLeft] = useState(60);
  const [score, setScore] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [message, setMessage] = useState("");
  const inputRef = useRef(null);
  const [notifyCalled, setNotifyCalled] = useState(false);
  const [userName] = useState(localStorage.getItem("userName") || "");

  useEffect(() => {
    generateNewQuestion();

    // Start the timer
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    // Clean up the timer on component unmount
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (timeLeft <= 0) {
      endGame();
    }
  }, [timeLeft]);

  useEffect(() => {
    // When the score updates (correct answer), add 2 seconds to the timer
    if (score > 0) {
      setTimeLeft((prevTime) => prevTime + 2);
    }
  }, [score]);

  const generateQuestion = () => {
    const num1 = Math.floor(Math.random() * 100) + 1;
    const num2 = Math.floor(Math.random() * 100) + 1;
    const operator = Math.random() > 0.5 ? "+" : "-";
    const question = `${num1} ${operator} ${num2}`;
    const answer = eval(question);
    setCurrentQuestion(question);
    setCorrectAnswer(answer);
    return answer;
  };

  const generateNewQuestion = () => {
    setCurrentAnswer("");
    setMessage("");
    setCorrectAnswer(null); // Hide the correct answer
    inputRef.current.focus();
    generateQuestion();
  };

  const handleAnswerChange = (e) => {
    setCurrentAnswer(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userAnswer = parseInt(currentAnswer, 10);

    if (userAnswer === correctAnswer) {
      setScore((prevScore) => prevScore + 1);
      setMessage("Correct!");
      generateNewQuestion();
    } else {
      setMessage("Wrong! Try Again.");
    }
  };

  const endGame = async () => {
    setMessage(`Time's up! Your score is ${score}`);
    if (!notifyCalled) {
      notify();
      setNotifyCalled(true);
      userWon(userName, "other");
    }
  };

  const notify = async () => {
    await sendNotification(userName, `youre score is ${score}`);
  };

  const handleRestart = () => {
    trackButtonClick("Against Time page");
    setTimeLeft(60); // Reset the time back to 60 seconds
    setScore(0); // Reset the score to 0
    generateNewQuestion(); // Start a new game with a new question
  };

  return (
    <>
      <AgainsttimeMetas />
      <main id="main">
        <div className="breadcrumbs" data-aos="fade-in">
          <div className="container">
            <h2>Against Time</h2>
            <p>Solve as many math problems as you can within 60 seconds!</p>
            <p>Every correct answer will add 2 seconds to the timer.</p>
          </div>
        </div>
        <section id="AgainstTime" className="AgainstTime">
          <div className="container" data-aos="fade-up">
            <div className="row mt-5 text-center">
              <div className="col-lg-2"></div>
              <div className="col-lg-9 mt-5 mt-lg-0">
                <TimerClock
                  initialTime={timeLeft}
                  onTimeUp={endGame}
                  score={score}
                  setScore={setScore}
                />
                {/* timeLeft > 0 && <div className='text-center'>Time: {timeLeft}s</div> */}
                <div className="text-center">Score: {score}</div>
                {timeLeft < 0 && (
                  <button className="btn btn-success" onClick={handleRestart}>
                    Restart
                  </button>
                )}
                <h2>Question: {currentQuestion}</h2>
                <form>
                  <input
                    ref={inputRef}
                    onKeyUp={handleSubmit}
                    className="form-control col-sm-4 col-md-4"
                    type="number"
                    id="answer"
                    name="answer"
                    value={currentAnswer}
                    onChange={handleAnswerChange}
                    disabled={timeLeft < 0}
                    aria-label="answer"
                  />
                  <br></br>
                  {null && correctAnswer !== null && (
                    <div>Correct Answer: {correctAnswer}</div>
                  )}
                  <div>{message}</div>
                  {/*correctAnswer*/}
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Againsttime;
