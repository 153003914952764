import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft, faAnglesRight } from "@fortawesome/free-solid-svg-icons";

function BlogContent({
  title,
  content,
  handleNext,
  handlePrevious,
  index,
  previousTitle,
  nextTitle,
  isLastBlog,
}) {
  return (
    <div className="container">
      <div className="blog-content">
        <h1>{title}</h1>
        <br />
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
      <br />
      <div className="next-previous">
        {index !== 0 ? (
          <span className="previous" onClick={handlePrevious}>
            <div style={{ display: "flex" }}>
              <FontAwesomeIcon icon={faAnglesLeft} className="icon" />
              <span className="previous-text">PREVIOUS</span>
            </div>
            <div className="previous-title">{previousTitle}</div>
          </span>
        ) : (
          <div style={{ flex: 1 }} />
        )}
        {!isLastBlog && (
          <span className="next" onClick={handleNext}>
            <div className="text-arrow" style={{ display: "flex" }}>
              <span className="next-text">NEXT</span>
              <FontAwesomeIcon icon={faAnglesRight} className="icon" />
            </div>
            <div className="next-title">{nextTitle}</div>
          </span>
        )}
      </div>
      <br />
    </div>
  );
}

export default BlogContent;
