import React, { useEffect, useState } from "react";
import "../assets/css/feedback.css";
import { handleError } from "../components/common/error_method";
import FeedbackMeta from "../metas/feedback";
import { databaseKey } from "../components/common/database_key";
import { sendNotification } from "../components/notification_ref";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchData, pushData } from "../components/firebase/firebase_methods";
import { fetchIpAddress } from "../components/common/fetch_ip";
import { trackButtonClick } from "./event_track";

const Feedback = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [userName, setUserName] = useState("");
  const collectionName = databaseKey();
  const [userIP, setUserIP] = useState(null);

  const showToastMessage = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  useEffect(() => {
    const storedUserName = localStorage.getItem("userName") || "";
    setUserName(storedUserName);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    fetchIpAddress(setUserIP);
    trackButtonClick("feedback page");

    e.preventDefault();
    const name = formData.name;
    const email = formData.email;
    const subject = formData.subject;
    const message = formData.message;
    try {
      const feedbackRef = fetchData(`${collectionName}/feedback`);
      await pushData(feedbackRef, {
        name,
        email,
        subject,
        message,
        timestamp: new Date().toString(),
        userIp: userIP,
      });
      await sendNotification(
        userName,
        `Thank you for your feedback, ${userName}!`
      );
      showToastMessage("feedback submitted successfully!");
      setFormData({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
      setErrorMessage("");
    } catch (error) {
      setErrorMessage("Failed to submit the form. Please try again later.");
      handleError("Error uploading form data:", error);
    }
  };

  return (
    <>
      <FeedbackMeta />
      <ToastContainer />
      <div className="breadcrumbs" data-aos="fade-in">
        <div className="container">
          <h2>Feedback</h2>
          <p></p>
        </div>
      </div>
      <div className="feedback-form-container">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="feedback-inputs">
                <label htmlFor="name" className="feedback-label">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="feedback-inputs">
                <label htmlFor="email" className="feedback-label">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
          <div className="feedback-inputs">
            <label htmlFor="subject" className="feedback-label">
              Subject
            </label>
            <input
              type="text"
              name="subject"
              id="subject"
              value={formData.subject}
              onChange={handleChange}
              required
              autoComplete="off"
            />
          </div>
          <div className="feedback-inputs">
            <label htmlFor="message" className="feedback-label">
              Message
            </label>
            <textarea
              name="message"
              id="message"
              value={formData.message}
              onChange={handleChange}
              rows={5}
              cols={50}
              required
              autoComplete="off"
            ></textarea>
          </div>
          <div className="my-3">
            <div className="error-message"></div>
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
          </div>
          <button type="submit" className="btn btn-success">
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default Feedback;
