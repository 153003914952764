import { useEffect } from "react";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { handleError } from "../components/common/error_method";

const SignOut = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogout = async () => {
      const auth = getAuth();
      try {
        await signOut(auth);
        localStorage.removeItem("userName");
        localStorage.setItem("toastMessage", "logout successful!");
        navigate("/practice");
      } catch (error) {
        handleError("Error logging out: " + error);
      }
    };

    handleLogout();
  }, []);
};

export default SignOut;
