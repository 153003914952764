import React from "react";
import "../../assets/css/quiz.css";

const CurrentQuestion = ({
  question,
  options,
  handleCheckAnswer,
  moreQuestions,
  questionType,
}) => {
  const renderQuestion = () => {
    switch (questionType) {
      case "MCQ":
        return (
          <div className="quiz-container">
            <h2 className="question-text">{question}</h2>
            <form onSubmit={handleCheckAnswer} className="options-form">
              {options.map((option, index) => (
                <div key={index} className="option-container">
                  <input
                    type="radio"
                    id={`option${index}`}
                    name="option"
                    value={option}
                    className="radio-input"
                  />
                  <label htmlFor={`option${index}`} className="option-label">
                    {option}
                  </label>
                </div>
              ))}
              <button type="submit" className="check-answer-btn">
                Check Answer
              </button>
            </form>
          </div>
        );
      case "Fill in the Blanks":
      case "One Word":
      case "Short Answer":
      case "Long Answer":
        return (
          <div className="quiz-container">
            <h2 className="question-text">{question}</h2>
            <form onSubmit={handleCheckAnswer} className="options-form">
              {questionType !== "Short Answer" &&
                questionType !== "Long Answer" && (
                  <input
                    type="text"
                    id="answer"
                    name="answer"
                    className="quiz-answer-input"
                    placeholder="Your Answer"
                  />
                )}
              {(questionType === "Short Answer" ||
                questionType === "Long Answer") && (
                <textarea
                  id="answer"
                  name="answer"
                  className="quiz-answer-textarea"
                  placeholder={`Your Answer (Max ${
                    questionType === "Short Answer" ? 100 : 500
                  } characters)`}
                  maxLength={questionType === "Short Answer" ? 100 : 500}
                ></textarea>
              )}
              <button type="submit" className="check-answer-btn">
                Check Answer
              </button>
            </form>
          </div>
        );
      case "Boolean":
        return (
          <div className="quiz-container">
            <h2 className="question-text">{question}</h2>
            <form onSubmit={handleCheckAnswer} className="options-form">
              <div className="radio-button" style={{ marginBottom: "5px" }}>
                <input type="radio" id="true" name="option" value="true" />
                <label htmlFor="true" className="option-label">
                  True
                </label>
              </div>
              <div className="radio-button" style={{ marginBottom: "5px" }}>
                <input type="radio" id="false" name="option" value="false" />
                <label htmlFor="false" className="option-label">
                  False
                </label>
              </div>
              <button type="submit" className="check-answer-btn">
                Check Answer
              </button>
            </form>
          </div>
        );
      default:
        return null;
    }
  };

  return <>{renderQuestion()}</>;
};

export default CurrentQuestion;
