import React, { useEffect } from "react";
import Swiper from "swiper/bundle";

const Testimonials = () => {
  useEffect(() => {
    new Swiper(".testimonials-slider", {
      speed: 600, // Sets the slide transition speed in milliseconds.
      loop: true, // Enables an infinite loop of the slider.
      autoplay: {
        delay: 5000, // Sets the delay between automatic slides in milliseconds (5 seconds).
        disableOnInteraction: false, // Allows autoplay to continue even if the user interacts with the slider.
      },
      slidesPerView: "auto", // Determines how many slides are visible at a time. 'auto' means it adjusts automatically based on container width.
      pagination: {
        el: ".swiper-pagination", // Specifies the pagination element.
        type: "bullets", // Sets the pagination type to bullets.
        clickable: true, // Allows users to click on the pagination bullets to navigate the slider.
      },
      breakpoints: {
        320: {
          slidesPerView: 1, // Adjusts the number of slides displayed at a screen width of 320 pixels.
          spaceBetween: 20, // Adds space between slides.
        },
        1200: {
          slidesPerView: 2, // Adjusts the number of slides displayed at a screen width of 1200 pixels.
          spaceBetween: 20, // Adds space between slides.
        },
      },
    });
  }, []);
  return (
    <>
      <section id="testimonials" className="testimonials">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Testimonials</h2>
            <p>What are they saying</p>
          </div>

          <div
            className="testimonials-slider swiper"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="testimonial-wrap">
                  <div className="testimonial-item">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/testimonials/testimonials-1.webp"
                      }
                      className="testimonial-img"
                      alt="Manish Prajapat - CEO & Founder"
                      title="Manish Prajapat - CEO & Founder"
                      style={{ width: "90px", height: "90px" }}
                    />
                    <h3>Manish Prajapat</h3>
                    <h4>Ceo &amp; Founder</h4>
                    <p>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      MathSpeedUp has been a game-changer for me! The timed math
                      games have significantly improved my calculation speed and
                      accuracy. It's a fun and engaging way to challenge
                      yourself while sharpening your math skills. Highly
                      recommend this web application for anyone looking to boost
                      their math abilities!
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                  </div>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="testimonial-wrap">
                  <div className="testimonial-item">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/testimonials/testimonials-2.webp"
                      }
                      className="testimonial-img"
                      alt="Michael P - Freelance Teacher"
                      title="Michael P - Freelance Teacher"
                      style={{ width: "90px", height: "90px" }}
                    />
                    <h3>Michael P.</h3>
                    <h4>Freelance Teacher</h4>
                    <p>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      can't believe how much my math skills have improved since
                      I started using MathSpeedUp! The memory game is my
                      favorite; it really exercises my brain and helps me retain
                      important math concepts. This web application is perfect
                      for students, professionals, or anyone who wants to master
                      mental math.
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                  </div>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="testimonial-wrap">
                  <div className="testimonial-item">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/testimonials/testimonials-3.webp"
                      }
                      className="testimonial-img"
                      alt="Lisa R - Freelance Teacher"
                      title="Lisa R - Freelance Teacher"
                      style={{ width: "90px", height: "90px" }}
                    />
                    <h3> Lisa R.</h3>
                    <h4> Freelance Teacher</h4>
                    <p>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      As a teacher, I always look for innovative ways to help my
                      students excel in math. MathSpeedUp has become an
                      invaluable tool in my classroom! The against-the-time game
                      is not only educational but also creates healthy
                      competition among my students. They enjoy practicing math,
                      and I've seen a remarkable improvement in their
                      calculation speed and confidence.
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                  </div>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="testimonial-wrap">
                  <div className="testimonial-item">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/testimonials/testimonials-4.webp"
                      }
                      className="testimonial-img"
                      alt="Mr. Anderson - Math Teacher"
                      title="Mr. Anderson - Math Teacher"
                      style={{ width: "90px", height: "90px" }}
                    />
                    <h3> Mr. Anderson</h3>
                    <h4>Math Teacher.</h4>
                    <p>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      MathSpeedUp is a fantastic application for adults looking
                      to refresh their math skills. The quick, challenging games
                      are addictively fun, and the progress tracking feature
                      keeps me motivated to keep pushing myself. It's like a
                      mental gym for my brain!
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                  </div>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="testimonial-wrap">
                  <div className="testimonial-item">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/testimonials/testimonials-5.webp"
                      }
                      className="testimonial-img"
                      alt="Sarah M - Entrepreneur"
                      title="Sarah M - Entrepreneur"
                      style={{ width: "90px", height: "90px" }}
                    />
                    <h3>Sarah M.</h3>
                    <h4>Entrepreneur</h4>
                    <p>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      I've tried several math apps, but MathSpeedUp stands out
                      from the rest. The combination of time-based games and
                      memory challenges is truly unique. It's a brilliant way to
                      improve mental math skills while having a blast! I use it
                      daily during my commute, and it's made a remarkable
                      difference in my number crunching abilities.
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
