import React, { useState } from "react";
import "../assets/css/sign-up.css";
import { auth } from "../firebaseConfig";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { handleError } from "../components/common/error_method";
import Name from "../assets/img/signup/person.webp";
import Password from "../assets/img/signup/password.webp";
import Email from "../assets/img/signup/email.webp";
import LoginSignupMetas from "../metas/sign_up";
import LoginAsGuest from "./login_as_guest.js";
import GoogleLogin from "./login_with_google.js";
import { sendNotification } from "../components/notification_ref.js";
import "react-confirm-alert/src/react-confirm-alert.css";
import Swal from "sweetalert2";
import { trackButtonClick } from "./event_track.js";

const SignUp = () => {
  const [action, setAction] = useState("Login");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState(null);
  const [isSignUpLoading, setIsSignUpLoading] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false);

  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[a-z0-9][\w.]+@\w+?(\.\w+)+$/i;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handleSignUp = async () => {
    trackButtonClick("Signup Button");
    if (action === "Sign Up") {
      setError(null);

      if (!validatePassword(password)) {
        setError("Password should be at least 6 characters long.");
        return;
      }

      if (validateEmail(email)) {
        setIsSignUpLoading(true);
        try {
          const userCredential = await createUserWithEmailAndPassword(
            auth,
            email,
            password
          );
          const user = userCredential.user;
          await updateProfile(user, {
            displayName: name,
          });
          await sendEmailVerification(user);
          setAction("Login");
          setEmail("");
          setPassword("");
          setName("");
          setIsSignUpLoading(false);
          window.location.reload(true);
        } catch (error) {
          setIsSignUpLoading(false);
          const errorCode = error.code;
          const errorMessage = error.message;
          if (errorMessage === "Firebase: Error (auth/email-already-in-use).") {
            setError("Email already in use");
          }
          handleError("firebase error:" + errorCode, errorMessage);
        }
      } else {
        setError("Please enter a valid email address.");
      }
    } else {
      setAction("Sign Up");
      setError(null);
    }
  };

  const handleLogin = async () => {
    trackButtonClick("Login Button");

    if (action === "Login") {
      setError(null);

      if (!validatePassword(password)) {
        setError("Password should be at least 6 characters long.");
        return;
      }

      if (validateEmail(email)) {
        setIsLoginLoading(true);
        try {
          const userCredential = await signInWithEmailAndPassword(
            auth,
            email,
            password
          );

          if (userCredential.user.emailVerified) {
            localStorage.setItem("toastMessage", "login successful!");
            const user = userCredential.user;
            await sendNotification(
              user.displayName,
              "welcome! You've successfully logged in."
            );
            localStorage.setItem("userName", user.displayName);
            setEmail("");
            setPassword("");
            setName("");
            setIsLoginLoading(false);
            navigate("/practice");
          } else {
            setIsLoginLoading(false);
            Swal.fire({
              title: "Email Verification Required",
              text: "Email verification required. Please check your email inbox for a verification link. Before clicking 'OK', please verify your email. You will not be able to log in otherwise.",
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            }).then((result) => {
              if (result.isConfirmed) {
                setEmail("");
                setPassword("");
              }
            });
          }
        } catch (error) {
          setIsLoginLoading(false);
          const errorCode = error.code;
          const errorMessage = error.message;
          handleError("firebase error:" + errorCode, errorMessage);
          setError("Wrong Email & Password you entered");
        }
      } else {
        setIsLoginLoading(false);
        setError("Please enter a valid email address.");
      }
    } else {
      setAction("Sign Up");
      setError(null);
    }
  };

  return (
    <>
      <LoginSignupMetas />
      <div className="breadcrumbs" data-aos="fade-in">
        <div className="container">
          <h2>{action}</h2>
          <p>
            {action === "Login" &&
              "Log in to access personalized math challenges, competitions, and track your math progress."}
            {action === "Sign Up" &&
              "Sign up to join MathSpeedUp for free math challenges, competitions, and fun games to improve your math skills!"}
          </p>
        </div>
      </div>
      <div className="sign-up-container">
        <div className="inputs">
          {action === "Login" ? (
            <div></div>
          ) : (
            <div className="input">
              <img src={Name} alt="Name" />
              <input
                type="text"
                id="nameInput"
                name="name"
                placeholder="Name"
                autoComplete="off"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          )}
          <div className="input">
            <img src={Email} alt="Email" />
            <input
              type="text"
              id="emailInput"
              name="email"
              placeholder="Email"
              value={email}
              autoComplete="off"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input">
            <img src={Password} alt="Password" />
            <input
              type="password"
              id="passwordInput"
              name="password"
              placeholder="Password"
              value={password}
              autoComplete="off"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {action === "Login" && (
            <div className="forgot-password">
              <Link to="/forgot-password">Forgot your Password?</Link>
            </div>
          )}
          <div className="sign-in-error">
            {error && <p className="error-message">{error}</p>}
          </div>
        </div>
        <LoginAsGuest />
        <GoogleLogin />
        <div className="submit-container">
          <div
            className={action === "Login" ? "submit gray" : "submit"}
            onClick={handleSignUp}
          >
            {isSignUpLoading ? "Loading..." : "Sign Up"}
          </div>
          <div
            className={action === "Sign Up" ? "submit gray" : "submit"}
            onClick={handleLogin}
          >
            {isLoginLoading ? "Loading..." : "Login"}
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
