import React, { useState, useEffect } from "react";

const RenderQuestion = ({
  questionObj,
  qIndex,
  quizIndex,
  quizId,
  fetchParticipants,
}) => {
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const data = await fetchParticipants(quizId);
      if (data) {
        setParticipants(data);
      }
    }
    fetchData();
  }, [quizId, fetchParticipants]);

  const renderParticipants = (participants, que) => {
    if (participants && participants.length > 0) {
      return (
        <div>
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Participant Name</th>
                <th>Participant Answer</th>
              </tr>
            </thead>
            <tbody>
              {participants.slice(1).map((participant, index) => (
                <tr key={index}>
                  <td>{participant.userName}</td>
                  <td>
                    {participant.questions &&
                      participant.questions.map((question, qIndex) => (
                        <React.Fragment key={qIndex}>
                          {question.question === que && (
                            <span key={qIndex}>{question.answer}</span>
                          )}
                        </React.Fragment>
                      ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } else {
      return <p>No participants</p>;
    }
  };

  return (
    <div key={qIndex} className="accordion-item">
      <h2 className="accordion-header">
        <button
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#quiz${quizIndex}-question${qIndex}`}
          aria-expanded="false"
          aria-controls={`quiz${quizIndex}-question${qIndex}`}
        >
          {questionObj.question}
        </button>
      </h2>
      <div
        id={`quiz${quizIndex}-question${qIndex}`}
        className="accordion-collapse collapse"
        aria-labelledby={`quiz${quizIndex}-question${qIndex}`}
        data-bs-parent={`#quiz${quizIndex}`}
      >
        <div className="accordion-body">
          <> Answer : {questionObj.correctAnswer}</>
          {renderParticipants(participants, questionObj.question)}
        </div>
      </div>
    </div>
  );
};

export default RenderQuestion;
