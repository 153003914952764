const QuoridorWinner = ({ roomDetails, participantDetails }) => {
  return (
    <>
      <h3 style={{ color: "green" }}>Won: {roomDetails.winner}</h3>
      {Object.values(participantDetails).map(
        (participant, index) =>
          participant.userName !== roomDetails.winner && (
            <span key={index} className="participant">
              <h3 style={{ color: "red" }}>lost: {participant.userName}</h3>
            </span>
          )
      )}
    </>
  );
};

export default QuoridorWinner;
