import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import Layout from "./pages/layout";
import Contact from "./pages/contact";
import About from "./pages/about";
import Againsttime from "./pages/againsttime";
import Practice from "./pages/practice";
import MathMemoryGame from "./pages/memorygame";
import MissingNumber from "./pages/missingnumber";
import Sitemap from "./pages/Sitemap";
import P2P from "./pages/p2p";
import Policy from "./pages/policy";
import NotFound from "./components/NotFound";
import reportWebVitals from "./reportWebVitals";
import Readers from "./pages/readers";
import Blog from "./components/readers/blog";
import SignUp from "./pages/sign-up";
import UserProfile from "./pages/user_profile";
import SignOut from "./pages/sign_out";
import Feedback from "./pages/feedback";
import Notification from "./pages/notification";
import Quiz from "./pages/quiz";
import Multiplication from "./pages/games/multiplication";
import QoridorGame from "./pages/games/quoridor_game";
import TrackPage from "./pages/track_page";
import VedicBlog from "./components/readers/vedic_math_blog";
import ReactGA from "react-ga4";
import ForgotPassword from "../src/components/forgot_password";
import UpdateEmail from "./components/update_email";
import TermsOfServices from "../src/pages/terms_of_services";
import ArithmeticMemory from "./pages/arithmetic_memory";
import { siteSetting } from "./components/common/site_settings";

const AppWrapper = () => {
  ReactGA.initialize("G-54Z7E0RMQT");

  useEffect(() => {
    const setSiteSettings = () => {
      siteSetting();
    };

    const savedTimestamp = localStorage.getItem("lastRunTimestamp");
    const now = new Date().getTime();

    let timeToNextRun = 5 * 60 * 1000;

    if (savedTimestamp) {
      const elapsedTime = now - savedTimestamp;

      if (elapsedTime < timeToNextRun) {
        timeToNextRun -= elapsedTime;
      } else {
        setSiteSettings();
        localStorage.setItem("lastRunTimestamp", now);
      }
    } else {
      localStorage.setItem("lastRunTimestamp", now);
    }

    const timeout = setTimeout(() => {
      setSiteSettings();
      localStorage.setItem("lastRunTimestamp", new Date().getTime());

      setInterval(() => {
        setSiteSettings();
        localStorage.setItem("lastRunTimestamp", new Date().getTime());
      }, 5 * 60 * 1000);
    }, timeToNextRun);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <BrowserRouter>
      <TrackPage />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<App />} />
          <Route path="contact" element={<Contact />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="contact-us" element={<Contact />} />
          <Route path="about" element={<About />} />
          <Route path="against_time" element={<Againsttime />} />
          <Route path="practice" element={<Practice />} />
          <Route path="sitemap.xml" element={<Sitemap />} />
          <Route path="missing-number" element={<MissingNumber />} />
          <Route path="policy" element={<Policy />} />
          <Route path="player-vs-player" element={<P2P />} />
          <Route path="readers" element={<Readers />} />
          <Route path="readers/:id" element={<Blog />} />
          <Route path="readers/vedic-math/:id" element={<VedicBlog />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="user_profile" element={<UserProfile />} />
          <Route path="user_profile/update-email" element={<UpdateEmail />} />
          <Route path="sign_out" element={<SignOut />} />
          <Route path="feedback" element={<Feedback />} />
          <Route path="notification" element={<Notification />} />
          <Route path="quiz" element={<Quiz />} />
          <Route path="terms-of-services" element={<TermsOfServices />} />
          <Route path="games">
            <Route path="multiplication" element={<Multiplication />} />
            <Route path="quoridor_game" element={<QoridorGame />} />
            <Route path="memorygame" element={<MathMemoryGame />} />
            <Route
              path="arithmetic-memory-game"
              element={<ArithmeticMemory />}
            />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<AppWrapper />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
