export const findAdjacentOccupiedCell = (
  currentParticipant,
  participantDetails
) => {
  let occupiedCell = null;
  const adjacentCells = [
    `${currentParticipant.row - 2}-${currentParticipant.col}`,
    `${currentParticipant.row + 2}-${currentParticipant.col}`,
    `${currentParticipant.row}-${currentParticipant.col - 2}`,
    `${currentParticipant.row}-${currentParticipant.col + 2}`,
  ];

  const isAdjacentCellOccupied = adjacentCells.some((cell) => {
    const [adjRow, adjCol] = cell.split("-").map(Number);
    const isOccupied = Object.values(participantDetails).some((participant) => {
      return (
        participant.row === adjRow &&
        participant.col === adjCol &&
        participant.userName !== currentParticipant.userName
      );
    });

    if (isOccupied) {
      occupiedCell = { row: adjRow, col: adjCol };
      return true;
    }
    return false;
  });

  return { isAdjacentCellOccupied, occupiedCell };
};

export const occupiedCell = async (
  row,
  col,
  borderObjects,
  currentParticipant,
  participantDetails,
  updateParticipantsPosition,
  checkForBorder
) => {
  const { isAdjacentCellOccupied, occupiedCell } = findAdjacentOccupiedCell(
    currentParticipant,
    participantDetails
  );

  const isBorderPresent = checkForBorder(
    currentParticipant.row,
    currentParticipant.col,
    row,
    col,
    borderObjects
  );

  if (isAdjacentCellOccupied && !isBorderPresent) {
    if (
      currentParticipant.row === occupiedCell.row - 2 ||
      currentParticipant.row === occupiedCell.row + 2
    ) {
      occupiedCellAdjacent(
        row,
        col,
        occupiedCell,
        borderObjects,
        currentParticipant,
        updateParticipantsPosition,
        checkForBorder
      );
      if (
        col === occupiedCell.col &&
        (row === occupiedCell.row - 2 || row === occupiedCell.row + 2)
      ) {
        updateParticipantsPosition(row, col);
      } else if (occupiedCell.row === 0 || occupiedCell.row === 12) {
        if (
          (col === occupiedCell.col - 2 || col === occupiedCell.col + 2) &&
          row === occupiedCell.row
        ) {
          updateParticipantsPosition(row, col);
        }
      }
    } else if (col === 0 || col === 12) {
      occupiedCellAdjacent(
        row,
        col,
        occupiedCell,
        borderObjects,
        currentParticipant,
        updateParticipantsPosition,
        checkForBorder
      );
      if (row === occupiedCell.row - 2 || row === occupiedCell.row + 2) {
        if (
          col === occupiedCell.col &&
          (row === occupiedCell.row - 2 || row === occupiedCell.row + 2)
        ) {
          updateParticipantsPosition(row, col);
        }
      } else if (row === occupiedCell.row) {
        occupiedCellAdjacent(
          row,
          col,
          occupiedCell,
          borderObjects,
          currentParticipant,
          updateParticipantsPosition,
          checkForBorder
        );

        if (
          (col === occupiedCell.col - 2 || occupiedCell.col + 2) &&
          row === occupiedCell.row
        ) {
          updateParticipantsPosition(row, col);
        }
      }
    } else if (
      currentParticipant.col === occupiedCell.col - 2 ||
      currentParticipant.col === occupiedCell.col + 2
    ) {
      occupiedCellAdjacent(
        row,
        col,
        occupiedCell,
        borderObjects,
        currentParticipant,
        updateParticipantsPosition,
        checkForBorder
      );

      if (
        row === occupiedCell.row &&
        (col === occupiedCell.col - 2 || col === occupiedCell.col + 2)
      ) {
        updateParticipantsPosition(row, col);
      }
    }
  } else {
    if (isAdjacentCellOccupied) {
      if (
        occupiedCell.row === currentParticipant.row &&
        occupiedCell.col === 10 &&
        borderObjects[`${occupiedCell.row}-${occupiedCell.col - 1}`]
      ) {
        const isBorderPresent = checkForBorder(
          currentParticipant.row,
          currentParticipant.col,
          row,
          col,
          borderObjects
        );

        if (
          col === occupiedCell.col &&
          (row + 2 === occupiedCell.row || row - 2 === occupiedCell.row) &&
          !isBorderPresent
        ) {
          updateParticipantsPosition(row, col);
        }
      }
    }
  }
};

const occupiedCellAdjacent = async (
  row,
  col,
  occupiedCell,
  borderObjects,
  currentParticipant,
  updateParticipantsPosition,
  checkForBorder
) => {
  const isAdjacentCell =
    (row === occupiedCell.row - 2 && col === occupiedCell.col) ||
    (row === occupiedCell.row + 2 && col === occupiedCell.col) ||
    (row === occupiedCell.row && col === occupiedCell.col - 2) ||
    (row === occupiedCell.row && col === occupiedCell.col + 2);

  const isBorderPresent = checkForBorder(
    currentParticipant.row,
    currentParticipant.col,
    row,
    col,
    borderObjects
  );
  if (isAdjacentCell && !isBorderPresent) {
    if (occupiedCell.row === currentParticipant.row) {
      if (occupiedCell.col > currentParticipant.col) {
        if (borderObjects[`${occupiedCell.row}-${occupiedCell.col + 1}`]) {
          if (
            (row === occupiedCell.row + 2 || row === occupiedCell.row - 2) &&
            col === occupiedCell.col
          ) {
            updateParticipantsPosition(row, col);
          }
        } else if (
          borderObjects[`${occupiedCell.row}-${occupiedCell.col - 1}`]
        ) {
          return;
        }
      } else {
        if (borderObjects[`${occupiedCell.row}-${occupiedCell.col - 1}`]) {
          if (
            (row === occupiedCell.row + 2 || row === occupiedCell.row - 2) &&
            col === occupiedCell.col
          ) {
            updateParticipantsPosition(row, col);
          }
        } else if (
          borderObjects[`${occupiedCell.row}-${occupiedCell.col + 1}`]
        ) {
          return;
        }
      }
    } else {
      if (occupiedCell.row < currentParticipant.row) {
        if (borderObjects[`${occupiedCell.row - 1}-${occupiedCell.col}`]) {
          if (
            (col === occupiedCell.col + 2 || col === occupiedCell.col - 2) &&
            row === occupiedCell.row
          ) {
            updateParticipantsPosition(row, col);
          }
        } else if (
          borderObjects[`${occupiedCell.row + 1}-${occupiedCell.col}`]
        ) {
          return;
        }
      } else {
        if (borderObjects[`${occupiedCell.row + 1}-${occupiedCell.col}`]) {
          if (
            (col === occupiedCell.col + 2 || col === occupiedCell.col - 2) &&
            row === occupiedCell.row
          ) {
            updateParticipantsPosition(row, col);
          }
        } else if (
          borderObjects[`${occupiedCell.row - 1}-${occupiedCell.col}`]
        ) {
          return;
        }
      }
    }
  }
};

export const drawCondition = (
  borderObjects,
  currentParticipant,
  updateDraw
) => {
  const adjacentCells = [
    `${currentParticipant.row - 1}-${currentParticipant.col}`,
    `${currentParticipant.row + 1}-${currentParticipant.col}`,
    `${currentParticipant.row}-${currentParticipant.col - 1}`,
    `${currentParticipant.row}-${currentParticipant.col + 1}`,
  ];

  if (
    (currentParticipant.row === 0 &&
      currentParticipant.col === 0 &&
      borderObjects[
        `${currentParticipant.row}-${currentParticipant.col + 1}`
      ] &&
      borderObjects[
        `${currentParticipant.row + 1}-${currentParticipant.col}`
      ]) ||
    (currentParticipant.row === 8 &&
      currentParticipant.col === 8 &&
      borderObjects[
        `${currentParticipant.row}-${currentParticipant.col - 1}`
      ] &&
      borderObjects[
        `${currentParticipant.row - 1}-${currentParticipant.col}`
      ]) ||
    (currentParticipant.row === 0 &&
      currentParticipant.col !== 0 &&
      borderObjects[
        `${currentParticipant.row}-${currentParticipant.col - 1}`
      ] &&
      borderObjects[
        `${currentParticipant.row}-${currentParticipant.col + 1}`
      ] &&
      borderObjects[
        `${currentParticipant.row + 1}-${currentParticipant.col}`
      ]) ||
    (currentParticipant.row === 8 &&
      currentParticipant.col !== 8 &&
      borderObjects[
        `${currentParticipant.row}-${currentParticipant.col - 1}`
      ] &&
      borderObjects[
        `${currentParticipant.row}-${currentParticipant.col + 1}`
      ] &&
      borderObjects[
        `${currentParticipant.row - 1}-${currentParticipant.col}`
      ]) ||
    (currentParticipant.col === 0 &&
      currentParticipant.row !== 0 &&
      borderObjects[
        `${currentParticipant.row - 1}-${currentParticipant.col}`
      ] &&
      borderObjects[
        `${currentParticipant.row + 1}-${currentParticipant.col}`
      ] &&
      borderObjects[
        `${currentParticipant.row}-${currentParticipant.col + 1}`
      ]) ||
    (currentParticipant.col === 8 &&
      currentParticipant.row !== 8 &&
      borderObjects[
        `${currentParticipant.row - 1}-${currentParticipant.col}`
      ] &&
      borderObjects[
        `${currentParticipant.row + 1}-${currentParticipant.col}`
      ] &&
      borderObjects[`${currentParticipant.row}-${currentParticipant.col - 1}`])
  ) {
    updateDraw();
    return true;
  } else if (
    (currentParticipant.row === 0 &&
      currentParticipant.col === 0 &&
      borderObjects[
        `${currentParticipant.row}-${currentParticipant.col + 1}`
      ] &&
      borderObjects[
        `${currentParticipant.row + 1}-${currentParticipant.col}`
      ]) ||
    (currentParticipant.row === 8 &&
      currentParticipant.col === 8 &&
      borderObjects[
        `${currentParticipant.row}-${currentParticipant.col - 1}`
      ] &&
      borderObjects[
        `${currentParticipant.row - 1}-${currentParticipant.col}`
      ]) ||
    (currentParticipant.row === 0 &&
      currentParticipant.col === 8 &&
      borderObjects[
        `${currentParticipant.row}-${currentParticipant.col - 1}`
      ] &&
      borderObjects[
        `${currentParticipant.row + 1}-${currentParticipant.col}`
      ]) ||
    (currentParticipant.row === 8 &&
      currentParticipant.col === 0 &&
      borderObjects[
        `${currentParticipant.row}-${currentParticipant.col + 1}`
      ] &&
      borderObjects[`${currentParticipant.row - 1}-${currentParticipant.col}`])
  ) {
    updateDraw();
    return true;
  } else if (
    (currentParticipant.col === 0 &&
      borderObjects[
        `${currentParticipant.row}-${currentParticipant.col + 1}`
      ] &&
      borderObjects[
        `${currentParticipant.row + 1}-${currentParticipant.col}`
      ] &&
      borderObjects[
        `${currentParticipant.row - 1}-${currentParticipant.col}`
      ]) ||
    (currentParticipant.col === 8 &&
      borderObjects[
        `${currentParticipant.row}-${currentParticipant.col - 1}`
      ] &&
      borderObjects[
        `${currentParticipant.row + 1}-${currentParticipant.col}`
      ] &&
      borderObjects[`${currentParticipant.row - 1}-${currentParticipant.col}`])
  ) {
    updateDraw();
    return true;
  } else {
    const allBordersExist = adjacentCells.every((cell) => borderObjects[cell]);
    if (allBordersExist) {
      updateDraw();
      return true;
    } else {
      return false;
    }
  }
};
