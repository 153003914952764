import React, { useEffect } from "react";

const RightClickDisabled = ({ rightClickDisabled }) => {
  useEffect(() => {
    const handleContextMenu = (e) => {
      if (rightClickDisabled) {
        e.preventDefault();
      }
    };

    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, [rightClickDisabled]);

  return null;
};

export default RightClickDisabled;
