import React from "react";

const Messages = ({ messages, userName, chatContainerRef }) => {
  const senderName = (sender, userName) => {
    if (!sender) return "";

    if (sender === userName) {
      return "Me: ";
    } else {
      return sender.slice(0, 9) + " ";
    }
  };

  return (
    <div className="chat-messages" ref={chatContainerRef}>
      {messages.map((message, index) => (
        <div key={index} className="chat-message">
          <span className="message-sender">
            {senderName(message.sent_by, userName)}
          </span>
          <span
            dangerouslySetInnerHTML={{
              __html: makeUrlsClickable(message.message),
            }}
          />
        </div>
      ))}
    </div>
  );
};

const makeUrlsClickable = (message) => {
  if (message) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    return message.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank">Click Here!</a>`;
    });
  }
};

export default Messages;
