import React, { useState, useEffect } from 'react';

const TimerClock = ({ initialTime, onTimeUp, score, setScore }) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);

  useEffect(() => {
    // Start the timer
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    // Clean up the timer on component unmount
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (timeLeft <= 0) {
      onTimeUp();
    }
  }, [timeLeft, onTimeUp]);

  // Handle the score and generate new question on score update
  useEffect(() => {
    if (score > 0) {
      setTimeLeft((prevTime) => prevTime + 2);
    }
  }, [score]);

  return (
    <>
      {timeLeft > 0 ? (
        <div className='text-center'>Time: {timeLeft}s</div>
      ) : (
        <div className='text-center'>Time's up!</div>
      )}
    </>
  );
};

export default TimerClock;
