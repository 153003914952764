import React from "react";

const Host = ({ participants }) => {
  if (!participants || participants.length === 0) {
    return null;
  }

  return (
    <div
      className="text-center"
      style={{ width: "40%", display: "inline-grid" }}
    >
      <div className="accordion" id="accordionExample">
        {participants.slice(1).map((participant, index) => (
          <div className="accordion-item" key={index}>
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#quiz${index}`}
                aria-expanded="false"
                aria-controls={`quiz${index}`}
              >
                {participant.userName}
              </button>
            </h2>
            <div
              id={`quiz${index}`}
              className="accordion-collapse collapse"
              aria-labelledby={`quiz${index}`}
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="accordion" id={`questionsAccordion${index}`}>
                  {participant.questions &&
                    participant.questions.map((question, qIndex) => (
                      <div
                        className="accordion-item"
                        key={`q${index}-${qIndex}`}
                      >
                        <h2
                          className="accordion-header"
                          id={`qHeading${index}-${qIndex}`}
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#qCollapse${index}-${qIndex}`}
                            aria-expanded="false"
                            aria-controls={`qCollapse${index}-${qIndex}`}
                          >
                            <p>{question.question}</p>
                          </button>
                        </h2>
                        <div
                          id={`qCollapse${index}-${qIndex}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`qHeading${index}-${qIndex}`}
                          data-bs-parent={`#questionsAccordion${index}`}
                        >
                          <div className="accordion-body">
                            <p>Answer: {question.answer}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Host;
