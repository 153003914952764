import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

const MathApk = () => {
  return (
    <>
      <a
        className="btn btn-outline-success"
        href="https://speedup-assets.s3.amazonaws.com/mathspeedup.apk"
        style={{ marginLeft: "15px", padding: "10px" }}
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faArrowDown} /> &nbsp;Download
      </a>
    </>
  );
};

export default MathApk;
