import React from "react";
import ReadersMeta from "../metas/readers";
import { Link } from "react-router-dom";
import blogData from "../components/readers/blog_data";
import "../assets/css/blog.css";

const Readers = () => {
  return (
    <>
      <ReadersMeta />
      <div className="breadcrumbs" data-aos="fade-in">
        <div className="container">
          <h2>Readers</h2>
          <p>
            Embark on a journey to boost your math skills! Explore blogs to
            enhance your mental math abilities and unlock new levels of
            calculation speed.
          </p>
        </div>
      </div>
      <div className="blog-container">
        <div className="container">
          <div className="row">
            {blogData.map((blog) => (
              <div key={blog.id} className="card">
                <Link to={`/readers/${blog.id}`} className="link-style">
                  <img src={blog.image} className="card-img-top" alt="image" />
                  <div className="card-body">
                    <p className="card-title">
                      <strong>{blog.title}</strong>
                    </p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Readers;
