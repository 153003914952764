import React from "react";
import { Helmet } from "react-helmet";

const LayoutMetas = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="medium" content="mult" />
        <meta name="author" content="Math Speed Up | Manish Prajapat" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta name="google-adsense-account" content="ca-pub-9380744845529406" />
        <meta
          property="og:title"
          content="Improve your Skills | Math Speed Up"
        />
        <meta
          property="og:description"
          content="Math Speed Up is the application that will help you to practice maths problems. You can choose any type of math question for competitive exam preparation. Speed Maths- Practice, Shortcuts, Mental maths, Special Techniques,  Quick calculations "
        />
        <meta property="og:url" content="https://www.mathspeedup.com/" />
        <meta property="og:site_name" content="MathSpeedUp" />
        <meta
          property="og:image"
          content="https://speedup-assets.s3.amazonaws.com/images/maths-apps.png"
        />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="418" />
        <meta property="og:image:type" content="image/jpeg" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:label1" content="Written by" />
        <meta name="twitter:data1" content="Math Speed Up" />
        <meta name="twitter:label2" content="Practice" />
        <meta name="twitter:data2" content="Competitive" />
        <meta
          name="application-name"
          content="Math Speed Up | Practice Maths"
        />
        <meta name="msapplication-TileColor" content="#a435f0" />
        <meta name="theme-color" content="#ffffff" />

        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <link href="/assets/img/favicon.webp" rel="icon" hreflang="en" />
        <link
          rel="canonical"
          href="https://www.mathspeedup.com"
          hreflang="en"
        />
        <link
          href="/assets/img/apple-touch-icon.webp"
          rel="apple-touch-icon"
          hreflang="en"
        />
        <link
          href="/assets/vendor/animate.css/animate.min.css"
          rel="stylesheet preload"
          as="style"
          hreflang="en"
        />
        <link
          href="/assets/vendor/aos/aos.css"
          rel="stylesheet preload"
          as="style"
          hreflang="en"
        />
        <link
          href="/assets/vendor/bootstrap/css/bootstrap.min.css"
          rel="stylesheet preload"
          as="style"
          hreflang="en"
        />
        <link
          href="/assets/vendor/bootstrap-icons/bootstrap-icons.css"
          rel="stylesheet preload"
          as="style"
          hreflang="en"
        />
        <link
          href="/assets/vendor/boxicons/css/boxicons.min.css"
          rel="stylesheet preload"
          as="style"
          hreflang="en"
        />
        <link
          href="/assets/vendor/remixicon/remixicon.css"
          rel="stylesheet preload"
          as="style"
          hreflang="en"
        />
        <link
          href="/assets/vendor/swiper/swiper-bundle.min.css"
          rel="stylesheet preload"
          as="style"
          hreflang="en"
        />
        <link
          rel="stylesheet"
          as="font"
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Raleway:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i&display=swap"
          hreflang="en"
        />
        <link
          href="/assets/css/style.css"
          rel="stylesheet preload"
          as="style"
          hreflang="en"
        />
      </Helmet>
    </div>
  );
};

export default LayoutMetas;
