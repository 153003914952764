import React, { useEffect, useState } from "react";
import MultiplicationMetas from "../../metas/multiplication";
import "../../assets/css/multiplication.css";
import { trackButtonClick } from "../event_track";

const Multiplication = () => {
  const [wheelRotation, setWheelRotation] = useState(0);
  const [innerRotation, setInnerRotation] = useState(0);
  const [outerWheel, setOuterWheel] = useState(0);
  const [innerWheel, setInnerWheel] = useState(0);
  const [answer, setAnswer] = useState();
  const [isWheelRotating, setIsWheelRotating] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);

  useEffect(() => {
    const spinBtn = document.querySelector(".spin-button");

    const spinWheel = () => {
      let value1 = Math.ceil(Math.random() * -3600);
      let value2 = Math.ceil(Math.random() * 3600);
      setWheelRotation(value1);
      setInnerRotation(value2);

      let outerWheel = Math.ceil(Math.random() * 9);
      let innerWheel = Math.ceil(Math.random() * 9);
      setOuterWheel(outerWheel);
      setInnerWheel(innerWheel);
      setAnswer(outerWheel * innerWheel);

      setTimeout(() => {
        setWheelRotation(0);
        setInnerRotation(0);
      }, 2000);

      setTimeout(() => {
        setIsWheelRotating(true);
      }, 4000);
    };

    spinBtn.addEventListener("click", spinWheel);

    return () => {
      spinBtn.removeEventListener("click", spinWheel);
    };
  }, []);

  const handleInputChange = (event) => {
    const userInput = parseInt(event.target.value);
    if (userInput === answer) {
      setIsWheelRotating(false);
      setIsCorrect(true);
    } else {
      setIsWheelRotating(true);
      setIsCorrect(false);
    }
  };

  const handleSpin = () => {
    trackButtonClick("Spin Button");
    setIsWheelRotating(false);
    setIsCorrect(false);
  };

  return (
    <>
      <MultiplicationMetas />
      <div className="breadcrumbs" data-aos="fade-in">
        <div className="container">
          <h2>Multiplication</h2>
          <p>
            Spin the wheel to reveal two numbers. Multiply the numbers together
            to find the answer.
          </p>
        </div>
      </div>
      <div className="wheel-container">
        <div className="spin-button" onClick={handleSpin}>
          Spin
        </div>
        <div
          className="wheel"
          style={{ transform: `rotate(${wheelRotation}deg)` }}
        >
          <span style={{ "--i": 0 }}></span>
          <span style={{ "--i": 1 }}></span>
          <span style={{ "--i": 2 }}></span>
          <span style={{ "--i": 3 }}></span>
          <span style={{ "--i": 4 }}></span>
          <div className="number">
            <b style={{ "--i": 0 }}>{outerWheel}</b>
            <b style={{ "--i": 1 }}>1</b>
            <b style={{ "--i": 2 }}>4</b>
            <b style={{ "--i": 3 }}>6</b>
            <b style={{ "--i": 4 }}>2</b>
            <b style={{ "--i": 5 }}>8</b>
            <b style={{ "--i": 6 }}>7</b>
            <b style={{ "--i": 7 }}>3</b>
          </div>
        </div>
        <div
          className="wheel inner"
          style={{ transform: `rotate(${innerRotation}deg)` }}
        >
          <span style={{ "--i": 0 }}></span>
          <span style={{ "--i": 1 }}></span>
          <span style={{ "--i": 2 }}></span>
          <span style={{ "--i": 3 }}></span>
          <span style={{ "--i": 4 }}></span>
          <div className="number">
            <b style={{ "--i": 0 }}>{innerWheel}</b>
            <b style={{ "--i": 1 }}>1</b>
            <b style={{ "--i": 2 }}>4</b>
            <b style={{ "--i": 3 }}>6</b>
            <b style={{ "--i": 4 }}>2</b>
            <b style={{ "--i": 5 }}>8</b>
            <b style={{ "--i": 6 }}>7</b>
            <b style={{ "--i": 7 }}>3</b>
          </div>
        </div>
      </div>
      {isWheelRotating && (
        <>
          <h4 className="multiplication-heading">
            {outerWheel} X {innerWheel}
          </h4>
          <div className="answer-container">
            <input
              type="number"
              className="answer-input"
              onChange={handleInputChange}
            />
          </div>
        </>
      )}
      {isCorrect && !isWheelRotating && (
        <>
          <h4 className="correct-answer-message">Wow! you got it right</h4>
        </>
      )}
    </>
  );
};

export default Multiplication;
