import React, { useEffect, useState } from "react";
import {
  fetchData,
  deleteData,
  subscribeToData,
  getData,
} from "../firebase/firebase_methods";
import { databaseKey } from "../common/database_key";
import RenderQuestion from "./render-questions";
import ParticipantView from "./participants_view";

const PreviousQuiz = () => {
  const [selectedQuizzes, setSelectedQuizzes] = useState([]);
  const [participantsData, setParticipantsData] = useState({});
  const [viewMode, setViewMode] = useState("questions");
  const [userName, setUserName] = useState(
    localStorage.getItem("userName") || ""
  );
  const collectionName = databaseKey();

  useEffect(() => {
    const quizRef = fetchData(`${collectionName}/quiz`);
    const unsubscribe = subscribeToData(quizRef, async (snapshot) => {
      if (snapshot) {
        const quizzes = Object.entries(snapshot)
          .filter(([, quiz]) => quiz.host === userName)
          .map(([id, quiz]) => ({ ...quiz, id }));
        setSelectedQuizzes(quizzes);
      }
    });
    return unsubscribe;
  }, [userName]);

  useEffect(() => {
    const fetchDataForAllQuizzes = async () => {
      const data = {};
      await Promise.all(
        selectedQuizzes.map(async (quiz) => {
          const participants = await fetchParticipants(quiz.id);
          data[quiz.id] = participants;
        })
      );
      setParticipantsData(data);
    };

    if (selectedQuizzes.length > 0) {
      fetchDataForAllQuizzes();
    }
  }, [selectedQuizzes]);

  const handleDelete = async (quizId) => {
    deleteData(`${collectionName}/quiz/${quizId}`);
    const joinQuizRef = fetchData(`${collectionName}/join_quize`);
    const joinQuizSnapshot = await getData(joinQuizRef);
    joinQuizSnapshot.forEach((childSnapshot) => {
      const userId = childSnapshot.key;
      const quizLink = childSnapshot.val();

      if (quizLink === `/quiz?roomID=${quizId}`) {
        deleteData(`${collectionName}/join_quize/${userId}`);
      }
    });

    deleteData(`${collectionName}/quiz/${quizId}`);
  };

  const fetchParticipants = async (quizId) => {
    const participantsRef = fetchData(
      `${collectionName}/participants/${quizId}`
    );
    const participantsSnapshot = await getData(participantsRef);
    if (participantsSnapshot.exists()) {
      const participantsData = participantsSnapshot.val();
      const participantsArray = Object.values(participantsData);
      return participantsArray;
    }
  };

  const renderContent = (quiz, index) => {
    if (viewMode === "participants") {
      const participants = participantsData[quiz.id] || [];
      return (
        <ParticipantView
          participants={participants}
          index={index}
          quiz={quiz}
        />
      );
    } else {
      return (
        <div className="accordion-body">
          {quiz.questions.map((questionObj, qIndex) => (
            <RenderQuestion
              key={qIndex}
              questionObj={questionObj}
              qIndex={qIndex}
              quizIndex={index}
              quizId={quiz.id}
              fetchParticipants={fetchParticipants}
            />
          ))}
        </div>
      );
    }
  };

  return (
    <div className="container">
      <div className="mb-3">
        <select
          className="form-select"
          id="viewModeSelect"
          value={viewMode}
          onChange={(e) => setViewMode(e.target.value)}
        >
          <option value="questions">Questions</option>
          <option value="participants">Participants</option>
        </select>
      </div>
      <div className="accordion" id="accordionExample">
        {selectedQuizzes.length > 0 ? (
          selectedQuizzes.map((quiz, index) => (
            <div className="accordion-item" key={index}>
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#quiz${index}`}
                  aria-expanded="false"
                  aria-controls={`quiz${index}`}
                >
                  Quiz {index + 1}
                  <span
                    className="btn btn-danger"
                    onClick={() => handleDelete(quiz.id)}
                    style={{
                      marginLeft: "450px",
                      background: "none",
                      border: "none",
                      color: "red",
                      fontSize: "x-large",
                    }}
                  >
                    <i
                      className="bi bi-trash"
                      style={{ fontWeight: "bold" }}
                    ></i>
                  </span>
                </button>
              </h2>
              <div
                id={`quiz${index}`}
                className="accordion-collapse collapse"
                aria-labelledby={`quiz${index}`}
                data-bs-parent="#accordionExample"
              >
                {renderContent(quiz, index)}
              </div>
            </div>
          ))
        ) : (
          <div>
            <h2>No quizzes found</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviousQuiz;
