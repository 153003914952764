import React from 'react';

const P2pHeader = () => {
    return (
        <div className="breadcrumbs" data-aos="fade-in">
            <div className="container">
                <h2>Player Vs Player</h2>
                <p>Challenge Friends and Family.</p>
            </div>
        </div>
        );
};

export default P2pHeader;
