import { useEffect } from 'react';

const Sitemap = () => {
  useEffect(() => {
    // Redirect to the public sitemap URL
    window.location.href = '/public/sitemap.xml';
  }, []);

  return null; // Render nothing
};

export default Sitemap;
