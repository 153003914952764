import { useState, useEffect } from "react";
import Select from "react-select";
import "../assets/css/arithmetic_memory.css";
import RulesSection from "../components/arithmetic_rules";

const ArithmeticMemory = () => {
  const [difficultyType, setDifficultyType] = useState("1");
  const [digits, setDigits] = useState([]);
  const [currentDigit, setCurrentDigit] = useState(null);
  const [gameStart, setGameStart] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [symbols, setSymbols] = useState(["-", "/", "*", "+", "%"]);
  const [answer, setAnswer] = useState(null);
  const [userAnswer, setUserAnswer] = useState("");
  const [resultMessage, setResultMessage] = useState(null);
  const [countdown, setCountdown] = useState(3);
  const [customMaxNumber, setCustomMaxNumber] = useState(15);
  const [customCount, setCustomCount] = useState(5);
  const [customSymbols, setCustomSymbols] = useState([]);

  useEffect(() => {
    if (digits.length > 0) {
      displayNumbersOneByOne();
    }
  }, [digits]);

  const difficultyTypeChangeHandler = (e) => {
    setDifficultyType(e.target.value);
  };

  const getRandomNumber = () => {
    const count = difficultyType === "4" ? customCount : 5;
    let min, max, availableSymbols;

    switch (difficultyType) {
      case "2":
        min = 15;
        max = 100;
        availableSymbols = ["+", "-", "*", "/"];
        break;
      case "3":
        min = 100;
        max = 500;
        availableSymbols = symbols;
        break;
      case "4":
        min = 1;
        max = customMaxNumber;
        availableSymbols = customSymbols.length > 0 ? customSymbols : symbols;
        break;
      default:
        min = 1;
        max = 15;
        availableSymbols = ["+", "-"];
    }

    const randomArray = [];

    for (let i = 0; i < count; i++) {
      const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
      if (i > 0) {
        const randomSymbol =
          availableSymbols[Math.floor(Math.random() * availableSymbols.length)];
        randomArray.push(`${randomSymbol}${randomNum}`);
      } else {
        randomArray.push(`${randomNum}`);
      }
    }

    const expression = randomArray.join("").replace(/,/g, "");
    const calculatedAnswer = eval(expression);
    setAnswer(calculatedAnswer);
    setDigits(randomArray);
  };

  const displayNumbersOneByOne = () => {
    digits.forEach((item, index) => {
      setTimeout(() => {
        setCurrentDigit(item);
        if (index === digits.length - 1) {
          setTimeout(() => {
            setShowInput(true);
          }, 5000);
        }
      }, index * 5000);
    });
  };

  const startCountdown = () => {
    let countdownValue = 3;
    setCountdown(countdownValue);
    const interval = setInterval(() => {
      countdownValue -= 1;
      setCountdown(countdownValue);
      if (countdownValue === 0) {
        clearInterval(interval);
        getRandomNumber();
      }
    }, 1000);
  };

  const handleStartClick = () => {
    setGameStart(true);
    setShowInput(false);
    setResultMessage("");
    startCountdown();
  };

  const handleAnswerChange = (e) => {
    const userAnswerValue = e.target.value.replace(/['"]/g, "");
    const numericUserAnswer = Number(userAnswerValue);
    if (answer === numericUserAnswer) {
      setResultMessage("You got it right!");
      setShowInput(false);
    }
    setUserAnswer(e.target.value);
  };

  const handleCustomMaxNumberChange = (e) => {
    setCustomMaxNumber(Number(e.target.value));
  };

  const handleCustomCountChange = (e) => {
    setCustomCount(Number(e.target.value));
  };

  const handleCustomSymbolsChange = (selectedOptions) => {
    const selectedSymbols = selectedOptions.map((option) => option.value);
    setCustomSymbols(selectedSymbols);
  };

  const symbolOptions = symbols.map((symbol) => ({
    value: symbol,
    label: symbol,
  }));

  const handleRestartClick = () => {
    window.location.href = "/games/arithmetic-memory-game";
  };

  return (
    <>
      <main id="main">
        <div className="breadcrumbs" data-aos="fade-in">
          <div className="container">
            <h2>Arithmetic Memory</h2>
            <p>
              Arithmetic Memory is a fun and educational game that helps you
              improve your arithmetic calculations. Here, you can practice
              various types of math questions at different levels.
            </p>
          </div>
        </div>
        <section id="practice" className="practice">
          <div className="container" data-aos="fade-up">
            <div className="row mt-5">
              <div className="col-lg-2"></div>
              <div className="col-lg-8 mt-5 mt-lg-0">
                <div className="row">
                  <div className="col-md-4 col-sm-4"></div>
                  <div className="col-md-4 col-sm-4">
                    <label
                      htmlFor="difficulty_type"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      Difficulty
                    </label>
                    <select
                      name="difficulty_type"
                      id="difficulty_type"
                      className="form-control text-center"
                      value={difficultyType}
                      onChange={difficultyTypeChangeHandler}
                      aria-label="Select a difficulty type"
                    >
                      <option value="1">Easy</option>
                      <option value="2">Medium</option>
                      <option value="3">Hard</option>
                      <option value="4">Custom</option>
                    </select>
                    {difficultyType === "4" && gameStart === false && (
                      <>
                        <div className="form-group mt-3 symbols-select-container">
                          <label
                            htmlFor="custom_symbols"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            Operations
                          </label>
                          <Select
                            id="custom_symbols"
                            isMulti
                            options={symbolOptions}
                            value={symbolOptions.filter((option) =>
                              customSymbols.includes(option.value)
                            )}
                            onChange={handleCustomSymbolsChange}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                          />
                        </div>
                        <div className="form-group mt-3">
                          <label
                            htmlFor="custom_max_number"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            Max Number
                          </label>
                          <input
                            type="number"
                            id="custom_max_number"
                            className="form-control"
                            value={customMaxNumber}
                            onChange={handleCustomMaxNumberChange}
                            min="1"
                          />
                        </div>

                        <div className="form-group mt-3">
                          <label
                            htmlFor="custom_count"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            Iterations
                          </label>
                          <select
                            id="custom_count"
                            className="form-control"
                            value={customCount}
                            onChange={handleCustomCountChange}
                          >
                            {[...Array(6).keys()].map((i) => (
                              <option key={i} value={i + 5}>
                                {i + 5}
                              </option>
                            ))}
                          </select>
                        </div>
                      </>
                    )}
                    {resultMessage ? (
                      <div id="resultMessage" style={{ paddingTop: "20px" }}>
                        <center>
                          <h2>{resultMessage}</h2>
                        </center>
                      </div>
                    ) : (
                      <div id="sum" style={{ paddingTop: "20px" }}>
                        <center>
                          {!gameStart ? (
                            <button
                              onClick={handleStartClick}
                              className="btn btn-success"
                            >
                              Start
                            </button>
                          ) : showInput ? (
                            <>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter your answer"
                                onChange={handleAnswerChange}
                                value={userAnswer}
                              />
                            </>
                          ) : countdown > 0 ? (
                            <h1>Get Started in {countdown}</h1>
                          ) : (
                            <div className="progress-bar-container">
                              <div className="progress-bar digits">
                                <span>{currentDigit}</span>
                              </div>
                            </div>
                          )}
                        </center>
                      </div>
                    )}
                    {gameStart && countdown === 0 && (
                      <center>
                        <button
                          onClick={handleRestartClick}
                          className="btn btn-danger"
                          style={{ margin: "10px" }}
                        >
                          Restart
                        </button>
                      </center>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-4"></div>
                </div>
              </div>
              <div className="col-lg-2"></div>
            </div>
          </div>
        </section>
        <RulesSection />
      </main>
    </>
  );
};

export default ArithmeticMemory;
