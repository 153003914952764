import React, { useState } from "react";
import "../../assets/css/chat_style.css";
import { databaseKey } from "../common/database_key";
import { fetchData, getData, updateData } from "../firebase/firebase_methods";
import { fetchIpAddress } from "../common/fetch_ip";
import { trackButtonClick } from "../../pages/event_track";

const NameForm = ({ setUserName, userName, roomStatus, roomID, setUserIP }) => {
  const [isUpdate, setIsUpdate] = useState(false);
  const [oldName, setOldName] = useState();

  const validateUsername = (username) => {
    const regex = /^[a-zA-Z0-9_-\s]+$/;
    return regex.test(username);
  };

  let collectionName = databaseKey();

  const updateUserNameInDatabase = async (newName, oldNameToUpdate) => {
    const participantsRef = fetchData(
      `${collectionName}/participants/${roomID}`
    );
    const snapshot = await getData(participantsRef);

    if (snapshot.exists()) {
      const participantsData = snapshot.val();

      for (const participantID in participantsData) {
        const participant = participantsData[participantID];
        if (participant.userName === oldNameToUpdate) {
          await updateData(
            fetchData(
              `${collectionName}/participants/${roomID}/${participantID}`
            ),
            {
              userName: newName,
            }
          );
        }
      }
    }

    const roomRef = fetchData(`${collectionName}/rooms/${roomID}`);
    const roomSnapshot = await getData(roomRef);

    if (roomSnapshot.exists()) {
      const roomData = roomSnapshot.val();
      if (roomData.host === oldNameToUpdate) {
        await updateData(fetchData(`${collectionName}/rooms/${roomID}`), {
          host: newName,
        });
      }
    }
  };

  const handleNameSubmit = async (e) => {
    e.preventDefault();
    trackButtonClick("submit Name");

    const nameInput = e.target.elements.name;
    const enteredName = nameInput.value;

    fetchIpAddress(setUserIP);

    if (validateUsername(enteredName)) {
      localStorage.setItem("userName", enteredName);

      setUserName(enteredName);
      if (isUpdate) {
        setIsUpdate(false);
        updateUserNameInDatabase(enteredName, oldName);
      }
    }
  };

  const handleChangeNameClick = () => {
    setIsUpdate(true);
    setOldName(userName);
    localStorage.removeItem("userName");
    setUserName("");
  };

  const handleAnonymousEntry = async () => {
    trackButtonClick("Random Name");

    if (isUpdate) {
      const randomString = generateRandomString();
      localStorage.setItem("userName", randomString);
      setUserName(randomString);
      setIsUpdate(false);
      fetchIpAddress(setUserIP);

      updateUserNameInDatabase(randomString, oldName);
    } else {
      const randomString = generateRandomString();
      localStorage.setItem("userName", randomString);
      setUserName(randomString);
      fetchIpAddress(setUserIP);
    }
  };

  const truncateUserName = (name, maxLength) => {
    if (name.length <= maxLength) return name;
    return name.substring(0, maxLength) + "...";
  };

  const generateRandomString = () => {
    const regex = /[a-zA-Z0-9]{5}/;

    const randomString = Math.random().toString(36).substring(2, 7);
    if (randomString.match(regex)) {
      return randomString;
    }
  };

  return (
    <div className="col-lg-3 mt-5 mt-lg-0">
      {userName ? (
        <>
          <h4>
            Welcome, &nbsp;{" "}
            {roomStatus === "inProgress" ? (
              truncateUserName(userName, 8)
            ) : (
              <a onClick={handleChangeNameClick} title="Change Name">
                {truncateUserName(userName, 8)}
              </a>
            )}
          </h4>
        </>
      ) : (
        <div className="name-form">
          <form className="d-flex" onSubmit={(e) => handleNameSubmit(e)}>
            <input
              type="name"
              name="name"
              placeholder="Enter your name"
              className="form-control"
              style={{ width: "170px" }}
            />
            <button
              className="btn btn-success"
              type="submit"
              style={{ marginLeft: "5px" }}
            >
              Submit
            </button>
          </form>
          <button
            className="btn btn-outline-success"
            onClick={handleAnonymousEntry}
            style={{ margin: "4px 0px 0px 4px" }}
          >
            Random
          </button>
        </div>
      )}
    </div>
  );
};

export default NameForm;
