import { useState } from "react";
import { Link } from "react-router-dom";
import { passwordReset } from "./firebase/firebase";
import Swal from "sweetalert2";
import Email from "../assets/img/signup/email.webp";
import LoginSignupMetas from "../metas/sign_up";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[a-z0-9][\w.]+@\w+?(\.\w+)+$/i;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      await passwordReset(email);
      Swal.fire({
        title: "Password Reset Email Sent",
        text: "A password reset email has been sent to your email address. Please check your inbox and follow the instructions to reset your password.",
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        setError("User not found, try again!");
        setEmail("");
      }
    }
  };

  return (
    <>
      <LoginSignupMetas />
      <main id="main">
        <div className="breadcrumbs" data-aos="fade-in">
          <div className="container">
            <h2>Forgot Password</h2>
            <p>
              Enter the email address associated with your account and we'll
              send you a link to reset your password.
            </p>{" "}
          </div>
        </div>
        <div className="sign-up-container">
          <div className="inputs">
            <div className="input">
              <img src={Email} alt="Email" />
              <input
                type="text"
                id="emailInput"
                name="email"
                placeholder="Email"
                value={email}
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="sign-in-error">
            {error && <p className="error-message">{error}</p>}
          </div>{" "}
        </div>
        <div className="fogot-password-submit-button">
          <button
            type="submit"
            className="btn btn-success"
            onClick={handleSubmit}
          >
            Continue
          </button>
        </div>
        <div className="sign-up-link">
          Don't have an account? &nbsp;<Link to="/sign-up"> Sign Up</Link>
        </div>
      </main>
    </>
  );
};

export default ForgotPassword;
