import React from "react";
import { Helmet } from "react-helmet";

const VedicMeta = ({ title }) => {
  return (
    <div>
      <Helmet>
        <title>MathSpeedUp Vedic Math Blogs - {title}</title>
        <meta charSet="UTF-8" />
        <meta name="title" content={`MathSpeedUp Blogs - ${title}`} />
        <meta
          name="description"
          content="Discover the ancient wisdom of Vedic Mathematics through our dedicated blogs. Learn traditional methods for rapid calculations, mental math tricks, and mathematical insights passed down through generations."
        />
        <meta
          name="keywords"
          content="Vedic Mathematics, mental math, rapid calculations, math tricks, calculation shortcuts, ancient math methods, Vedic math techniques, mathematical insights, mental arithmetic, Vedic sutras"
        />
        <meta
          property="article:published_time"
          content="2023-09-17T01:30:57+00:00"
        />
      </Helmet>
    </div>
  );
};

export default VedicMeta;
