import React, { useState, useEffect, useRef } from "react";
import PracticeMetas from "../metas/practice";
import Dropdown from "../components/question_dropdown";
import {
  generateQuestion,
  checkAnswer,
} from "../components/generate_question_function";
import "../assets/css/common_margin.css";
import { trackButtonClick } from "./event_track";
import ToasterMessage from "../components/common/toaster_message";

const Practice = () => {
  const [questionType, setQuestionType] = useState("0"); // Default question type
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [currentQuestionAnswer, setCurrentQuestionAnswer] = useState("");
  const [userAnswer, setUserAnswer] = useState("");
  const [isCorrect, setIsCorrect] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(false); // State to manage submit button disable
  const [correctOnly, setCorrectOnly] = useState(false); // State to manage submit button disable
  const ansInputRef = useRef(null); // Create a ref for the ans input

  useEffect(() => {
    questionTypeChangeHandler({ target: { value: questionType } });
    ansInputRef.current.focus();
  }, []);

  useEffect(() => {
    if (correctOnly) {
      setSubmitDisabled(!(parseInt(userAnswer) === currentQuestionAnswer)); // Enable the submit button when the answer is correct
    }
  }, [userAnswer, currentQuestionAnswer, correctOnly]);

  const changeCorrectOnly = (event) => {
    setCorrectOnly(event.target.checked);
  };

  const questionTypeChangeHandler = (event) => {
    setQuestionType(event.target.value);
    setCurrentQuestion(
      generateQuestion(event.target.value, setCurrentQuestionAnswer)
    );
    setUserAnswer("");
    setIsCorrect(null);
  };

  const handleCheckAnswer = (ans = "") => {
    trackButtonClick("Practice Page");

    checkAnswer(
      ans,
      userAnswer,
      currentQuestionAnswer,
      setIsCorrect,
      questionTypeChangeHandler,
      ansInputRef,
      questionType
    );
  };

  const handleAnswerCheck = (e) => {
    setUserAnswer(e.target.value);
    if (correctOnly) {
      handleCheckAnswer(e.target.value);
    }
  };

  return (
    <>
      <ToasterMessage />

      <PracticeMetas />
      <main id="main">
        <div className="breadcrumbs" data-aos="fade-in">
          <div className="container">
            <h2>Practice</h2>
            <p>Select Type of questions and do infinite practice.</p>
          </div>
        </div>
        <section id="practice" className="practice">
          <div className="container" data-aos="fade-up">
            <div className="row mt-5">
              <div className="col-lg-2"></div>
              <div className="col-lg-8 mt-5 mt-lg-0">
                <div className="row">
                  <div className="col-md-4 col-sm-4"></div>
                  <div className="col-md-4 col-sm-4">
                    <Dropdown
                      questionType={questionType}
                      questionTypeChangeHandler={questionTypeChangeHandler}
                    />
                  </div>
                  <div
                    className="col-md-4 col-sm-4 text-center"
                    style={{ paddingTop: "20px" }}
                  >
                    Submit on correct only :{" "}
                    <input
                      type="checkbox"
                      id="correct_only"
                      name="correct_only"
                      onClick={changeCorrectOnly}
                      defaultChecked={correctOnly}
                      aria-label="Submit on correct only"
                    />
                  </div>
                  <div id="sum" style={{ paddingTop: "20px" }}>
                    <center>
                      <h2>
                        <span id="question" style={{ fontSize: "60px" }}>
                          {currentQuestion.question}
                        </span>
                      </h2>
                      <br />
                      <div id="answer"></div>
                      <input
                        ref={ansInputRef}
                        type="number"
                        name="ans"
                        id="ans"
                        value={userAnswer}
                        onChange={handleAnswerCheck}
                        defaultChecked={correctOnly}
                        aria-label="Enter an answer"
                      />
                      <br />
                      <br />
                      <button
                        onClick={() => handleCheckAnswer(userAnswer)}
                        className="btn btn-primary btn-lg"
                        disabled={correctOnly && submitDisabled}
                      >
                        CHECK
                      </button>
                      {isCorrect && <p className="text-success">Correct!</p>}
                    </center>
                  </div>
                  <div className="container-fluid text-center table"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Practice;
