import { onValue, remove } from "firebase/database";
import React, { useState, useEffect } from "react";
import { handleError } from "../common/error_method";
import { databaseKey } from "../common/database_key";
import { sendNotification } from "../notification_ref";
import { fetchData, subscribeToData } from "../firebase/firebase_methods";
import Host from "../quiz/host";
import ParticipantList from "./participants_list";
import { trackButtonClick } from "../../pages/event_track";

const CurrentParticipants = ({
  roomID,
  redirectToGameRoot,
  userName,
  setMoreQuestions,
  moreQuestions,
  roomStatus,
  questionLength,
  handleUserWon,
  winners,
  setWinners,
}) => {
  const [participants, setParticipants] = useState([]);
  const [lost, setLost] = useState([]);
  const [hostName, setHostName] = useState("");
  const [winnerNotified, setWinnerNotified] = useState(false);
  const [losersNotified, setLosersNotified] = useState(false);
  const [isQuiz, setIsQuiz] = useState(false);
  const [winner, setWinner] = useState();

  const collectionName = databaseKey();
  useEffect(() => {
    if (!roomID) return;

    setIsQuiz(window.location.pathname.includes("quiz"));
    const participantsPath = `${collectionName}/participants/${roomID}`;
    const participantsRef = fetchData(participantsPath);

    if (isQuiz) {
      const quizRef = fetchData(`${collectionName}/quiz/${roomID}`);
      subscribeToData(
        quizRef,
        (snapshot) => {
          const data = snapshot;
          if (data) setHostName(data.host);
        },
        (error) => {
          handleError("Error with the onValue listener for quiz:", error);
        }
      );
    }

    subscribeToData(
      participantsRef,
      (snapshot) => {
        const participantsData = snapshot;
        const participantsArray = Object.values(participantsData);
        setParticipants(participantsArray);

        if (isQuiz) {
          const newWinners = participantsArray
            .filter(
              (participant) => participant.questionIndex >= questionLength
            )
            .map((participant) => participant.userName);

          if (newWinners.includes(userName) && !winners.includes(userName)) {
            newWinners.push(userName);
          }
          setWinners(newWinners);
        } else {
          const newWinner = participantsArray.find(
            (participant) => participant.questionIndex >= questionLength
          );

          if (newWinner && !winnerNotified) {
            const newLost = participantsArray.filter(
              (participant) => participant.questionIndex < questionLength
            );
            setLost(newLost);
            setWinner(newWinner);
            setMoreQuestions(false);
            handleUserWon(newWinner);
            sendNotificationForWinner(newWinner);
            setWinnerNotified(true);
            if (!losersNotified) {
              sendNotificationForLosers(newLost);
              setLosersNotified(true);
            }
          }
        }
      },
      (error) => {
        handleError("Error with the onValue listener:", error);
      }
    );
  }, [
    roomID,
    userName,
    moreQuestions,
    participants.questionIndex,
    questionLength,
    winnerNotified,
    losersNotified,
  ]);

  const sendNotificationForLosers = async (losers) => {
    losers.forEach(async (loser) => {
      if (loser.userName === userName) {
        await sendNotification(
          userName,
          `${loser.userName} you lost the game. Better luck next time!`
        );
      }
    });
  };

  const sendNotificationForWinner = async (winner) => {
    if (winner.userName === userName) {
      await sendNotification(
        userName,
        `${winner.userName} congratulations , you won the game.`
      );
    }
  };

  function handleLeaveClick() {
    trackButtonClick("Leave Game");
    const participantPath = `/${collectionName}/participants/${roomID}`;
    const participantsRef = fetchData(participantPath);
    onValue(
      participantsRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const participantsData = snapshot.val();

          for (const participantID in participantsData) {
            const participant = participantsData[participantID];

            if (participant.userName === userName) {
              const specificParticipantRef = fetchData(
                `${participantPath}/${participantID}`
              );

              remove(specificParticipantRef)
                .then(() => {
                  console.log(`Participant ${userName} removed from Firebase.`);
                })
                .catch((error) => {
                  handleError(
                    `Error removing participant ${userName} from Firebase:`,
                    error
                  );
                });

              break;
            }
          }
        } else {
          handleError("Participants do not exist.");
        }
      },
      (error) => {
        handleError("Error with the onValue listener:", error);
      }
    );
    redirectToGameRoot();
  }

  return (
    <>
      {!isQuiz ? (
        <>
          {winner ? (
            <>
              <div className="text-center">
                <h3 style={{ color: "green" }}>Won: {winner.userName}</h3>
                {lost.length > 0 && (
                  <div className="text-center">
                    {lost.map((lost, index) => (
                      <h3 key={index} style={{ color: "red" }}>
                        Lost: {lost.userName}
                      </h3>
                    ))}
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <ParticipantList
                participants={participants}
                roomStatus={roomStatus}
                questionLength={questionLength}
                hostName={hostName}
                handleLeaveClick={handleLeaveClick}
              />
            </>
          )}
        </>
      ) : (
        <>
          {winners &&
          winners.length > 0 &&
          (winners.includes(userName) || userName === hostName) ? (
            <div className="text-center">
              {isQuiz && (
                <>
                  {userName === hostName ? (
                    <Host participants={participants} />
                  ) : (
                    <h3>Thanks for participating</h3>
                  )}
                </>
              )}
            </div>
          ) : (
            <ParticipantList
              participants={participants}
              roomStatus={roomStatus}
              questionLength={questionLength}
              hostName={hostName}
              handleLeaveClick={handleLeaveClick}
            />
          )}
        </>
      )}
    </>
  );
};

export default CurrentParticipants;
