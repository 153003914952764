import P2pMetas from "../metas/p2p";
import React, { useState, useEffect, useRef } from "react";
import {
  generateQuestion,
  checkAnswer,
} from "../components/generate_question_function";
import NameForm from "../components/p2p/submit_user_name_form";
import CurrentQuestion from "../components/p2p/questions";
import CurrentParticipants from "../components/p2p/current_participants";
import P2pHeader from "../components/p2p/header";
import CreateRoomSection from "../components/p2p/create_room_section";
import ShareUrl from "../components/p2p/share_url";
import RulesSection from "../components/p2p/rules_section";
import Countdown from "../components/p2p/countdown";
import { handleError } from "../components/common/error_method";
import "../assets/css/p2p.css";
import userWon from "../components/game_record";
import { databaseKey } from "../components/common/database_key";
import {
  fetchData,
  getData,
  pushData,
  updateData,
  setData,
  subscribeToData,
} from "../components/firebase/firebase_methods";
import { fetchIpAddress } from "../components/common/fetch_ip";
import { trackButtonClick } from "./event_track";

const P2P = () => {
  const [userName, setUserName] = useState(
    localStorage.getItem("userName") || ""
  );
  const [userToken, setUserToken] = useState(
    localStorage.getItem("userToken") || ""
  );
  const [roomID, setRoomID] = useState("");
  const [roomDetails, setRoomDetails] = useState({});
  const [participantDetails, setParticipantsDetails] = useState({});
  const [currentUserHost, setCurrentUserHost] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [currentQuestionAnswer, setCurrentQuestionAnswer] = useState("");
  const [currentParticipant, setCurrentParticipant] = useState("");
  const [roomStatus, setRoomStatus] = useState("pending");
  const [questionType, setQuestionType] = useState("0");
  const ansInputRef = useRef(null);
  const [userAnswer, setUserAnswer] = useState("");
  const [isCorrect, setIsCorrect] = useState(null);
  const [moreQuestions, setMoreQuestions] = useState(true);
  const [waitingForHost, setWaitingForHost] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const [questionLength, setQuestionLength] = useState();
  const [restartedRounds, setRestartedRounds] = useState(
    parseInt(localStorage.getItem("rounds")) || 1
  );
  const [maxRounds, setMaxRounds] = useState(0);
  const [isChecked, setIsChecked] = useState(true);
  const collectionName = databaseKey();
  const [userIP, setUserIP] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleUserWon = async (winner) => {
    await userWon(winner.userName, "p2p");
  };

  useEffect(() => {
    const participantsRef = fetchData(
      `${collectionName}/participants/${roomID}`
    );

    subscribeToData(participantsRef, (snapshot) => {
      const participants = snapshot;

      if (participants !== null) {
        const restartKeyReceived = Object.values(participants).some(
          (participant) => participant?.restartKey !== undefined
        );

        if (restartKeyReceived) {
          const nullifiedParticipants = Object.fromEntries(
            Object.entries(participants).map(([participantID, participant]) => [
              participantID,
              { ...participant, restartKey: null },
            ])
          );

          setData(
            fetchData(`${collectionName}/participants/${roomID}`),
            nullifiedParticipants
          )
            .then(() => {
              window.location.reload(true);
            })
            .catch((error) => {
              handleError("Error updating participants:" + error);
            });
        }
      }
    });
  }, [roomID, userToken, userName]);

  useEffect(() => {
    if (isCorrect && countdown === 0) {
      if (
        roomDetails.questions &&
        roomDetails.questions[currentQuestionIndex]
      ) {
        setCurrentQuestionIndex(currentQuestionIndex);
        setCurrentQuestion(
          roomDetails.questions[currentQuestionIndex].question
        );
        setCurrentQuestionAnswer(
          roomDetails.questions[currentQuestionIndex].answer
        );
        setIsCorrect(null);
        setUserAnswer("");
      } else {
        setMoreQuestions(false);
      }
    }
  }, [isCorrect, roomDetails, currentQuestionIndex, countdown]);

  useEffect(() => {
    const queryRoomID = getRoomId();
    if (queryRoomID) {
      setRoomID(queryRoomID);
      checkAndRedirect(queryRoomID, userName);
    }
  }, [userName, userToken, roomStatus]);

  useEffect(() => {
    if (roomDetails && roomDetails.questions) {
      if (
        currentQuestionIndex >= 0 &&
        currentQuestionIndex < roomDetails.questions.length
      ) {
        setCurrentQuestion(
          roomDetails.questions[currentQuestionIndex].question
        );
        setCurrentQuestionAnswer(
          roomDetails.questions[currentQuestionIndex].answer
        );
      } else {
        setMoreQuestions(false);
      }
    }
  }, [
    roomDetails,
    currentQuestionIndex,
    roomStatus,
    currentParticipant.status,
  ]);

  const questionTypeChangeHandler = (event) => {
    setQuestionType(event.target.value);
    setUserAnswer("");
    const currentUserParticipant = participantDetails[userToken];

    if (currentUserParticipant) {
      currentUserParticipant.questionIndex += 1;

      setParticipantsDetails({
        ...participantDetails,
        [currentUserParticipant.participantID]: currentUserParticipant,
      });

      setCurrentQuestionIndex(currentQuestionIndex + 1);
      updateParticipantField(
        userToken,
        "questionIndex",
        currentQuestionIndex + 1
      );
    }
  };

  const getRoomId = () => {
    const query = new URLSearchParams(window.location.search);
    return query.get("roomID");
  };

  const fetchRoomData = async (queryRoomID) => {
    if (queryRoomID) {
      const roomPath = `${collectionName}/rooms/${queryRoomID}`;
      const roomRef = fetchData(roomPath);

      const participantsPath = `${collectionName}/participants/${queryRoomID}`;
      const participantsRef = fetchData(participantsPath);

      try {
        const [roomSnapshot, participantsSnapshot] = await Promise.all([
          getData(roomRef),
          getData(participantsRef),
        ]);

        if (roomSnapshot.exists() && participantsSnapshot.exists()) {
          const roomData = roomSnapshot.val();
          const participantsData = participantsSnapshot.val();

          await setRoomObjects(roomData, participantsData);

          subscribeToData(
            roomRef,
            async (roomSnapshot) => {
              try {
                const roomData = roomSnapshot;
                await setRoomObjects(roomData, participantsData);
              } catch (error) {
                handleError("Error in onValue listener for room data:" + error);
              }
            },
            (error) => {
              handleError(
                "Error with the onValue listener for room data:" + error
              );
            }
          );

          subscribeToData(
            participantsRef,
            async (participantsSnapshot) => {
              try {
                const participantsData = participantsSnapshot;
                await setRoomObjects(roomData, participantsData);
              } catch (error) {
                handleError(
                  "Error in onValue listener for participants data:" + error
                );
              }
            },
            (error) => {
              handleError(
                "Error with the onValue listener for participants data:" + error
              );
            }
          );
        } else {
          redirectToGameRoot("Room does not exist.");
        }
      } catch (error) {
        handleError("Error fetching room data:" + error);
        redirectToGameRoot("Error fetching data.");
      }
    }
  };

  const setRoomObjects = async (roomData, participantData) => {
    setParticipantsDetails(participantData);
    setRoomDetails(roomData);
    setRoomStatus(roomData.status);
    setQuestionLength(roomData.questions.length);
    setCurrentUserHost(userName === roomData.host);
    setMaxRounds(roomData.rounds);
    for (const participantID in participantData) {
      if (participantID === userToken) {
        const matchingParticipant = participantData[participantID];
        setCurrentParticipant(matchingParticipant);
        setCurrentQuestionIndex(matchingParticipant.questionIndex);
      }
    }
  };

  const checkAndRedirect = async (queryRoomID, userName) => {
    await fetchRoomData(queryRoomID);
    const participantsRef = fetchData(
      `${collectionName}/participants/${queryRoomID}`
    );
    const participantsSnapshot = await getData(participantsRef);
    if (participantsSnapshot.exists()) {
      const participantsData = participantsSnapshot.val();
      const userTokenExists = Object.keys(participantsData).some(
        (participantID) => participantID === userToken
      );
      fetchIpAddress(setUserIP);

      if (!userTokenExists && userName) {
        const newParticipant = {
          status: "pending",
          questionIndex: 0,
          answers: {},
          userName: userName,
          userIP: userIP,
        };

        try {
          const newParticipantRef = await pushData(
            participantsRef,
            newParticipant
          );
          const newkey = newParticipantRef.key;
          localStorage.setItem("userToken", newkey);
          setParticipantsDetails({
            ...participantsData,
            [newkey]: newParticipant,
          });
          window.location.href = `/player-vs-player?roomID=${queryRoomID}`;
        } catch (error) {
          handleError("Error adding new participant:" + error);
        }
      }
    }
  };

  const updateFirebaseRoomDetails = async (updatedDetails) => {
    try {
      const roomPath = `${collectionName}/rooms/${roomID}`;
      await updateData(fetchData(roomPath), updatedDetails);
    } catch (error) {
      handleError("Error updating Firebase room details:" + error);
    }
  };

  const handleReadyClick = async () => {
    trackButtonClick("ready p2p");
    const updatedStatus = "ready";
    updateParticipantField(userToken, "status", updatedStatus);
    setWaitingForHost(true);
  };

  const handleStartClick = async () => {
    trackButtonClick("Start p2p");
    let countdownValue = 10;
    roomDetails.status = "inProgress";
    setRoomStatus("inProgress");
    updateFirebaseRoomDetails(roomDetails);
    const countdownRef = fetchData(
      `${collectionName}/rooms/${roomID}/countdown`
    );

    try {
      await setData(countdownRef, countdownValue);
      for (const participantID in participantDetails) {
        const participant = participantDetails[participantID];
        if (participant.status === "ready") {
          updateParticipantField(participantID, "status", "inGame");
        }
      }
    } catch (error) {
      handleError("Error updating countdown value in Firebase: " + error);
    }
  };

  const updateParticipantField = async (userToken, field, newValue) => {
    try {
      const participantRef = fetchData(
        `${collectionName}/participants/${roomID}/${userToken}`
      );
      const participantSnapshot = await getData(participantRef);
      if (participantSnapshot.exists()) {
        const updates = {};
        updates[field] = newValue;
        await updateData(participantRef, updates);
      } else {
        handleError(`Participant not found with userToken: ${userToken}`);
      }
    } catch (error) {
      handleError(`Error updating participant ${field}:` + error);
    }
  };

  const redirectToGameRoot = (msg = "") => {
    let url = "/player-vs-player";
    if (msg !== "") {
      url += "/?msg=" + msg;
    }
    window.location.href = url;
  };

  const generateQuestions = (
    questionCount,
    questionType,
    setCurrentQuestionAnswer
  ) => {
    const generatedQuestionsAndAnswers = [];
    for (let i = 0; i < questionCount; i++) {
      const { question, answer } = generateQuestion(
        questionType,
        setCurrentQuestionAnswer
      );
      generatedQuestionsAndAnswers.push({ question, answer });
    }
    return generatedQuestionsAndAnswers;
  };

  const handleCreateRoomClick = async (numQuestions, numberOfRounds) => {
    trackButtonClick("Create Room");
    setIsLoading(true);
    let generatedRoomID = "";
    let generatedParticipantID = "";
    const generatedQuestionsAndAnswers = generateQuestions(
      numQuestions,
      questionType,
      setCurrentQuestionAnswer
    );
    try {
      const roomsRef = fetchData(`${collectionName}/rooms/`);
      let roomData = {
        created_at: new Date().toString(),
        host: userName,
        questionType: questionType,
        status: "pending",
        questions: generatedQuestionsAndAnswers,
        rounds: numberOfRounds,
      };

      let roomRef = await pushData(roomsRef, roomData);
      generatedRoomID = roomRef.key;

      if (isChecked) {
        const roomURL = `${window.location.origin}/player-vs-player?roomID=${generatedRoomID}`;
        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleString();
        const message = `Let's play together! Join me: ${roomURL}`;
        const chatMessage = {
          message,
          sent_by: userName,
          sent_at: formattedDate,
          readBy: [""],
        };
        const chatRef = fetchData(`${collectionName}/global_chat/`);
        await pushData(chatRef, chatMessage);
        subscribeToData(chatRef, async (snapshot) => {
          let messageCount = 0;
          const snapshot_array = snapshotToArray(snapshot);

          snapshot_array.forEach(() => {
            messageCount++;
          });
          const messageCountRef = fetchData(
            `${collectionName}/global_chat/message_count`
          );
          const messageCountSnapshot = await getData(messageCountRef);

          if (messageCountSnapshot.exists()) {
            await updateData(messageCountRef, { count: messageCount });
          } else {
            setData(messageCountRef, { count: messageCount });
          }
        });
      }
      const participantsRef = fetchData(
        `${collectionName}/participants/${generatedRoomID}`
      );

      const newParticipantData = {
        status: "ready",
        questionIndex: 0,
        answers: {},
        userName: userName,
        userIP: userIP,
      };

      let participantRef = await pushData(participantsRef, newParticipantData);
      generatedParticipantID = participantRef.key;
      localStorage.setItem("userToken", generatedParticipantID);
    } catch (error) {
      handleError("Error uploading form data:" + error);
    }
    setIsLoading(false);
    window.location.href = `/player-vs-player?roomID=${generatedRoomID}`;
  };

  const handleCheckAnswer = (ans = "") => {
    trackButtonClick("P2p Check answer Button");

    checkAnswer(
      ans,
      userAnswer,
      currentQuestionAnswer,
      setIsCorrect,
      questionTypeChangeHandler,
      ansInputRef,
      questionType
    );
  };

  const handleRestartClick = async () => {
    trackButtonClick("Restart p2p Button");
    if (maxRounds > restartedRounds) {
      const newRestartedRounds = restartedRounds + 1;
      localStorage.setItem("rounds", newRestartedRounds);

      try {
        const roomRef = fetchData(`${collectionName}/rooms/${roomID}`);
        const participantsRef = fetchData(
          `${collectionName}/participants/${roomID}`
        );
        const [roomSnapshot, participantsSnapshot] = await Promise.all([
          getData(roomRef),
          getData(participantsRef),
        ]);

        if (roomSnapshot.exists() && participantsSnapshot.exists()) {
          const roomData = roomSnapshot.val();
          const participantData = participantsSnapshot.val();
          const generatedQuestionsAndAnswers = generateQuestions(
            roomData.questions.length,
            roomData.questionType,
            setCurrentQuestionAnswer
          );
          const updatedRoomData = {
            ...roomData,
            status: "pending",
            questions: generatedQuestionsAndAnswers,
          };

          await updateData(
            fetchData(`${collectionName}/rooms/${roomID}`),
            updatedRoomData
          );

          for (const participantID in participantData) {
            const participant = participantData[participantID];
            if (participant.userName === roomData.host) {
              participantData[participantID] = {
                ...participant,
                status: "ready",
                questionIndex: 0,
                restartKey: generateRestartKey(),
              };
            } else {
              participantData[participantID] = {
                ...participant,
                status: "pending",
                questionIndex: 0,
                restartKey: generateRestartKey(),
              };
            }
          }

          await setData(
            fetchData(`${collectionName}/participants/${roomID}`),
            participantData
          );
        } else {
          handleError("Room or participants data not found.");
        }
      } catch (error) {
        handleError("Error restarting the room:" + error);
      }
    } else {
      localStorage.removeItem("rounds");
      redirectToGameRoot();
    }
  };

  const generateRestartKey = () => {
    return Math.random().toString(36).substring(2, 15);
  };

  const snapshotToArray = (snapshot) => {
    return snapshot ? Object.values(snapshot) : [];
  };

  return (
    <>
      <P2pMetas />
      <main id="main">
        <P2pHeader />
        <section id="p2p" className="p2p">
          <div className="container" data-aos="fade-up">
            <div className="row mt-5" style={{ marginTop: "1rem!important" }}>
              <NameForm
                setUserName={setUserName}
                userName={userName}
                roomStatus={roomStatus}
                roomID={roomID}
                setUserIP={setUserIP}
              />
              <div className="col-lg-6 mt-5 mt-lg-0 text-center">
                {roomID && (
                  <>
                    {roomStatus === "pending" && (
                      <>
                        {currentUserHost && (
                          <>
                            <button
                              onClick={handleStartClick}
                              className="btn btn-success"
                            >
                              Start
                            </button>
                          </>
                        )}
                      </>
                    )}
                    {roomStatus === "pending" &&
                      ["pending"].includes(currentParticipant.status) && (
                        <>
                          <button
                            onClick={handleReadyClick}
                            className="btn btn-primary"
                          >
                            Ready
                          </button>
                        </>
                      )}
                    {roomStatus === "pending" && waitingForHost && userName && (
                      <p>Waiting for the host to start...</p>
                    )}
                  </>
                )}
                {roomStatus === "inProgress" &&
                  userName &&
                  currentParticipant.status === "inGame" && (
                    <Countdown
                      roomID={roomID}
                      roomStatus={roomStatus}
                      setCountdown={setCountdown}
                      countdown={countdown}
                    />
                  )}
                {roomStatus === "inProgress" &&
                  currentParticipant.status === "pending" && (
                    <p>
                      Game is already in progress. Please join another game.
                    </p>
                  )}
                <CreateRoomSection
                  roomID={roomID}
                  userName={userName}
                  questionType={questionType}
                  questionTypeChangeHandler={questionTypeChangeHandler}
                  handleCreateRoomClick={handleCreateRoomClick}
                  isChecked={isChecked}
                  setIsChecked={setIsChecked}
                  isLoading={isLoading}
                />
                <div className="current-user-questions">
                  {roomStatus === "inProgress" &&
                    currentParticipant.status === "inGame" &&
                    currentQuestion &&
                    moreQuestions && (
                      <>
                        <div className="current-user-questions">
                          <CurrentQuestion
                            currentQuestion={currentQuestion}
                            userAnswer={userAnswer}
                            ansInputRef={ansInputRef}
                            setUserAnswer={setUserAnswer}
                            handleCheckAnswer={handleCheckAnswer}
                            countdown={countdown}
                          />
                        </div>
                        <br></br>
                      </>
                    )}
                </div>
              </div>
              {roomID && (
                <>
                  <CurrentParticipants
                    roomID={roomID}
                    redirectToGameRoot={redirectToGameRoot}
                    userName={userName}
                    setMoreQuestions={setMoreQuestions}
                    moreQuestions={moreQuestions}
                    roomStatus={roomStatus}
                    questionLength={questionLength}
                    handleUserWon={handleUserWon}
                  />
                  <ShareUrl
                    currentQuestion={currentQuestion}
                    roomID={roomID}
                    userName={userName}
                  />
                </>
              )}
            </div>
            <br></br>
            <div className="text-center">
              {currentUserHost &&
                !moreQuestions &&
                roomStatus === "inProgress" && (
                  <button
                    onClick={handleRestartClick}
                    className="btn btn-danger"
                    style={{ margin: "10px" }}
                  >
                    Restart
                  </button>
                )}
            </div>
          </div>
          <RulesSection />
        </section>
      </main>
    </>
  );
};

export default P2P;
