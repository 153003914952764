import React from "react";
import { Helmet } from "react-helmet";

const QuoridorMetas = () => {
  return (
    <div>
      <Helmet>
        <title>Quoridor Game | Strategy Board Game</title>
        <meta charSet="UTF-8" />
        <meta name="title" content="Quoridor Game | Strategy Board Game" />
        <meta
          name="description"
          content="Quoridor is a strategy board game that challenges players to outmaneuver their opponents by building walls and navigating through a maze. Test your strategic skills now!"
        />
        <meta
          name="keywords"
          content="Quoridor, Board game, Strategy game, Maze game, Multiplayer game, Wall-building game, Tactical game, Gaming, Strategy, Tactics, Puzzle, Fun, Challenge"
        />
        <meta
          property="article:published_time"
          content="2024-03-14T12:00:00+00:00"
        />
      </Helmet>
    </div>
  );
};

export default QuoridorMetas;
