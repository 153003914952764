import React from "react";

const CreateRoomButton = ({ handleCreateRoomClick, isLoading }) => {
  return (
    <div className="col-md-12 text-center mt-3">
      <button className="btn btn-success" onClick={handleCreateRoomClick}>
        {isLoading ? "Loading..." : "Create Room"}
      </button>
    </div>
  );
};

export default CreateRoomButton;
