import React, { useState } from "react";
import ContactMetas from "../metas/contact";
import { handleError } from "../components/common/error_method";
import { databaseKey } from "../components/common/database_key";
import { fetchData, pushData } from "../components/firebase/firebase_methods";
import { fetchIpAddress } from "../components/common/fetch_ip";
import { trackButtonClick } from "./event_track";

const Contact = () => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [userIP, setUserIP] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    fetchIpAddress(setUserIP);
    trackButtonClick("Contact Page");

    const form = e.target;
    const formData = new FormData(form);

    // Extract form data
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const message = formData.get("message");
    const read = false;
    try {
      // Upload form data to Firebase Realtime Database
      const collectionName = databaseKey();
      if (collectionName) {
        const contactsRef = fetchData(`${collectionName}/contacts-us`);
        await pushData(contactsRef, {
          name,
          email,
          phone,
          message,
          read,
          timestamp: new Date().toString(),
          userIP: userIP,
        });
      }
      form.reset();
      setIsFormSubmitted(true);
      setErrorMessage("");

      // Display a success message or redirect to a thank-you page, etc.
    } catch (error) {
      setErrorMessage("Failed to submit the form. Please try again later.");

      handleError("Error uploading form data:", error);
    }
  };

  return (
    <>
      <ContactMetas />
      <main id="main">
        <div className="breadcrumbs" data-aos="fade-in">
          <div className="container">
            <h2>Contact Us</h2>
            <p>
              We are a team of talented people who encourage others to have good
              calculation speed.
            </p>
          </div>
        </div>
        <section id="contact" className="contact">
          <div data-aos="fade-up">
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  title="maplocation"
                  className="gmap_iframe"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                  style={{ border: 0, width: "100%", height: "350px" }}
                  src="https://maps.google.com/maps?hl=en&amp;q=kanha park rau&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="container" data-aos="fade-up">
            <div className="row mt-5">
              <div className="col-lg-4">
                <div className="info">
                  <div className="address">
                    <i className="bi bi-geo-alt"></i>
                    <h4>Location:</h4>
                    <p>Kanha park, Rau</p>
                  </div>
                  <div className="email">
                    <i className="bi bi-envelope"></i>
                    <h4>Email:</h4>
                    <p>manishprajapat94@gmail.com</p>
                  </div>

                  {/* <div className="phone">
                    <i className="bi bi-phone"></i>
                    <h4>Call:</h4>
                    <p>+1 5589 55488 55s</p>
                  </div> */}
                </div>
              </div>

              <div className="col-lg-8 mt-5 mt-lg-0">
                <form id="contactForm" onSubmit={handleSubmit}>
                  <p className="required-field">
                    Required field <span>*</span>
                  </p>
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Name*"
                        required
                        autoComplete="off"
                      />
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Email*"
                        required
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      id="phone"
                      placeholder="Phone*"
                      required
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      className="form-control"
                      name="message"
                      rows="5"
                      id="message"
                      placeholder="Message*"
                      required
                      autoComplete="off"
                    ></textarea>
                  </div>
                  <div className="my-3">
                    <div className="error-message"></div>
                    {/* Error message */}
                    {errorMessage && (
                      <div className="error-message">{errorMessage}</div>
                    )}
                    {isFormSubmitted && (
                      <div className="sent-message">
                        Your message has been sent. Thank you!
                      </div>
                    )}
                  </div>
                  <div className="text-center submit-button">
                    <button className="btn btn-success" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Contact;
