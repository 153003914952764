import React from "react";

const Input = ({ message, setMessage, sendMessage, handleKeyPress }) => {
  return (
    <div className="message-input">
      <input
        className="message-input-field"
        id="message"
        name="message"
        type="text"
        placeholder="Type your message..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyPress={handleKeyPress}
      />
      <button
        className="send-button"
        onClick={sendMessage}
        aria-label="send message"
      >
        Send
      </button>
    </div>
  );
};

export default Input;
