import AboutUsContent from "../components/about_us_content";
import Testimonials from "../components/testimonials";
import StudentCount from "../components/student_count";
import AboutMetas from "../metas/about";

function About() {
  return (
    <>
      <AboutMetas />
      <main id="main">
        <>
          <div className="breadcrumbs" data-aos="fade-in">
            <div className="container">
              <h2>About Us</h2>
              <p>
                We are a team of talented people who encourage others to have
                good calculation speed.
              </p>
            </div>
          </div>
          <section id="about" className="about">
            <div className="container" data-aos="fade-up">
              <AboutUsContent />
            </div>
          </section>

          <StudentCount />
          <Testimonials />
        </>
      </main>
    </>
  );
}

export default About;
