import React from 'react';

const NotFound = () => {
  return (
    <>
      <main id="main">
        <div className="breadcrumbs" data-aos="fade-in">
          <div className="container">
            <h2>Not Found</h2>
          </div>
        </div>
        <section id="about" className="about">
          <div className="container text-center" data-aos="fade-up">
            <h1 className="text-center">404 - Page Not Found</h1>
            <p className="text-center">The page you are looking for does not exist.</p>
          </div>
        </section>
      </main>
    </>
  );
};

export default NotFound;
