import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";

const ToasterMessage = () => {
  const [message] = useState(localStorage.getItem("toastMessage"));
  useEffect(() => {
    if (message) {
      showToastMessage(message);
      localStorage.removeItem("toastMessage");
    }
  }, [message]);

  const showToastMessage = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  return <ToastContainer />;
};

export default ToasterMessage;
