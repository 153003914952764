import { useEffect, useState } from "react";
import React from "react";
import Pawn from "../../assets/img/pawn1.webp";
import Pawn1 from "../../assets/img/pawn2.webp";
import { findAdjacentOccupiedCell, drawCondition } from "./occupied_cell";

const Grid = ({
  roomDetails,
  handleCellClick,
  participantDetails,
  currentTurn,
  currentParticipant,
  checkForBorder,
  updateDraw,
}) => {
  const [borderObjects, setBorderObjects] = useState({});
  const [nextTurnPawnCell, setNextTurnPawnCell] = useState(null);

  useEffect(() => {
    const borders = {};
    Object.values(participantDetails).forEach((participant) => {
      if (participant.borderObject) {
        participant.borderObject.forEach((border) => {
          const key = `${border.row}-${border.col}`;
          borders[key] = participant.userName;
        });
      }
      checkBlockedCell(borders);
    });
    setBorderObjects(borders);

    const nextParticipant = Object.values(participantDetails).find(
      (participant) => participant.userName === currentTurn
    );
    setNextTurnPawnCell(
      nextParticipant ? `${nextParticipant.row}-${nextParticipant.col}` : null
    );
  }, [participantDetails, currentTurn]);

  const checkBlockedCell = (borderObjects) => {
    drawCondition(borderObjects, currentParticipant, updateDraw);
  };

  const handleClick = (row, col) => {
    handleCellClick(row, col, borderObjects);
  };

  const getCellClassName = (row, col) => {
    let cellClassName = "grid-cell";
    const key = `${row}-${col}`;

    if (borderObjects[key]) {
      cellClassName +=
        borderObjects[key] === roomDetails.host
          ? " host-wall"
          : " participant-wall";
    }

    const hasBorder = checkForBorder(
      currentParticipant.row,
      currentParticipant.col,
      row,
      col,
      borderObjects
    );

    const { isAdjacentCellOccupied, occupiedCell } = findAdjacentOccupiedCell(
      currentParticipant,
      participantDetails
    );

    if (isAdjacentCellOccupied && currentParticipant.userName === currentTurn) {
      if (currentParticipant.row === occupiedCell.row) {
        if (col + 2 === occupiedCell.col || col - 2 === occupiedCell.col) {
          const isOccupiedByParticipant = isCellOccupiedByParticipant(row, col);
          if (
            row === occupiedCell.row &&
            !hasBorder &&
            !isOccupiedByParticipant
          ) {
            cellClassName += " highlighted-cell";
          }
        } else if (occupiedCell.col > currentParticipant.col) {
          if (borderObjects[`${occupiedCell.row}-${occupiedCell.col + 1}`]) {
            if (
              (row === occupiedCell.row + 2 || row === occupiedCell.row - 2) &&
              col === occupiedCell.col &&
              !hasBorder
            ) {
              cellClassName += " highlighted-cell";
            }
          }
        } else if (
          occupiedCell.col < currentParticipant.col &&
          borderObjects[`${occupiedCell.row}-${occupiedCell.col - 1}`]
        ) {
          const hasBorder = checkForBorder(
            currentParticipant.row,
            currentParticipant.col,
            row,
            col,
            borderObjects
          );

          if (
            (row === occupiedCell.row + 2 || row === occupiedCell.row - 2) &&
            col === occupiedCell.col &&
            !hasBorder
          ) {
            cellClassName += " highlighted-cell";
          }
        }
      } else {
        if (row + 2 === occupiedCell.row || row - 2 === occupiedCell.row) {
          if (col === occupiedCell.col && !hasBorder && !hasBorder) {
            cellClassName += " highlighted-cell";
          }
        } else {
          if (occupiedCell.row < currentParticipant.row) {
            if (borderObjects[`${occupiedCell.row - 1}-${occupiedCell.col}`]) {
              if (
                (col === occupiedCell.col + 2 ||
                  col === occupiedCell.col - 2) &&
                row === occupiedCell.row &&
                !hasBorder
              ) {
                cellClassName += " highlighted-cell";
              }
            }
          } else if (
            occupiedCell.row > currentParticipant.row &&
            borderObjects[`${occupiedCell.row + 1}-${occupiedCell.col}`]
          ) {
            if (
              (col === occupiedCell.col + 2 || col === occupiedCell.col - 2) &&
              row === occupiedCell.row &&
              !hasBorder
            ) {
              cellClassName += " highlighted-cell";
            }
          }
        }
      }
      if (occupiedCell.col === 0 || occupiedCell.col === 12) {
        if (
          (row === occupiedCell.row - 2 || row === occupiedCell.row + 2) &&
          col === occupiedCell.col &&
          !hasBorder
        ) {
          cellClassName += " highlighted-cell";
        }
      } else if (occupiedCell.row === 0 || occupiedCell.row === 12) {
        if (
          (col === occupiedCell.col - 2 || col === occupiedCell.col + 2) &&
          row === occupiedCell.row &&
          !hasBorder
        ) {
          cellClassName += " highlighted-cell";
        }
      }
    }

    if (currentParticipant.userName === currentTurn) {
      if (
        (row === currentParticipant.row - 2 &&
          col === currentParticipant.col) ||
        (row === currentParticipant.row + 2 &&
          col === currentParticipant.col) ||
        (row === currentParticipant.row &&
          col === currentParticipant.col - 2) ||
        (row === currentParticipant.row && col === currentParticipant.col + 2)
      ) {
        const isOccupiedByParticipant = isCellOccupiedByParticipant(row, col);

        if (!hasBorder && !isOccupiedByParticipant) {
          cellClassName += " highlighted-cell";
        }
      }
    }

    if (key === nextTurnPawnCell) {
      cellClassName += " next-turn-pawn-cell";
    }

    return cellClassName;
  };

  const isCellOccupiedByParticipant = (row, col) => {
    return Object.values(participantDetails).some(
      (participant) => participant.row === row && participant.col === col
    );
  };

  return (
    <div className="grid-parent-container">
      {Object.values(participantDetails).map((participant, index) => (
        <span key={index} className="fan-count">
          {participant.userName === roomDetails.host && (
            <>
              {[...Array(participant.fan)].map((_, fanIndex) => (
                <span key={fanIndex} className="fan-icon"></span>
              ))}
            </>
          )}
        </span>
      ))}
      <div className="grid-container">
        {[...Array(13)].map((_, row) => (
          <React.Fragment key={row}>
            {[...Array(13)].map((_, col) => {
              return (
                <div
                  key={col}
                  className={getCellClassName(row, col)}
                  onClick={() => handleClick(row, col)}
                >
                  {Object.values(participantDetails).map((participant) => (
                    <React.Fragment key={participant.userName}>
                      {row === participant.row &&
                        col === participant.col &&
                        (participant.userName === roomDetails.host ? (
                          <img src={Pawn} className="pawn-icon" alt="Pawn" />
                        ) : (
                          <img src={Pawn1} className="pawn-icon" alt="Pawn" />
                        ))}
                    </React.Fragment>
                  ))}
                </div>
              );
            })}
          </React.Fragment>
        ))}
      </div>
      {Object.values(participantDetails).map((participant, index) => (
        <span key={index} className="fan-count">
          {participant.userName !== roomDetails.host && (
            <>
              {" "}
              {[...Array(participant.fan)].map((_, fanIndex) => (
                <span
                  key={fanIndex}
                  className="fan-icon fan-icon non-host"
                ></span>
              ))}
            </>
          )}
        </span>
      ))}
    </div>
  );
};

export default Grid;
