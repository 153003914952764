import React from "react";
import { Helmet } from "react-helmet";

const ProfileMetas = () => {
  return (
    <div>
      <Helmet>
        <title>User Profile | MathSpeedUp</title>
        <meta charSet="UTF-8" />
        <meta name="title" content="User Profile | MathSpeedUp" />
        <meta
          name="description"
          content="Manage your MathSpeedUp profile, track your progress, and enjoy personalized math challenges!"
        />
        <meta
          name="keywords"
          content="MathSpeedUp user profile, Math progress tracking, Personalized math challenges, Math achievements, Math performance, User statistics, Math profile management, MathSpeedUp account, Math leaderboard, Math achievements, Math progress monitoring, User dashboard, Math user experience, Personalized math practice, Math gaming profile, Math learning profile"
        />
        <meta
          property="article:published_time"
          content="2023-09-17T01:30:57+00:00"
        />
      </Helmet>
    </div>
  );
};

export default ProfileMetas;
