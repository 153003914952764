import React, { useEffect, useState } from "react";
import CommonChat from "./chat/chat";
import { databaseKey } from "../common/database_key";
import { fetchData, subscribeToData } from "../firebase/firebase_methods";

const GlobalChat = ({ userName, onClose }) => {
  const collectionName = databaseKey();
  const chatRef = fetchData(`${collectionName}/global_chat`);
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    const unsubscribe = subscribeToData(chatRef, (snapshot) => {
      if (snapshot) {
        const messageData = snapshot;
        const messageList = Object.values(messageData);
        let count = 0;
        messageList.forEach((message) => {
          if (message.readBy && message.readBy.includes(userName)) {
            return;
          } else {
            count++;
          }
        });
        setUnreadCount(count);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [chatRef, userName]);

  return (
    <CommonChat
      chatRef={chatRef}
      userName={userName}
      onClose={onClose}
      unreadCount={unreadCount}
      chat="global-chat"
    />
  );
};

export default GlobalChat;
