const AboutUsContent = () => {
  return (
    <>
      <div className="row">
        <div
          className="col-lg-6 order-1 order-lg-2"
          data-aos="fade-left"
          data-aos-delay="100"
        >
          <img
            src="assets/img/about.webp"
            className="img-fluid"
            alt="About Math Speed up"
            title="Math Speed Up - About Us"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
          <h3>We understand that every student learns differently.</h3>
          <p className="fst-italic">
            That's why our website offers personalized learning experiences.
            Through adaptive learning technology, we identify each student's
            strengths and areas for improvement, tailoring practice sessions to
            meet their specific needs.
          </p>
          <ul>
            <li>
              <i className="bi bi-check-circle"></i> We believe in the power of
              a supportive community.{" "}
            </li>
            <li>
              <i className="bi bi-check-circle"></i> Students can interact with
              peers, discuss math challenges, and share insights.{" "}
            </li>
            <li>
              <i className="bi bi-check-circle"></i> Our platform fosters a
              collaborative learning environment that enhances the overall
              learning journey.
            </li>
          </ul>
          <p>
            Join us today and embark on a fun-filled math adventure that will
            empower you to excel in this crucial subject!
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutUsContent;
