import vedicMathData from "./vedic_math_data";
import { Link } from "react-router-dom";

const VedicMath = () => {
  return (
    <>
      <div className="blog-container">
        <div className="container">
          <div className="row">
            {vedicMathData.map((blog) => (
              <div key={blog.id} className="card">
                <Link
                  to={`/readers/vedic-math/${blog.id}`}
                  className="link-style"
                >
                  <img
                    src={blog.image}
                    className="card-img-top"
                    alt={blog.title}
                  />
                  <div className="card-body">
                    <p className="card-title">
                      <strong>{blog.title}</strong>
                    </p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default VedicMath;
