import { useEffect } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";

const DisabledInspect = ({ rightClickDisabled }) => {
  const threshold = 160;
  let devTools = false;

  const detectDevTools = () => {
    const widthThreshold = window.outerWidth - window.innerWidth > threshold;
    const heightThreshold = window.outerHeight - window.innerHeight > threshold;
    const orientationThreshold =
      window.orientation === 90 || window.orientation === -90;

    if (widthThreshold || heightThreshold || orientationThreshold) {
      if (!devTools) {
        devTools = true;
        showAlert();
      }
    } else {
      if (devTools) {
        devTools = false;
      }
    }
  };

  useEffect(() => {
    if (!window.location.origin.includes("mathspeedup")) {
      return;
    }

    const ctrlShiftKey = (e, keyCode) =>
      e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);

    const handleKeyDown = (e) => {
      if (
        e.keyCode === 123 ||
        ctrlShiftKey(e, "I") ||
        ctrlShiftKey(e, "J") ||
        ctrlShiftKey(e, "C") ||
        (e.ctrlKey && e.keyCode === "U".charCodeAt(0))
      ) {
        if (rightClickDisabled) {
          e.preventDefault();
        }
      }
    };

    window.addEventListener("resize", detectDevTools);
    window.addEventListener("mousemove", detectDevTools);
    window.addEventListener("focus", detectDevTools);
    document.addEventListener("keydown", handleKeyDown);

    detectDevTools();

    return () => {
      window.removeEventListener("resize", detectDevTools);
      window.removeEventListener("mousemove", detectDevTools);
      window.removeEventListener("focus", detectDevTools);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [rightClickDisabled]);

  // useEffect(() => {
  //   if (devToolsOpen) {
  //     Swal.fire({
  //       title: "Operation denied!",
  //       text: "Please close the Developer Tools to continue using the page.",
  //       icon: "warning",
  //       showCancelButton: false,
  //       confirmButtonColor: "#3085d6",
  //       confirmButtonText: "OK",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         detectDevTools();
  //       }
  //     });
  //   }
  // }, [devToolsOpen]);

  const showAlert = () => {
    alert("Please close the Developer Tools to continue using the page.");
    setTimeout(showAlert, 0);
  };

  return null;
};

export default DisabledInspect;
