import React from "react";

const RulesSection = () => {
  return (
    <>
      <div className="container">
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#startGame"
                aria-expanded="false"
                aria-controls="startGame"
              >
                Start the Game
              </button>
            </h2>
            <div
              id="startGame"
              className="accordion-collapse collapse"
              aria-labelledby="startGame"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul>
                  <li>
                    <h5>Select Difficulty Level:</h5>
                    <div>
                      <p>
                        Participants will see a dropdown with the following
                        options:
                      </p>
                      <ol>
                        <li>
                          <strong>Easy:</strong>
                          <ul>
                            <li>Digit Range: 1 to 15</li>
                            <li>Symbols: +, -</li>
                          </ul>
                        </li>
                        <li>
                          <strong>Medium:</strong>
                          <ul>
                            <li>Digit Range: 15 to 100</li>
                            <li>Symbols: +, -, *, /</li>
                          </ul>
                        </li>
                        <li>
                          <strong>Hard:</strong>
                          <ul>
                            <li>Digit Range: 100 to 500</li>
                            <li>Symbols: +, -, *, /, %</li>
                          </ul>
                        </li>
                        <li>
                          <strong>Custom:</strong>
                          <ul>
                            <li>
                              Participants can select their own digit range and
                              symbols.
                            </li>
                            <li>
                              Options include:
                              <ul>
                                <li>Operations</li>
                                <li>Maximum Number</li>
                                <li>Number of Iterations</li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li>Click Start Button</li>
                      </ol>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="gameRulesHeader">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#gameRules"
                aria-expanded="false"
                aria-controls="gameRules"
              >
                Game Rules
              </button>
            </h2>
            <div
              id="gameRules"
              className="accordion-collapse collapse"
              aria-labelledby="gameRulesHeader"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul>
                  <li>
                    <h5>During the Game:</h5>
                    <ul>
                      <li>
                        Each number and symbol will be shown inside a circle
                        sequentially.
                      </li>
                      <li>
                        You need to remember the numbers and their respective
                        operations.
                      </li>
                      <li>
                        After the iterations are finished, an input box will be
                        shown.
                      </li>
                      <li>Enter the correct answer in the input box.</li>
                      <li>
                        If the answer is correct, a "You got it right!" message
                        will appear.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h5>Restart the Game:</h5>
                    <ul>
                      <li>
                        Click the "Restart" button to start the game again.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RulesSection;
