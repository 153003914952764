import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import vedicMathData from "./vedic_math_data";
import BlogContent from "./blog-content";
import VedicMeta from "../../metas/vedic_blog";
import { useNavigate } from "react-router-dom";

const VedicBlog = () => {
  const { id } = useParams();
  const selectedBlog = vedicMathData.find((blog) => blog.id === id);
  const currentIndex = vedicMathData.findIndex((blog) => blog.id === id);
  const [index, setIndex] = useState(currentIndex);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  if (!selectedBlog) {
    return (
      <div className="container">
        <h2>Blog not found</h2>
        <p>Sorry, the blog you're looking for doesn't exist.</p>
      </div>
    );
  }

  const handlePrevious = () => {
    const previousIndex = currentIndex - 1;
    if (previousIndex >= 0) {
      setIndex(previousIndex);
      navigate(`/readers/vedic-math/${vedicMathData[previousIndex].id}`);
    }
  };

  const handleNext = () => {
    const nextIndex = currentIndex + 1;
    if (nextIndex < vedicMathData.length) {
      setIndex(nextIndex);
      navigate(`/readers/vedic-math/${vedicMathData[nextIndex].id}`);
    }
  };

  const previousBlog =
    currentIndex > 0 ? vedicMathData[currentIndex - 1] : null;
  const nextBlog =
    currentIndex < vedicMathData.length - 1
      ? vedicMathData[currentIndex + 1]
      : null;
  const isLastBlog = currentIndex === vedicMathData.length - 1;

  return (
    <>
      <VedicMeta title={selectedBlog.title} />
      <div className="breadcrumbs" data-aos="fade-in">
        <div className="container">
          <h2>Welcome to our Math Vedic Blog</h2>
          <p>
            Explore the ancient techniques of Vedic Mathematics. Learn rapid
            calculation methods, mental math tricks, and traditional techniques
            to enhance your mathematical skills and speed.
          </p>
        </div>
      </div>
      <div className="blog-container">
        <BlogContent
          title={selectedBlog.title}
          content={selectedBlog.content}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          index={currentIndex}
          previousTitle={previousBlog ? previousBlog.title : null}
          nextTitle={nextBlog ? nextBlog.title : null}
          isLastBlog={isLastBlog}
        />
      </div>
    </>
  );
};

export default VedicBlog;
