export const databaseKey = () => {
  let collectionName;
  let path = window.location.origin;

  collectionName = sessionStorage.getItem("collectionName");

  return collectionName
    ? collectionName
    : (() => {
        if (path.includes("localhost")) {
          collectionName = "localhost";
        } else if (path.includes("dev")) {
          collectionName = "dev";
        } else if (path.includes("mathspeedup")) {
          collectionName = "mathspeedup";
        }

        sessionStorage.setItem("collectionName", collectionName);

        return collectionName;
      })();
};
