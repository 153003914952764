import { get } from "firebase/database";
import { fetchData } from "../firebase/firebase_methods";
import { databaseKey } from "./database_key";
import Cookies from "js-cookie";

export const siteSetting = async () => {
  const collectionName = databaseKey();
  const site_settingRef = fetchData(
    `${collectionName}/site_settings/global_chat`
  );
  const snapshot = await get(site_settingRef);
  if (snapshot.exists()) {
    const value = snapshot.val();
    Cookies.set("global-chat", value, {
      expires: new Date(new Date().getTime() + 5 * 60 * 1000),
    });
    return value;
  }
};
