import ReactGA from "react-ga";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const TrackPage = () => {
  ReactGA.initialize("G-54Z7E0RMQT");

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return null;
};

export default TrackPage;
