import { handleError } from "../components/common/error_method";
import { databaseKey } from "./common/database_key";
import {
  fetchData,
  getData,
  setData,
  updateData,
} from "./firebase/firebase_methods";

const RATING_CATEGORIES = {
  IRON: "Iron",
  BRONZE: "Bronze",
  SILVER: "Silver",
  DIAMOND: "Diamond",
};

const collectionName = databaseKey();
const userWon = async (winner, game_type) => {
  const userName = localStorage.getItem("userName");
  try {
    const userGameRecordRef = fetchData(
      `${collectionName}/game_records/${userName}`
    );
    const userGameRecordSnapshot = await getData(userGameRecordRef);
    if (winner) {
      if (userGameRecordSnapshot.exists()) {
        const userData = userGameRecordSnapshot.val();
        let points = userData.points || 0;
        if (winner === userName) {
          game_type === "p2p" ? (points += 5) : (points += 1);
        } else {
          points += 1;
        }

        await updateData(userGameRecordRef, {
          points: points,
        });
      } else {
        let points = 0;
        if (winner === userName) {
          game_type === "p2p" ? (points += 5) : (points += 1);
        }

        await setData(userGameRecordRef, {
          username: userName,
          points: points,
          rank: RATING_CATEGORIES.IRON,
        });
      }

      await calculateRank();
    }
  } catch (error) {
    handleError("Error recording user win: " + error);
  }
};

const calculateRank = async () => {
  try {
    const userGameRecordRef = fetchData(`${collectionName}/game_records/`);
    const userGameRecordSnapshot = await getData(userGameRecordRef);

    if (userGameRecordSnapshot.exists()) {
      const userData = userGameRecordSnapshot.val();
      let usersArray = Object.entries(userData);

      usersArray = usersArray.sort((a, b) => b[1].points - a[1].points);

      const totalUsers = usersArray.length;
      const diamondIndex = Math.ceil(0.02 * totalUsers);
      const silverIndex = diamondIndex + Math.ceil(0.2 * totalUsers);
      const ironIndex = silverIndex + Math.ceil(0.3 * totalUsers);

      for (const [userId, user] of usersArray) {
        let rank;
        const index = usersArray.findIndex((u) => u[0] === userId);

        if (index < diamondIndex) {
          rank = RATING_CATEGORIES.DIAMOND;
        } else if (index < silverIndex) {
          rank = RATING_CATEGORIES.SILVER;
        } else if (index < ironIndex) {
          rank = RATING_CATEGORIES.IRON;
        }

        const userGameRecordRef = fetchData(
          `${collectionName}/game_records/${userId}`
        );

        await updateData(userGameRecordRef, {
          rank: rank,
        });
      }
    }
  } catch (error) {
    handleError("Error calculating users' ranks: " + error);
  }
};

export default userWon;
