// import logo from './logo.svg';
import AboutUsContent from "./components/about_us_content";
// import StudentCount from "./components/student_count";
import Testimonials from "./components/testimonials";
import WhyUs from "./components/why_us";
import Features from "./components/features";
import PopularGames from "./components/popular_games";
// import Trainers from "./components/trainers";
import HomePageMetas from "./metas/index";
import "./App.css";

function App() {
  return (
    <>
      <HomePageMetas />
      {/* <!-- ======= Hero Section ======= --> */}
      <section
        fetchpriority="high"
        id="hero"
        className="d-flex justify-content-center align-items-center"
      >
        <div
          className="container position-relative"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <h1>
            Fast Minds,
            <br /> Great Team
          </h1>
          <h2>
            We are a team of talented people who encourage others to have good
            calculation speed.
          </h2>
          {/* <a href="courses.html" className="btn-get-started" title="Get Started">Get Started</a> */}
        </div>
      </section>
      {/* <!-- End Hero --> */}
      <main id="main">
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <AboutUsContent />
          </div>
        </section>

        <WhyUs />
        <Features />
        {/* <StudentCount /> */}
        <Testimonials />
        <PopularGames />
        {/*<Trainers /> */}
      </main>
    </>
  );
}

export default App;
