// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBFUj7yWCNhcUBIVhppMHmmeTH9p8MxmgI",
  authDomain: "mathsapp-prod.firebaseapp.com",
  projectId: "mathsapp-prod",
  storageBucket: "mathsapp-prod.appspot.com",
  messagingSenderId: "655775507259",
  appId: "1:655775507259:web:99fa42c2fc0f1851028d1e",
  measurementId: "G-4MDM960W6R",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const auth = getAuth();

export default app;
export { database, auth }; // Export the database instance
