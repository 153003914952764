import React, { useEffect, useState } from "react";
import ShareButton from "../common/share_button";
import axios from "axios";
import { handleError } from "../common/error_method";
import { trackButtonClick } from "../../pages/event_track";

const ShareUrl = ({ currentQuestion, roomID }) => {
  const [buttonText, setButtonText] = useState("Copy Url");
  const [completeURL, setCompleteURL] = useState(
    `${window.location.origin}${window.location.pathname}?roomID=${roomID}`
  );
  const [shortenURL, setShortenURL] = useState();

  useEffect(() => {
    const fetchURL = async () => {
      const completeURL = `${window.location.origin}${window.location.pathname}?roomID=${roomID}`;
      setCompleteURL(completeURL);
      try {
        const shortenedURL = await shortenUrl(completeURL);
        setShortenURL(shortenedURL);
      } catch (error) {
        handleError("Error shortening URL:", error);
      }
    };

    fetchURL();
  }, [roomID]);

  const shortenUrl = async (completeURL) => {
    try {
      const response = await axios.post(
        "https://tinyurl.com/api-create.php",
        null,
        {
          params: { url: completeURL },
        }
      );

      return response.data;
    } catch (error) {
      handleError("Error shortening URL:", error);
      throw error;
    }
  };

  const copyRoomURLToClipboard = async () => {
    trackButtonClick("Copy URL");

    if (roomID) {
      navigator.clipboard
        .writeText(shortenURL)
        .then(() => {
          setButtonText("Copied!");
        })
        .catch((error) => {
          handleError("Error copying URL to clipboard:", error);
        });
    }
  };

  const buttonStyle = {
    marginBottom: "10px",
    marginRight: "10px",
  };

  return (
    <>
      {currentQuestion && (
        <>
          <div className="row text-center" style={{ marginTop: "20px" }}>
            <div className="col-sm-3"></div>
            <div className="col-sm-6">
              {completeURL} <br />
              <br />
              <button
                onClick={copyRoomURLToClipboard}
                className="btn btn-primary"
                style={buttonStyle}
              >
                {buttonText}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-copy"
                  viewBox="0 0 16 16"
                  style={{ marginLeft: "10px" }}
                >
                  <path
                    fillRule="evenodd"
                    d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
                  />
                </svg>
              </button>
              <ShareButton
                title="MathSpeedUp"
                text="Join me in a challenging game! Can you beat me?"
                url={shortenURL}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ShareUrl;
