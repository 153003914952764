import { useEffect, useState } from "react";
import {
  getAuth,
  onAuthStateChanged,
  updateProfile,
  verifyBeforeUpdateEmail,
  signOut,
  updatePassword,
} from "firebase/auth";
import profile from "../assets/img/signup/profile.jpg";
import "../assets/css/user_profile.css";
import { handleError } from "../components/common/error_method";
import ProfileMetas from "../metas/user_profile";
import { databaseKey } from "../components/common/database_key";
import { fetchData, getData } from "../components/firebase/firebase_methods";
import { Link, useNavigate } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import Swal from "sweetalert2";
import { trackButtonClick } from "./event_track";

const UserProfile = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [userRank, setUserRank] = useState("");
  const [userName, setUserName] = useState("");
  const [isAnonymous, setIsAnonymous] = useState();
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const storedUserName = localStorage.getItem("userName") || "";
    setUserName(storedUserName);
  }, []);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        setIsAnonymous(firebaseUser.isAnonymous);
        const { displayName, email } = firebaseUser;
        if (!displayName || !email) {
          setName(userName);
        } else {
          setName(displayName);
          setEmail(email);
        }
      }
    });

    return () => unsubscribe();
  }, [userName]);

  useEffect(() => {
    const fetchUserRank = async () => {
      try {
        const collectionName = databaseKey();
        const userGameRecordRef = fetchData(
          `${collectionName}/game_records/${userName}`
        );
        const userGameRecordSnapshot = await getData(userGameRecordRef);
        if (userGameRecordSnapshot.exists()) {
          const userData = userGameRecordSnapshot.val();
          setUserRank(userData.rank || "");
        } else {
          setUserRank("Iron");
        }
      } catch (error) {
        handleError("Error fetching user rank: " + error);
      }
    };

    fetchUserRank();
  }, [userName]);

  const handleSaveClick = async () => {
    trackButtonClick("User Profile Page");

    if (name === "" || email === "" || (isAnonymous && password === "")) {
      setError("Please fill out all fields.");
      return;
    }

    const auth = getAuth();
    const user = auth.currentUser;

    try {
      const currentUserEmail = user.email;

      if (currentUserEmail !== email) {
        await verifyBeforeUpdateEmail(user, email);

        if (isAnonymous && password) {
          await updatePassword(user, password);
        }

        Swal.fire({
          title: "Email Verification Required",
          text: "Email verification required. Please check your email inbox for a verification link. Before clicking 'OK', please verify your email. You will not be able to log in otherwise.",
          icon: "info",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            signOut(auth)
              .then(() => {
                navigate("/sign-up");
              })
              .catch((error) => {
                handleError(error);
              });
          }
        });
      }

      await updateProfile(user, {
        displayName: name,
      });

      localStorage.setItem("userName", name);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <ProfileMetas />
      <div className="breadcrumbs" data-aos="fade-in">
        <div className="container">
          <h2>User Profile</h2>
          <p>
            Welcome to your profile! Here, you can manage your account details
            and track your progress in MathSpeedUp. Edit your information or
            explore more features below.
          </p>
        </div>
      </div>
      <div className="profile-container">
        <div className="profile-box">
          <h2>My Profile</h2>
          <img className="profile-img" src={profile} alt="Profile" />
          <div className="profile-details">
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoComplete="off"
              readOnly={!isAnonymous}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={email}
              required
              autoComplete="off"
              onChange={(e) => setEmail(e.target.value)}
              readOnly={!isAnonymous}
            />
            {isAnonymous && (
              <input
                type="password"
                name="password"
                placeholder="password"
                value={password}
                required
                autoComplete="off"
                onChange={(e) => setPassword(e.target.value)}
              />
            )}
            <button className="save-profile">
              {isAnonymous ? (
                <span onClick={handleSaveClick}>Update Profile</span>
              ) : (
                <Link
                  to="/user_profile/update-email"
                  style={{ height: "45px", width: "auto" }}
                >
                  Edit Email
                </Link>
              )}
            </button>

            <div className="rank">
              {error ? (
                <span style={{ fontSize: "20px" }}>{error}</span>
              ) : (
                <span className="user_rank">{userRank}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
