import "../../assets/css/blog.css";
import vedicImage1 from "../../assets/img/vedic/vedic-1.webp";
import vedicImage2 from "../../assets/img/vedic/vedic-2.webp";
import vedicImage3 from "../../assets/img/vedic/vedic-3.webp";
import vedicImage4 from "../../assets/img/vedic/vedic-4.webp";
import vedicImage5 from "../../assets/img/vedic/vedic-5.webp";
import vedicImage6 from "../../assets/img/vedic/vedic-6.webp";
import vedicImage7 from "../../assets/img/vedic/vedic-7.webp";
import vedicImage8 from "../../assets/img/vedic/vedic-8.webp";
import vedicImage9 from "../../assets/img/vedic/vedic-9.webp";
import vedicImage10 from "../../assets/img/vedic/vedic-10.webp";
import vedicImage11 from "../../assets/img/vedic/vedic-11.webp";
import vedicImage12 from "../../assets/img/vedic/vedic-12.webp";
import vedicImage13 from "../../assets/img/vedic/vedic-13.webp";
import vedicImage14 from "../../assets/img/vedic/vedic-14.webp";
import vedicImage15 from "../../assets/img/vedic/vedic-15.webp";
import vedicImage16 from "../../assets/img/vedic/vedic-16.webp";
import addition from "../../assets/img/vedic/addition.webp";
import subtractionImg from "../../assets/img/vedic/subtraction.webp";
import cubeRootImg from "../../assets/img/vedic/cube_root.webp";
import squareRoot1Img from "../../assets/img/vedic/square-root-1.webp";
import squareRoot2Img from "../../assets/img/vedic/square-root-2.webp";
import square1 from "../../assets/img/vedic/VedicMathSquare1.webp";
import square2 from "../../assets/img/vedic/VedicMathSquare2.webp";
import multiplicationImg1 from "../../assets/img/vedic/multiplication1.webp";
import multiplicationImg2 from "../../assets/img/vedic/multiplication2.webp";
import multiplicationImg3 from "../../assets/img/vedic/multiplication3.webp";
import multiplicationImg4 from "../../assets/img/vedic/multiplication4.webp";
import multiplicationImg5 from "../../assets/img/vedic/multiplication5.webp";
import multiplicationImg6 from "../../assets/img/vedic/multiplication6.webp";
import multiplicationImg7 from "../../assets/img/vedic/multiplication7.webp";
import multiplicationImg8 from "../../assets/img/vedic/multiplication8.webp";
import multiplicationImg9 from "../../assets/img/vedic/multiplication9.webp";
import multiplicationImg10 from "../../assets/img/vedic/multiplication10.webp";
import multiplicationImg11 from "../../assets/img/vedic/multiplication11.webp";
import multiplicationImg12 from "../../assets/img/vedic/multiplication12.webp";
import cube1 from "../../assets/img/vedic/VedicMathCube1.webp";
import cube2 from "../../assets/img/vedic/VedicMathCube2.webp";
import cube3 from "../../assets/img/vedic/VedicMathCube3.webp";

const vedicMathData = [
  {
    title: "Vedic Maths Addition Tricks",
    image: vedicImage1,
    id: "Vedic-Maths-Addition-Tricks",
    content: `
      <p>We have various tricks to perform the addition in Vedic maths. In this section, you will learn how to add numbers using one of the sutras called Ekadhikena Purvena with the help of an example.</p>
      <strong>Example:</strong>
      <p>Compute: 98765 + 63217 + 89522 + 60543</p>
      <p>Or</p>
      <p>By Sutra Ekadhikena Purvena, add 98765, 63217, 89522, and 60543.</p>
      <strong>Solution:</strong>
      <p>Steps for adding numbers using Ekadhiken Purvena Sutra:</p>
      <h4>Step 1:</h4>
      <p>Write the given numbers in rows and columns by giving some space between the digits.</p>
      <h4>Step 2:</h4>
      <p>Column I (from the right side), add the first two digits, 5 + 7 = 12</p>
      <h4>Step 3:</h4>
      <p>Mark Ekadhika dot(.) on 1, (digit which is next to 7 in column II)</p>
      <h4>Step 4:</h4>
      <p>Now, start again adding with 2;<br>2 + 2 = 4<br>Again start with 4 such that 4 + 3 = 7</p>
      <h4>Step 5:</h4>
      <p>Write 7 below at the answer’s place</p>
      <h4>Step 6:</h4>
      <p>Add the remaining columns in the same way.</p>
      <p>Thus, the final answer will be obtained as:</p>
      <p>Vedic maths addition</p>
      <div class="image-container">  
        <img src="${addition}" alt="Vedic Maths Addition Example" />
      </div>
    `,
  },
  {
    title: "Vedic Maths Subtraction Tricks",
    image: vedicImage2,
    id: "Vedic-Maths-Subtraction-Tricks",
    content: `
        <p> Subtraction can be performed using 4 or 5 different methods in Vedic mathematics, and the best, as well as easiest way to subtract the numbers, is the Sutra Ekadhiken Purvena and Param Mitra Unka (the best friend). Here, two digits are called each other’s best friends if their sum is equal to 10. For example, 3 is the best friend of 7 since 3 + 7 = 10.</p>
        <p>Go through the example given below to understand the subtraction of numbers by Sutra Ekadhiken Purvena.</p>
        <strong>Question:</strong>
        <p>Subtract 389 from 746.</p>
        <strong>Solution:</strong>
        <p>Steps for subtraction in Sutra Ekadhiken Purvena:</p>
        <h4>Step 1:</h4> <p>Write the given numbers in rows and columns by giving some space between the digits.</p>
        <h4>Step 2:</h4> <p>Consider column I (from the right end), 9 is greater than 6 so we cannot subtract it from 6.
        1 is the best friend of 9 and add 1 to 6, i.e. 1 + 6 = 7. So write 7 in the answer place and mark Ekadhika dot(.) on 8, which is in the same row of column II such that it becomes 9 (as 8 + 1 = 9).
        Here, dot(.) on the number represents one more than the previous number.</p>
        <h4>Step 3:</h4> <p>Similarly, we need to subtract the remaining numbers. Thus, the answer will be:</p>
        <div class="image-container">
          <img src="${subtractionImg}" alt="Vedic Maths Subtraction Example" />
        </div>  
        <p>Therefore, 746 – 789 = 357.</p>
`,
  },
  {
    title: "Vedic Maths Multiplication Tricks",
    image: vedicImage3,
    id: "Vedic-Maths-Multiplication-Tricks",
    content: `<p> Like addition and subtraction, multiplication can also be done using different sutras in Vedic maths. In this section, you will learn two simple methods of multiplying numbers along with examples.</p>
            <strong>Method 1:</strong>
            <p>In this method, we can multiply the numbers whose unit digits are added up to 10 or powers of 10.</p>
            <p>Let’s have a look at the solved example given below to understand the multiplication of numbers.</p>
            <strong>Example:</strong>
            <p>Multiply 63 and 67.</p>
            <strong>Solution:</strong>
            <p>63 × 67</p>
            <p>Sum of unit digits = 3 + 7 = 10</p>
            <p>Digits in tens places = 6</p>
            <p>So, we can write the multiplication as:</p>
            <p>63 × 67 = 6 × (6 + 1)/3 × 7
            = 6 × 7/3 × 7
            = 42/21
            = 4221 </p>
            <p>We can also verify the result using normal mathematical calculations.</p>
            <p>This method of multiplication is referred to as the Sutra Ekadhiken Purvena. This method can also be used to multiply two numbers whose last two digits are added up to 100, the last three digits are added up to 1000. Also, in the case of mixed fractions, the sum of proper fractions must be added up to 1 to apply this method of multiplication.</p>
            <strong>Method 2:</strong>
            <p>If two numbers are to be multiplied and one of these numbers is having only 9’s then we can apply this method.</p>
            <strong>Example:</strong>
            <p>Multiply 876 and 999.</p>
            <strong>Solution:</strong>
            <p>Given, two numbers are 876 and 999.</p>
            <p>Now, subtract 1 from 876.</p>
            <p>876 – 1 = 875</p>
            <p>Subtract 875 from 999.</p>
            <p>999 – 875 = 124</p>
            <p>Thus,</p>
            <p>876 × 999 = 876 – 1/999 – 875
            = 875/124
            = 875124</p>
            <p>This method of multiplying numbers is Sutra Ekanyunena Purvena.</p>
            <p>Similarly, there are many sutras in Vedic maths to perform the multiplication of numbers.</p>
    `,
  },
  {
    title: "Vedic Maths Division Tricks",
    image: vedicImage4,
    id: "Vedic-Maths-Division-Tricks",
    content: `<h4>When the divisor is smaller and closer to the power of 10.</h4>
            <strong> Step 1: </strong> <p>Identify how much short the divisor is to the power of 10.</p>
            <strong> Step 2: </strong> <p>Split the dividend into 2 parts – Quotient and Remainder. The number of digits considered as the remainder is the same as the number of digits in the divisor.</p>
            <strong> Step 3: </strong> <p>Multiply the first digit of the quotient with the number obtained from step 1. Add this to the unit’s digit of the quotient part (from step 2).</p>
            <strong> Step 4: </strong> <p>Multiply the number obtained from step 3 with the number from step 1. Add this number to the Remainder part (from step 2).</p>
           <strong>Step 5:</strong>
            <p>Now, check if the remainder is greater than or equal to the divisor. If yes, divide it again and add the new quotient part to the quotient. The remainder obtained now is the final remainder.</p>
            <p>Let us see an example below:</p>
            <p><strong>Example: Divide 341 by 9</strong></p>
            <strong>Step 1:</strong>
            <p>Deficiency = 1</p>
            <strong>Step 2:</strong>
            <p>Split 341 -> Quotient and Remainder</p>
            <strong>Step 3:</strong>
            <p>Initial quotient = 3 (first digit of 341)</p>
            <strong>Step 4:</strong>
            <p>Multiply 3 (initial quotient) by 1 (deficiency) = 3. Subtract 3 from 4 (next digit) = 1. Write 1 next to 3 (quotient so far is 31). Repeat for the last digit: Multiply 1 by 1 = 1. Subtract 1 from 1 (last digit) = 0.</p>
            <strong>Step 5:</strong>
            <p>Final quotient = 37, Remainder = 8</p>
    `,
  },
  {
    title: "Vedic Maths Cube Root Tricks",
    image: vedicImage5,
    id: "Vedic-Maths-Cube-Root-Tricks",
    content: `
    <p>Finding cube root in a quick manner can be very important for the fast Calculations in any competitive exam. Let us see how to find the cube root of a number?</p>
    <div class="image-container">    
      <img src="${cubeRootImg}" alt="Vedic Maths Cube Root Tricks" />
    </div>
    <p>The <b>cube root</b> of a number is another number that, when multiplied three times, makes the first number.</p>
    <p><strong style="font-style: italic; font-family: 'Times New Roman', Times, serif;">Example: </strong>The cube root of <strong>27</strong> is <strong>3</strong> or <strong>&#8731;27</strong> = <strong>3</strong> , becouse <strong>3 X 3 X 3 = 27</strong></p><br>
    <strong> Now </strong>
    <br><br>
    <p>Let us find the cube root of 39304 through a shortcut method involving four steps:</p>
    <strong>STEP 1:</strong>
    <p>
    To find the <b>unit place of the cube root</b> always remember the following points:<br><br>

      •    If the last digit of a cube root is 8 then the unit digit will be 2.<br>
      •    If the last digit of a cube root is 2 then the unit digit will be 8.<br>
      •    If the last digit of a cube root is 3 then the unit digit will be 7.<br>
      •    If the last digit of a cube root is 7 then the unit digit will be 3.<br>
      •    If the last digit of a cube root is other than 2, 3, 7 and 8 then put the same number as the unit digit.<br><br>

    Therefore, the unit digit will be 4.</p>
    <strong>STEP 2:</strong>
    <p>Now, strike off the <b>last 3 digits </b> of the given number.</p>
    <p>  39304</p>
    <strong>STEP 3:</strong>
    <p>Now, find the <b>nearest cube</b> of the first 2 digit from the left, i.e., 39.</p>
    <p>The nearest cube is 27.</p>
    <strong>STEP 4:</strong>
    <p> Now, 3 is the cube root of 27.</p>
    <p>Therefore, the <b>ten’s place digit will be 3.</b></p><br>
    <p> So, the answer will be 34.</p>
    `,
  },
  {
    title: "Vedic Maths Square Root of Perfect Squares Tricks",
    image: vedicImage6,
    id: "Vedic-Maths-Square-Root-of-Perfect-Squares-Tricks",
    content: `
    <p>Finding Square Roots in the traditional way, i.e. by long division method is quite time-consuming.</p>
    <p>However, there is a much simpler and faster method of finding square roots of a perfect square. In Vedic Maths, we use Sutra “The First by the First and the Last by the Last“.</p>
    <p>A perfect square is an integer that is the square of another integer. For example, 1024 is a perfect square of 32. Here, 1024 and 32 both are integers.</p>
    <p>Square of 32 or 32 ^2 = 1024.</p>
    <p>So, the square root of 1024 or √1024 is 32.</p>
    <p>Let’s understand the method to find the square root of any perfect square with this example.</p>
    <p><b>√1024 = 32</b></p>
    <strong>Step 1:</strong><br>
    <p>We have to mark the digits in the number in pairs starting from the right, i.e. 24 as one pair, 10 as another pair in 1024.</p>
    <p>The leftmost pair gives the left-most digit of the answer.</p>
    <p>The rightmost pair give the right-most digit of the answer.</p>
    <strong>Step 2: Find the leftmost part of the answer.</strong><br>
    <p>Consider the leftmost pair first, i.e. 10 in this case is the leftmost pair.</p>
    <p>The largest number whose square is less than 10 is 3 (3^2 = 9).</p>
    <p>So, the leftmost digit of the answer is 3.</p>
    <strong>Step 3: Find the rightmost part of the answer.</strong><br>
    <p>Consider the rightmost pair, i.e. 24 in this case is the rightmost pair.</p>
    <p>Since the number 1024 ends in 4, so the last digit in the answer will be either 2 or 8 (as only square of 2 (= 4) or square of (8 = 64) ends in 4).</p>
    <p>How to chose whether 2 or 8 is the last digit?<br>
    From Step 2, find the remainder of 10 – 9 (= 1). As 1 is less than 3 (obtained from step 2), the last digit will be 2.</p>
    <p>Had the above remainder been greater than 3, then the last digit would have been 8.</p><br>
    <strong>Let’s understand this from below graphical representation:</strong><br><br><br>
    <div class="image-container">
      <img src="${squareRoot1Img}" alt="Vedic Maths Square Root of Perfect Squares Example" />
    </div><br><br>
    <p>Take another example:</p><br>
    <p><b>√2209 = 47</b></p><br>
    <div class="image-container">
      <img src="${squareRoot2Img}" alt="Vedic Maths Square Root of Perfect Squares Example" />
    </div>
        `,
  },
  {
    title: "Vedic Maths Square Tricks",
    image: vedicImage7,
    id: "Vedic-Maths-Square-Tricks",
    content: `
      <p>Calculating Vedic Math Square Tricks can be classified in following types:</p>
      <strong><p>1. Yavadunam (Specific Method)</p></strong>
      <strong><p>2. Ekadhikena Purvena (Specific Method)</p></strong>
      <strong><p>3. DvandaYoga (General Method)</p></strong>
      <h4>Yavadunam:</h4>
      <p>It is a specific and shorcut to square numbers using Vedic Mathematics whenever number is closer to power of 10. (10, 100, 1000, ….)</p>
      <p>Lets see examples for  vedic maths square method of Yavadunam:</p>
      <strong style="font-size: smaller; color: green;">Square of 14:</strong><br><br>
      <p>142 = (14+4)/42 = 18/16  = 196</p>
      <p>Here 14 is 4 more than 10(Base 10), So Excess = 4</p>
      <p>Increase it still further to that extent, So (14+4) = 18</p>
      <p>Square its excessive, So 42= 16</p>
      <p>Final Answer: 196</p>
      <strong style="font-size: smaller; color: green;">Square of 97:</strong><br><br>
      <p>972 = (97-3)/32 = 94/09  = 9409</p>
      <p>Here 97 is 3 less than 100(Base 100), So deficiency =3</p>
      <p>Reduce it still further to that extent, So (97-3) = 94.</p>
      <p>Square its deficiency, So 32 = 09. (As base is 100, we need exactly 2 digits. Hence 09).</p>
      <p>Final Answer: 9409</p>
      <p>Click Here => To Check More examples on Squares Tricks using Yavadunam Sutra</p>
      <h4>Ekadhikena Purvena:</h4>
      <p>This is another specific vedic maths tricks for square of a number ending with 5.</p>
      <p>Lets see examples for vedic squares tricks using Ekadhikena Purvena.</p>
      <p>Check if last digit is 5, if yes – square of 5 is 25</p>
      <p>Apply Ekadhikena Purvena for rest of the number i.e. Add 1 to the previous number and multiply each other. Example in case of square of 85, Add 1 to 8 to get 9 and multiply this with 8.</p>
      <p>Steps 1 and 2 together gives final answer.</p><br>
      <div class="image-container">
        <img src="${square1}" alt="Vedic Maths Square Example" /><br><br>
      </div>
      <h4>DvandaYoga (Duplex Method):</h4>
      <p>Dvanda Yoga is general method to square any number in vedic maths. Dvanda Yoga or Duplex Method is shortcut method for squares of large numbers.</p>
      <p><strong>How to calculate Dvanda</strong></p>
      <strong>Concept:</strong>
      <p> D(3) = 32 = 9</p>
      <p> D(43) = 2x4x3 = 24</p>
      <p>D(567) = 2x5x7 + 62 = 70 + 36 = 106</p>
      <p>D(3456) = 2x3x6 + 2x4x5 = 36 + 40 = 76</p>
      <p>D(34567) = 2x3x7 + 2x4x6 + 52 = 42 + 48 + 25 = 115</p>
      <strong>Example:</strong>
      <div class="image-container">
        <br><img src="${square2}" alt="Vedic Maths Square Example" /><br>
      </div>
    `,
  },
  {
    title: "Vedic Maths Fast Multiplication Tricks",
    image: vedicImage8,
    id: "Vedic-Maths-Fast-Multiplication-Tricks",
    content: `
    <h4>Trick 1: Multiplying with 11 or 111 or 1111</h4>
    <p> Multiplying with 11 is like left shifting the number by one digit and then adding it. 25 times 11 (25 x 11) is nothing but 25 + left shifted 25 = 275</p>
    <div class="image-container">
      <img src="${multiplicationImg1}" alt="Vedic Maths Fast Multiplication Tricks Example" />
    </div>
    <p>Let’s try 35 x 111 -> add 35 three times by left shifting</p>
    <div class="image-container">
      <img src="${multiplicationImg2}" alt="Vedic Maths Fast Multiplication Tricks Example" />
    </div>
    <p>Let’s try one more, 63 x 11</p>
    <div class="image-container">
      <img src="${multiplicationImg3}" alt="Vedic Maths Fast Multiplication Tricks Example" />
    </div>
    <h4>Trick 2: Squares of similar numbers ending with 5s</h4>
    <p>Multiplying two numbers ending in 5s is done by multiplying the left side of the numbers with one of them incremented and then adding 25 at the end. For example, 25 x 25 is (2×3)=6 is the prefix and add 25 as the postfix to it. So, the answer is 625.</p>
    <div class="image-container">
      <img src="${multiplicationImg5}" alt="Vedic Maths Fast Multiplication Tricks Example" style="width: 400px; height: 200px;"/><br>
      <img src="${multiplicationImg4}" alt="Vedic Maths Fast Multiplication Tricks Example" />
    </div>
    <h4>Trick 3: Multiplying when the sum of unit digits is 10</h4>
    <p>This Sutra is called Ekadhikina Purvena. If the sum of the unit digits of the two numbers equals 10, then we can use it.</p>
    <p>For example, let’s try the number: 33 x 37. The sum of the unit digits of both numbers (3+7) is 10. So, we can use the sutra here.</p>
    <p>(first, digit x one more than the first) (Unit digit of 1st number x Unit digit of the 2nd number) = (3 x 4) (3 x 7) = 1221</p>
    <p>Let’s try one more problem: 24 x 26</p>
    <p>Again, the unit digits add to 1. So, the LHS is (2 x 3) = 6. And the RHS is (4 x 6) = 24. Answer is 624.</p>
    <div class="image-container">
      <img src="${multiplicationImg6}" alt="Vedic Maths Fast Multiplication Tricks Example" />
    </div>
    <p>Let’s try one more problem: 163 x 167</p>
    <div class="image-container">
      <img src="${multiplicationImg7}" alt="Vedic Maths Fast Multiplication Tricks Example" />
    </div>
    <p>This only works when the left side of the numbers are the same. For example, this doesn’t work in the below case.</p>
    <div class="image-container">
      <img src="${multiplicationImg8}" alt="Vedic Maths Fast Multiplication Tricks Example" />
    </div>
    <h4>Trick 4: Multiplication by 9s</h4>
    <p>Ekanyunena Purvena – When the multiplier is only 9, this sutra may be used to determine the product of two numbers.</p>
    <p>Let’s try a problem: 44 x 99=?</p>
    <p>The process to solve it is:</p>
    <p>Step 1 – Reduce 1 from multiplicand ie. 44-1 = 43 </p>
    <p>Step 2 – The other part of the answer would be 99 – 43 = 56 </p>
    <p>Hence the answer is 4356</p>
    <p>Alternatively, you can look at it as 44 x 100 – 44 = 4400 – 44 = 4356</p>
    <h4>Trick 5: Multiplying numbers by 5</h4>
    <p>Half the number and add 0. Or half the number without the decimal point if there is a decimal point when you half the number</p>
    <div class="image-container">
      <img src="${multiplicationImg9}" alt="Vedic Maths Fast Multiplication Tricks Example" /><br>
      <img src="${multiplicationImg10}" alt="Vedic Maths Fast Multiplication Tricks Example" />
    </div>
    <p>Let’s try one more example: 55 x 5  (when you half 55 you get 27.5, so the answer is 275)</p>
    <div class="image-container">
      <img src="${multiplicationImg11}" alt="Vedic Maths Fast Multiplication Tricks Example" />
    </div>
    <h4>Trick 8: Multiplying by 9</h4>
    <p>Say, multiply 64 x 99</p>
    <p>Reduce the left number by 1, so 64-1 = 63</p>
    <p>Reduce the number 64 from 100 = 36</p>
    <p>The answer is 6336</p>
    <p>Let’s take one more example – 567 *999</p>
    <p>The answer is (567-1)(1000-567) = 566433</p>
    <div class="image-container">
      <img src="${multiplicationImg12}" alt="Vedic Maths Fast Multiplication Tricks Example" />
    </div>
    `,
  },
  {
    title: "Vedic Maths Linear, Quadratic and Cubic Polynomials",
    image: vedicImage9,
    id: "Vedic-Maths-Linear-Quadratic-and-Cubic-Polynomials",
    content: `
     <p>Vedic Mathematics offers various techniques to handle different types of polynomials. Here are some Vedic Math techniques and principles that can help with linear, quadratic, and cubic polynomials:</p>
    <h4>Linear Polynomials</h4>
    <p>For solving linear equations of the form  ax+b=0:</p>
    <strong style="font-size: smaller;">1. Simple Transposition:</strong><br>
    <p>Solve for x by isolating it on one side of the equation.</p>
    <p>Example: 2x + 3=0 implies x=− 3 / 2 </p>
    <h4>Quadratic Polynomials</h4>
    <p>For solving quadratic equations of the form ax<sup>2</sup>+bx+c=0 , Vedic Mathematics offers the following techniques:</p>
    <strong>1. Sutra Method:</strong>
    <p> Factorize the quadratic equation if possible.</p>
    <p> Use the "Vertically and Crosswise" method to solve quadratic equations by breaking them into simpler parts.</p>
    <p> Example: Solve x<sup>2</sup>+5x+6=0 by factoring into  (x+2)(x+3)=0, so  x=−2 and x=−3. </p>
    <strong>2. Completing the Square: </strong>
    <p>Rearrange the equation to complete the square and solve.</p>
    <p>Example: x<sup>2</sup>+4x+4=(x+2)<sup>2</sup> , so x = -2</p>
    <h4>Cubic Polynomials</h4>
    <p>For solving cubic equations of the form  ax<sup>3</sup> + bx<sup>2</sup> + cx + d =0:</p>
    <strong>1. Factorization:</strong>
    <p>Factor the cubic polynomial if possible.</p>
    <p>Example: Solve x<sup>3</sup> - 6x<sup>2</sup> +  by factoring into (x−1)(x−2)(x−3)=0, so x=1,2,3.</p>
    <strong>2. Vedic Sutras:</strong>
    <p>Use specific Vedic sutras for more complex cubic equations, which may involve breaking down the equation using various Vedic methods like the "Paravartya Yojayet" sutra for substitution and simplification.</p>
     `,
  },
  {
    title: "Cubes In Vedic Mathematics",
    image: vedicImage10,
    id: "Cubes-In-Vedic-Mathematics",
    content: ` 
    <p>Tirthaji Maharaj, Founder of Vedic Mathematics, has classified tricks to cube a number in Specific and General Methods. Specific Method can be applied when numbers satisfy certain conditions like numbers closer to power of 10. While General Method can be applied to any types of numbers.</p>
    <p>Depending on Specific and General Techniques, Cubes in Vedic Mathematics are classified in the form of Sutras as below. Lets see the  Vedic Mathematics Cube techniques.<p>
    <p><strong>1. Anurupyena Sutra (Specific Technique)</strong></p>
    <p><strong>2. Yavadunam Sutra (Specific Technique)</strong></p>
    <h4>Anurupyena Sutra – Shorcuts to Find Cubes using Vedic Mathematics</h4>
    <p>Anurupyena Sutra is shorcut to find cube in Vedic Mathematics. It is general technique which can be applied to any 2 digit number</p>
    <p>(a+b)<sup>3</sup> = a<sup>3</sup> + 3a<sup>2</sup>b + 3ab<sup>2</sup> + b<sup>3</sup></p>
    <div class="image-container">
      <img src="${cube1}" alt="Vedic Maths Cube Example" /><br><br>
      <img src="${cube2}" alt="Vedic Maths Cube Example" /><br><br>
    </div>
    <p>With Anurupyena, we can apply Vinculum Process if digit is greater than 5.</p>
    <h4>Yavadunam – Trick to Cube a Number in Vedic Mathematics:</h4>
    <p>Yavadunam is a shortcut to cube a number using Vedic Mathematics. It is a specific technique which can be applied whenever number is closer to power of 10.
    Example for Vedic Math Cube Tricks:</p>
    <div class="image-container">
      <img src="${cube3}" alt="Vedic Maths Cube Example" /><br><br>
    </div> `,
  },
  {
    title: "Digital roots or Digital Sum of Numbers In Vedic Mathematics",
    image: vedicImage11,
    id: "Digital-roots-or-Digital-Sum-of-Numbers-In-Vedic-Mathematics",
    content: ` 
    <p>The word Digit means the single figure numbers; the numbers from 1 to 9 and zero</p>
    <p>Digital Root or Digital Sum of a number : is the remainder when the number is divided by 9.</p>
    <p>So for 31, the remainder is 4 because 31/ 9 = 3 remainder 4. The digital root is also 4.</p>
    <p>The digital root can also be obtained by summing the digits of the number.</p>
    <strong>For example:</strong><br>
    <p>Digital sum of 26 is 2 + 6 = 8.</p>
    <p>Digital sum of 14 is 1 + 4 = 5.</p>
    <p>Digital sum of 764 is 7 + 6 + 4 =17. And 17 is a 2-digit number and we add the figures in 17 to get 1 + 7 = 8. So digital root of 764 is 8.</p>
    <p>When the sum of digits is greater than 9, you keep adding. So for 3856, the digital root is 3 + 8 + 5 + 6 = 22, 2 + 2 = 4.</p>
    <p>Some facts of digital roots or Digital Sum:</p>
    <p>Adding 9 to a number does not affect its digit sum. So 1,10,19, 28 all have a digit sum of 1.</p>
    <p>Digital sum of 59309 is 5 + 3 + 0 = 8, ignore all 9's</p>
    <p>In terms of digit sums 9 and 0 are equivalent</p>
    <p>Any group of digits in a number that add up to a 9 can also be removed.</p>
    <p>Digit sum of 26701 is 7.</p>
    <p>We see that 2 and 7 which adds up to 9. We can remove 2 and 7 and add up only other digits 6 + 0 + 1 = 7.</p>
  `,
  },
  {
    title: "Divisibility In Vedic Mathematics",
    image: vedicImage12,
    id: "Divisibility-In-Vedic-Mathematics",
    content: ` 
    <p>Divisibility is a fundamental concept in mathematics, and in the realm of Vedic Mathematics, it holds particular significance. Essentially, divisibility refers to the property of a number being evenly divisible by another number, meaning there is no remainder when the division is performed.</p>
    <p>Vedic Mathematics offers a set of elegant techniques that simplify the process of determining divisibility. These techniques are not only efficient but also intuitive, making them invaluable tools for mental calculations and problem-solving.</p>
    <p>Let's explore some key techniques:</p>
    <p><strong>Divisibility by 2: </strong>A number is divisible by 2 if its last digit is even, namely 0, 2, 4, 6, or 8.</p>
    <p><strong>Divisibility by 3: </strong>To check if a number is divisible by 3, sum up its digits. If the resulting sum is divisible by 3, then the original number is also divisible by 3.</p>
    <p><strong>Divisibility by 4: </strong>For divisibility by 4, examine the last two digits of the number. If this two-digit number is divisible by 4, then the entire number is divisible by 4.</p>
    <p><strong>Divisibility by 5: </strong>A number is divisible by 5 if its last digit is either 0 or 5.</p>
    <p><strong>Divisibility by 6: </strong>A number is divisible by 6 if it meets the conditions for both divisibility by 2 and divisibility by 3.</p>
    <p><strong>Divisibility by 9: </strong>To determine divisibility by 9, sum up the digits of the number. If the resulting sum is 9 or a multiple of 9, then the number itself is divisible by 9.</p>
    <p><strong>Divisibility by 10:</strong> Finally, a number is divisible by 10 if its last digit is 0.</p>
  `,
  },
  {
    title: "Vedic Mathematics Techniques for Learning Multiplication Tables",
    image: vedicImage13,
    id: "Vedic-Mathematics-Techniques-for-Learning-Multiplication-Tables",
    content: `
    <p>Vedic Mathematics offers several techniques to simplify arithmetic operations, including multiplication tables. Here are some key techniques:</p>
    <h4>1. Ekadhikena Purvena (By One More than the Previous One)</h4>
    <p>For any integer ending with 5, the square always ends with 25 and begins with the multiple of the previous integer and one more than the integer. For example:</p>
    <p>Square of 25 is 2 x 3 .. 25 = 625.</p>
    <p>Square of 85 is 8 x 9 .. 25 = 7225</p><br><br>
    <h4>2. Nikhilam Navatashcaramam Dashatah (All from 9 and the Last from 10)</h4>
    <p>For numbers close to bases like 10, 100, etc.</p>
    <strong>Steps:</strong>
    <ul>
      <li>Subtract each number from the nearest base.</li>
      <li>Cross-subtract and multiply the differences.</li>
    </ul>
    <strong>Example:</strong>
    <p>98 X 97 = 9506 (Base: 100)</p><br><br>
    <h4>3. Urdhva Tiryak (Vertically and Crosswise)</h4>
    <p>For multiplying two-digit numbers.</p>
    <strong>Steps:</strong>
    <ul>
      <li>Multiply the digits in the ones place.</li>
      <li>Cross-multiply and add.</li>
      <li>Multiply the digits in the tens place.</li>
    </ul>
    <strong>Example:</strong>
    <p>12 X 13 = 156</p><br><br>
    <h4>4. Doubling and Halving</h4>
    <p>Simplifies multiplication by converting numbers.</p>
    <strong>Steps:</strong>
    <ul>
      <li>Double one number and halve the other.</li>
    </ul>
    <strong>Example:</strong>
    <p>8 X 7 = 16 X 3.5 = 56</p><br><br>
    <h4>5. Base Multiplication</h4>
    <p>Involves multiplying numbers by adjusting them to a nearby base.</p>
    <strong>Steps:</strong>
    <ul>
      <li>Adjust both numbers relative to a base.</li>
      <li>Apply the base multiplication formula.</li>
    </ul>
    <strong>Example:</strong>
    <p>98 X 12 = 1176 (Base: 100)</p>
    <p>These techniques help in efficiently learning and recalling multiplication tables, making calculations quicker and easier.</p>
  `,
  },
  {
    title: "Digits sum Vedic Maths",
    image: vedicImage14,
    id: "Digits-sum-Vedic-Maths",
    content: `
    <strong>Digits sum Vedic Maths: </strong><br><p>The word digit means a single figure number: The numbers 1, 2, 3, 4, 5, 6, 7, 8, 9, 0 are all digits. Big numbers can be reduced to single digit by adding the constituents.</p>
    <strong>Digit Sum:</strong>
    <p>A digit sum is the sum of all the digits of a number and is found by adding all of the digits of a number</p>
    <ul>
      <li>The digit sum of 35 is 3 + 5 = 8</li>
      <li> The digit sum of 142 is 1 + 4 + 2 = 7</li>
      <li> Note : If the sum of the digits is greater than 9, then sum the digits of the result again until the result</li>
      <li> is less than 10.</li>
      <li> The digit of 57 is 5 + 7 = 12 → 1 + 2 = 3 (greater than 9, so need to add again</li>
      <li> Hence the digit sum of 57 is 3.</li>
      <li> The digit sum of 687 is 6 + 8 + 7 = 21 → 2 + 4 = 3</li>
      <li> Hence the digit sum of 687 is 3.</li>
    </ul>
    <strong>Note:</strong>
    <p>● Keep finding the digit sum of the result + until it’s less than 10 </p>
     <p>● 0 and 9 are equivalent </p>
     <p>Look and understand some more example : </p>
    <p>To find the digit sum of 18, for the example we just add 1 and 8, i.e.1 + 8 =9 so the digit sum of
    18 is 9. And the digit sum of 234 is 9 because 2 + 3+ 4 = 9 </p>`,
  },
  {
    title: "Number System Vedic Maths",
    image: vedicImage15,
    id: "Number-System-Vedic-Maths",
    content: `
    <p><b>Number System </b>is a method of representing numbers on the number line with the help of a set of Symbols and rules. These symbols range from 0-9 and are termed as digits. Let’s learn about the number system in detail, including its types, and conversion.</p>
    <h4>Number System in Maths</h4>
    <p>Number system in Maths is a writing system for expressing numbers. It is a mathematical notation for representing numbers of a given set, consistently using digits or other symbols. It allows us to perform arithmetic operations like addition, subtraction, multiplication, and division.</p>
    <h4>Types of Number System</h4>
    <p>Based on the base value and the number of allowed digits, number systems are of many types.</p>
    <p>The four common types of Number System are:</p>
    <strong>1. Decimal Number System</strong><br>
    <strong>2. Binary Number System</strong><br>
    <strong>3. Octal Number System</strong><br>
    <strong>4. Hexadecimal Number System</strong><br><br>
    <h4>Decimal Number System</h4>
    <p>Number system with base value 10 is termed as Decimal number system. It uses 10 digits i.e. 0-9 for the creation of numbers.</p>
    <p>Here, each digit in the number is at a specific place with place value a product of different powers of 10. The place value is termed from right to left as first place value called units, second to the left as Tens, so on Hundreds, Thousands, etc. Here, units has the place value as 100, tens has the place value as 101, hundreds as 102, thousands as 103, and so on. </p>
    <p>For example: 10285 has place values as</p>
    <p>(1 × 10<sup>4</sup>) + (0 × 10<sup>3</sup>) + (2 × 10<sup>2</sup>) + (8 × 10<sup>1</sup>) + (5 × 10<sup>0</sup>)</p>
    <p>1 × 10000 + 0 × 1000 + 2 × 100 + 8 × 10 + 5 × 1</p>
    <p>10000 + 0 + 200 + 80 + 5</p>
    <p>10285</p>
    <h4>Binary Number System</h4>
    <p> Number System with base value 2 is termed as Binary number system. It uses 2 digits i.e. 0 and 1 for the creation of numbers. The numbers formed using these two digits are termed as Binary Numbers.</p>
    <p> Binary number system is very useful in electronic devices and computer systems because it can be easily performed using just two states ON and OFF i.e. 0 and 1. </p>
    <p>Decimal Numbers 0-9 are represented in binary as: 0, 1, 10, 11, 100, 101, 110, 111, 1000, and 1001</p>
    <strong> Examples:</strong>
    <p>14 can be written as 1110</p>
    <p>19 can be written as 10011</p>
    <p> 50 can be written as 110010</p>
    <h4>Octal Number System</h4>
    <p>Octal Number System is one in which the base value is 8. It uses 8 digits i.e. 0-7 for creation of Octal Numbers. Octal Numbers can be converted to Decimal value by multiplying each digit with the place value and then adding the result. Here the place values are 80, 81, and 82. Octal Numbers are useful for the representation of UTF8 Numbers. </p>
    <strong>Example: </strong>
    <p>(135)<sub>10</sub> can be written as (207)<sub>8</sub></p>
    <p>(215)<sub>10</sub> can be written as (327)<sub>8</sub></p>
    <h4>Hexadecimal Number System</h4>
    <p>Number System with base value 16 is termed as Hexadecimal Number System. It uses 16 digits for the creation of its numbers. Digits from 0-9 are taken like the digits in the decimal number system but the digits from 10-15 are represented as A-F i.e. 10 is represented as A, 11 as B, 12 as C, 13 as D, 14 as E, and 15 as F. Hexadecimal Numbers are useful for handling memory address locations. </p>
    <strong>Examples: </strong>
    <p>(255)<sub>10</sub> can be written as (FF)<sub>16</sub></p>
    <p>(1096)<sub>10</sub> can be written as (448)<sub>16</sub></p>
    <p>(4090)<sub>10</sub> can be written as (FFA)<sub>16</sub></p>
`,
  },
  {
    title: "Algebraic Multiplication using Vedic Maths",
    image: vedicImage16,
    id: "Algebraic-Multiplication-using-Vedic-Maths",
    content: `
      <p>Lets example to understand this method(x + 3) (3x + 4)</p>
      <strong>Step I</strong>  <p>  Write one binomial under the other binomial as shown underx + 33x + 4 
      Multiply the digits on right side column vertically3× 4 = 12 </p>
      <strong>Step 2</strong>  <p> x + 33x + 4 </p>
      <p> Now multiply cross wise and add. Shown as under(x × 4) + (3x × 3) </p>
      <p>4x + 9x = 13x </p>
      <strong>Step 3</strong>  <p>  x + 33x + 4 </p>
      <p>Now vertically multiply on left hand side columnx × 3x = 3x2</p>
      <p>So, The answer is = 3x2 + 13x + 12</p>
      <h4> Two Trinomials using (3 × 3)</h4>
       <p>Lets another example to understand it</p>
       <p>(2x2 + x + 5) (3x2 + 2x + 6)</p>
      <strong>Step 1</strong>
      <p> Write one trinomial under the other as shown below</p>
       <p>2x2 + x + 5</p>
       <p>3x2 + 2x + 6</p>
       <p>On left hand side column , multiply vertically</p>
       <p>2x2 ×  3x2 = 6x4</p>
      <strong>Step 2</strong>
       <p>Now Cross wise multiplication with first two columns</p>
       <p>2x2 + x + 5</p>
       <p>3x2 + 2x + 6</p>
       <p>= (2x2 × 2x)  + (3x2 × x)</p>
       <p>= 4x3 +  3x3</p>
       <p>= 7x3</p>
      <strong>Step 3</strong>
       <p>Now multiply cross wise extreme left and extreme right columns</p>
       <p>Multiply Middle Column vertically</p>
       <p>And add the all three products. As shown below</p>
       <p>2x2 + x + 5</p>
       <p>3x2 + 2x + 6</p>
       <p>= (2x2 × 6)  + (3x2 × 5) + ( x × 2x)</p>
       <p>= 12x2 + 15x2 + 2x2</p>
       <p>= 29x2</p>
      <strong>Step 4</strong>
       <p>Now multiply cross wise middle column with extreme last column (right side)</p>
       <p>2x2 + x + 5</p>
       <p>3x2 + 2x + 6</p>
       <p>= (2x × 5)  + (x × 6) </p>
       <p>10x + 6x</p>
       <p>= 16x</p>
      <strong>Step 5</strong> 
       <p>Right Hand side Vertically Multiplication </p>
       <p>2x2 + x + 5</p>
       <p>3x2 + 2x + 6</p>
       <p>= 5 × 6 </p>
       <p>= 30</p>
       <p>Answer = 6x4 + 7x3 + 29x2 + 16x + 30</p>

  `,
  },
];

export default vedicMathData;
