import React from "react";
import { Helmet } from "react-helmet";

const MultiplicationMetas = () => {
  return (
    <div>
      <Helmet>
        <title>Multiplication | Fun Math Activity</title>
        <meta charSet="UTF-8" />
        <meta name="title" content="Multiplication | Fun Math Activity" />
        <meta
          name="description"
          content="Engage with fun multiplication activities on Mathspeedup.com! Improve your math skills while having fun."
        />
        <meta
          name="keywords"
          content="Multiplication, Multiplication games, Math activities, Fun math games, Educational games, Learning through play, Math practice, Multiplication practice, Times tables games, Math learning resources"
        />
        <meta
          property="article:published_time"
          content="2024-03-09T00:00:00+00:00"
        />
      </Helmet>
    </div>
  );
};

export default MultiplicationMetas;
