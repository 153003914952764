import React, { useState } from "react";
import CreateRoomButton from "./create_room_button";
import Dropdown from "../question_dropdown";

const CreateRoomSection = ({
  roomID,
  userName,
  questionType,
  questionTypeChangeHandler,
  handleCreateRoomClick,
  isChecked,
  setIsChecked,
  isLoading,
}) => {
  const [numberOfQuestions, setNumberOfQuestions] = useState(10);
  const [numberOfRounds, setNumberOfRounds] = useState(1);

  const handleNumberOfQuestionsChange = (event) => {
    setNumberOfQuestions(event.target.value);
  };

  const handleNumberOfRoundsChange = (event) => {
    setNumberOfRounds(event.target.value);
  };

  const handleCreateRoomButtonClick = () => {
    handleCreateRoomClick(numberOfQuestions, numberOfRounds);
  };

  return (
    <>
      {!roomID && userName && (
        <div className="container">
          <div className="row align-items-center"></div>
          <div className="row">
            <div className="col-md-6 offset-md-3 text-center">
              <label htmlFor="question_type" style={{ display: "flex" }}>
                Question Type
              </label>
              <Dropdown
                questionType={questionType}
                questionTypeChangeHandler={questionTypeChangeHandler}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6 offset-md-3">
              <label htmlFor="numberOfQuestions" style={{ display: "flex" }}>
                Number of Questions
              </label>
              <input
                type="number"
                id="numberOfQuestions"
                name="numberOfQuestions"
                value={numberOfQuestions}
                onChange={handleNumberOfQuestionsChange}
                className="form-control text-left"
                placeholder="Enter number of questions"
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6 offset-md-3">
              <label htmlFor="numberOfRounds" style={{ display: "flex" }}>
                Number of Rounds
              </label>
              <input
                type="number"
                id="numberOfRounds"
                name="numberOfRounds"
                value={numberOfRounds}
                onChange={handleNumberOfRoundsChange}
                className="form-control text-left"
                placeholder="Enter number of rounds"
              />
            </div>
          </div>
          <div className="col-md-6 offset-md-3 text-center">
            <p style={{ display: "inline-block", marginRight: "10px" }}>
              include URL in Global Chat
            </p>
            <label className="switch" style={{ display: "inline-block" }}>
              <input
                type="checkbox"
                checked={isChecked}
                id="checkbox"
                name="checkbox"
                onChange={(e) => setIsChecked(e.target.checked)}
              />
              <span className="slider"></span>
            </label>
          </div>
          <div className="row mt-3">
            <div className="col-md-6 offset-md-3 text-center">
              <CreateRoomButton
                handleCreateRoomClick={handleCreateRoomButtonClick}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateRoomSection;
