import React from "react";

const RulesSection = () => {
  return (
    <>
      <div className="container">
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#howToCreateRoom"
                aria-expanded="false"
                aria-controls="howToCreateRoom"
              >
                How To Create Game
              </button>
            </h2>
            <div
              id="howToCreateRoom"
              className="accordion-collapse collapse"
              aria-labelledby="howToCreateRoom"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul>
                  <li>
                    <h5>Step 1: Host Creates a Game</h5>
                    <div>
                      <p>
                        The game starts with the host creating a Game. To create
                        a Quoridor Game, the host can Click the "Create
                        Quoridor" button.
                      </p>
                      <p>
                        After creating the Game, the host can click the "Copy
                        URL to Clipboard" button to copy the room link and share
                        it with friends and family.
                      </p>
                    </div>
                  </li>
                  <li>
                    <h5>Step 2: Only One Participant Join the Game</h5>
                    <p>
                      Participant can join the Game by accessing the game URL .
                      They will need to provide their name and click the
                      "Submit" button.
                    </p>
                  </li>
                  <li>
                    <h5>Step 3: Gameplay Rules</h5>
                    <div>
                      <p>Once the game begins, the following rules apply:</p>
                      <ol>
                        <li>
                          Only two participants can play: the host and one other
                          participant.
                        </li>
                        <li>
                          The host shares the game URL with the other
                          participant.
                        </li>
                        <li>
                          The other participant joins by submitting their name
                          through the provided form.
                        </li>
                        <li>
                          After joining, both participants can see their pawn
                          and the number of walls (fans) they have.
                        </li>
                        <li>The host takes the first turn.</li>
                        <li>
                          Each participant is given 5 walls (fans) to place or
                          move.
                        </li>
                        <li>
                          Participants take turns either placing a wall or
                          moving their pawn.
                        </li>
                        <li>
                          Only one action (placing a wall or moving a pawn) is
                          allowed per turn.
                        </li>
                        <li>
                          If a participant uses up all 5 walls, they cannot
                          place any more.
                        </li>
                        <li>
                          The participant who reaches the opposite side first
                          wins the game.
                        </li>
                        <li>
                          If a participant's pawn is blocked and cannot move,
                          the game is a draw.
                        </li>
                      </ol>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="challengeFriendsHeader">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#challengeFriends"
                aria-expanded="false"
                aria-controls="challengeFriends"
              >
                Challenge Friends and Family
              </button>
            </h2>
            <div
              id="challengeFriends"
              className="accordion-collapse collapse"
              aria-labelledby="challengeFriends"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <section>
                  <h2>Challenge Friends and Family</h2>
                  <p>
                    Once your game is ready, it's time to invite your loved ones
                    to join the fun.
                  </p>
                  <ul>
                    <li>Share the unique game URL with friends and family.</li>
                    <li>Enjoy flexible gameplay at your own pace.</li>
                  </ul>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RulesSection;
