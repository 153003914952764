const Features = () => {
  return (
    <>
      <section id="features" className="features">
        <div className="container" data-aos="fade-up">
          <div className="row" data-aos="zoom-in" data-aos-delay="100">
            <div className="col-lg-3 col-md-4 mt-4 mt-md-0">
              <div className="icon-box" style={{ justifyContent: "center" }}>
                <i className="ri-group-line" style={{ color: "#419f81" }}></i>
                <h3>
                  <a href="/player-vs-player" title="Against Player">
                    Against Player
                  </a>
                </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4 mt-md-0">
              <div className="icon-box" style={{ justifyContent: "center" }}>
                <i
                  className="ri-bar-chart-box-line"
                  style={{ color: "#5578ff" }}
                ></i>
                <h3>
                  <a href="/practice" title="Practice Area">
                    Practice Area
                  </a>
                </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4 mt-md-0">
              <div className="icon-box" style={{ justifyContent: "center" }}>
                <i
                  className="ri-calendar-todo-line"
                  style={{ color: "#e80368" }}
                ></i>
                <h3>
                  <a href="/games/memorygame" title="Memory Games">
                    Memory Games
                  </a>
                </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4 mt-lg-0">
              <div className="icon-box" style={{ justifyContent: "center" }}>
                <i
                  className="ri-hourglass-line"
                  style={{ color: "#e361ff" }}
                ></i>
                <h3>
                  <a href="/against_time" title="Against Time">
                    Against Time
                  </a>
                </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box" style={{ justifyContent: "center" }}>
                <i
                  className="ri-questionnaire-line"
                  style={{ color: "#a31c72" }}
                ></i>
                <h3>
                  <a href="/missing-number" title="Missing Number">
                    Missing Number
                  </a>
                </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box" style={{ justifyContent: "center" }}>
                <i className="ri-book-line" style={{ color: "#ffbb2c" }}></i>
                <h3>
                  <a href="/readers" title="Readers Area">
                    Readers Area
                  </a>
                </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box" style={{ justifyContent: "center" }}>
                <i className="ri-edit-2-fill" style={{ color: "#c643fa" }}></i>
                <h3>
                  <a href="/quiz" title="Quiz">
                    Quiz
                  </a>
                </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box" style={{ justifyContent: "center" }}>
                <i
                  className="ri-contacts-line"
                  style={{ color: "#19bf4b" }}
                ></i>
                <h3>
                  <a href="/contact" title="Contact">
                    Contact
                  </a>
                </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box" style={{ justifyContent: "center" }}>
                <i
                  className="ri-thumb-up-line"
                  style={{ color: "#eb5228" }}
                ></i>
                <h3>
                  <a href="/feedback" title="Feedback">
                    Feedback Area
                  </a>
                </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box" style={{ justifyContent: "center" }}>
                <i className="ri-close-line" style={{ color: "#e80368" }}></i>
                <h3>
                  <a href="/games/multiplication" title="Multiplication">
                    Multiplication Area
                  </a>
                </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box" style={{ justifyContent: "center" }}>
                <i
                  className="ri-dashboard-line"
                  style={{ color: "#eb5228" }}
                ></i>
                <h3>
                  <a href="/games/quoridor_game" title="QuoridorGame">
                    Quoridor Game
                  </a>
                </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box" style={{ justifyContent: "center" }}>
                <i
                  className="ri-calculator-line"
                  style={{ color: "#34a4eb" }}
                ></i>
                <h3>
                  <a
                    href="/games/arithmetic-memory-game"
                    title="Arithmetic Memory Game"
                  >
                    Arithmetic Memory Game
                  </a>
                </h3>
              </div>
            </div>
            {/*
            <div className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box" style={{ justifyContent: "center" }}>
                <i className="ri-gradienter-line" style={{ color: '#ffa76e' }}></i>
                <h3><a href="/" title="Eiusmod Tempor">Eiusmod Tempor</a></h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box" style={{ justifyContent: "center" }}>
                <i className="ri-file-list-3-line" style={{ color: '#11dbcf' }}></i>
                <h3><a href="/" title="Midela Teren">Midela Teren</a></h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box" style={{ justifyContent: "center" }}>
                <i className="ri-price-tag-2-line" style={{ color: '#4233ff' }}></i>
                <h3><a href="/" title="Pira Neve">Pira Neve</a></h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box" style={{ justifyContent: "center" }}>
                <i className="ri-anchor-line" style={{ color: '#b2904f' }}></i>
                <h3><a href="/" title="Dirada Pack">Dirada Pack</a></h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box" style={{ justifyContent: "center" }}>
                <i className="ri-disc-line" style={{ color: '#b20969' }}></i>
                <h3><a href="/" title="Moton Ideal">Moton Ideal</a></h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box" style={{ justifyContent: "center" }}>
                <i className="ri-base-station-line" style={{ color: '#ff5828' }}></i>
                <h3><a href="/" title="Verdo Park">Verdo Park</a></h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box" style={{ justifyContent: "center" }}>
                <i className="ri-fingerprint-line" style={{ color: '#29cc61' }}></i>
                <h3><a href="/" title="Flavor Nivelanda">Flavor Nivelanda</a></h3>
              </div>
            </div>
            */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Features;
