import React, { useState, useEffect, useRef } from "react";
import { child } from "firebase/database";
import Header from "./header";
import Input from "./input";
import Messages from "./messages";
import "../../../assets/css/chat_style.css";
import GlobalChat from "../../p2p/global_chat";
import RoomChat from "../../p2p/room_chat";
import {
  getData,
  pushData,
  updateData,
  subscribeToData,
} from "../../firebase/firebase_methods";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../firebaseConfig";
import { fetchIpAddress } from "../../common/fetch_ip";
import { trackButtonClick } from "../../../pages/event_track";
import Cookies from "js-cookie";

const CommonChat = ({ chatRef, userName, onClose, unreadCount, chat }) => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [isChatOpen, setIsChatOpen] = useState(true);
  const [isRoomChatOpen, setIsRoomChatOpen] = useState(false);
  const [isGlobalChatOpen, setIsGlobalChatOpen] = useState(false);
  const [roomID, setRoomID] = useState();
  const [user, setUser] = useState();
  const [userId, setUserId] = useState();
  const [userIP, setUserIP] = useState(null);
  const [siteSettingValue, setSiteSettingValue] = useState(
    Cookies.get("global-chat") || ""
  );
  const [activeTab, setActiveTab] = useState(chat);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.displayName) {
          setUser(user.displayName || "");
          setUserId(user.uid);
        } else if (user.isAnonymous) {
          setUser(user.uid);
          setUserId(user.uid);
        }
      } else {
        setUser("");
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    setRoomID(query.get("roomID"));
  }, []);

  const handleReadMessages = async () => {
    try {
      const snapshot = await getData(chatRef);
      if (snapshot.exists()) {
        const messagesData = snapshot.val();
        const updatedMessages = [];

        for (const messageId in messagesData) {
          if (messagesData.hasOwnProperty(messageId)) {
            const message = messagesData[messageId];

            const userAlreadyRead = (message.readBy || []).includes(userName);

            if (!userAlreadyRead) {
              const updatedMessage = {
                ...message,
                readBy: [...(message.readBy || []), userName],
              };

              const messageRef = child(chatRef, messageId);
              await updateData(messageRef, updatedMessage);

              updatedMessages.push(updatedMessage);
            } else {
              updatedMessages.push(message);
            }
          }
        }

        setMessages(updatedMessages);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const handleRoomChatClick = () => {
    trackButtonClick("Room Chat");
    setActiveTab("room-chat");
    setIsRoomChatOpen(!isRoomChatOpen);
    setIsGlobalChatOpen(false);
  };

  const handleGlobalChatClick = () => {
    trackButtonClick("Global Chat");
    setActiveTab("global-chat");
    setIsGlobalChatOpen(!isGlobalChatOpen);
    setIsRoomChatOpen(false);
  };

  const handleChatCloseForBoth = () => {
    onClose();
    setIsChatOpen(false);
    setIsRoomChatOpen(false);
    setIsGlobalChatOpen(false);
  };

  const MAX_MESSAGES = 20;
  const chatContainerRef = useRef(null);

  useEffect(() => {
    const unsubscribe = subscribeToData(chatRef, (data) => {
      if (data) {
        setMessages(Object.values(data).slice(-MAX_MESSAGES));
      } else {
        setMessages([]);
      }
    });
    return () => unsubscribe();
  }, [chatRef]);

  const sendMessage = () => {
    if (message.trim() !== "") {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString();
      const sender = userName ? userName : "anonymous";
      const newMessage = {
        sent_by: sender,
        message: message,
        sent_at: formattedDate,
        original_id: userId,
        userIP: userIP,
      };
      pushData(chatRef, newMessage);
      setMessage("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      fetchIpAddress(setUserIP);
      sendMessage();
    }
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleChatClose = () => {
    trackButtonClick("Chat Close");
    setIsChatOpen(false);
    onClose();
    setIsRoomChatOpen(false);
    setIsGlobalChatOpen(false);
  };

  const handleSignIn = () => {
    window.location.href = "/sign-up";
  };

  return (
    <div
      className={`chat-box ${isChatOpen ? "" : "hidden"}`}
      onClick={handleReadMessages}
    >
      <Header handleChatClose={handleChatClose} />
      <div className="chat-options">
        {siteSettingValue === "enable" ? (
          <>
            <span
              onClick={handleGlobalChatClick}
              className={`tab ${activeTab === "global-chat" ? "active" : ""}`}
            >
              Global Chat{" "}
              {unreadCount > 0 && (
                <span className="unread-count" style={{ marginLeft: "2px" }}>
                  {unreadCount}
                </span>
              )}
            </span>
          </>
        ) : (
          <></>
        )}
        {window.location.pathname.includes("player-vs-player") && (
          <span
            onClick={handleRoomChatClick}
            className={`tab ${activeTab === "room-chat" ? "active" : ""}`}
          >
            Room Chat
          </span>
        )}
      </div>
      {isRoomChatOpen && (
        <RoomChat
          roomID={roomID}
          userName={userName}
          onClose={handleChatCloseForBoth}
        />
      )}
      {isGlobalChatOpen && (
        <GlobalChat
          userName={userName}
          onClose={handleChatCloseForBoth}
          setMessages={setMessages}
        />
      )}
      <Messages
        messages={messages}
        userName={userName}
        chatContainerRef={chatContainerRef}
      />
      {user ? (
        <>
          <Input
            message={message}
            setMessage={setMessage}
            sendMessage={sendMessage}
            handleKeyPress={handleKeyPress}
          />
        </>
      ) : (
        <>
          <div className="signin-input" onClick={handleSignIn}>
            <h5>Sign in to start conversation</h5>
          </div>
        </>
      )}
    </div>
  );
};

export default CommonChat;
