import React from 'react';
import { Helmet } from 'react-helmet';

const AboutMetas = () => {
  return (
    <div>
      <Helmet>
        <title>Increase math calculation speed | About us</title>
        <meta charSet="UTF-8" /> 
        <meta name="description" content="Elevate math skills with MathSpeedUp.com. Boost calculation speed, memory games, diverse practice options, and thrilling time challenges await. Join us!" />
        <meta name="title" content="Improve your Skills | Math Speed Up | Practice Maths" />
        <meta name="description" content="Math Speed Up is the application that will help you to practice maths problems. You can choose type of math questions for competitive exam preparation." />
        <meta name="keywords" content="random missing number series, how to increase calculation speed, increase math calculation speed, math Speed Up, Quick calculations, Maths games, Memory based games, math practice, Math, Speed, Speed Maths, Practice, Shortcuts, Mental maths, Special Techniques" />
        <meta property="article:published_time" content="2023-08-13T19:43:57+00:00" />
        <link rel="canonical" href="https://www.mathspeedup.com/about" hreflang="en"/>
      </Helmet>
    </div>
  );
};

export default AboutMetas;
