import React from "react";

const RulesSection = () => {
  return (
    <>
      <div className="container">
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#howToCreateRoom"
                aria-expanded="false"
                aria-controls="howToCreateRoom"
              >
                How To Create Room
              </button>
            </h2>
            <div
              id="howToCreateRoom"
              className="accordion-collapse collapse"
              aria-labelledby="howToCreateRoom"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul>
                  <li>
                    <h5>Step 1: Host Creates a Quiz</h5>
                    <div>
                      <p>
                        The game starts with the host creating a room. To create
                        a room, the host can:
                      </p>
                      <ol>
                        <li>
                          Host will click on the 'Create Quiz' button to create
                          a quiz.
                        </li>
                        <li>
                          Select a question type from the available options.
                        </li>
                        <li>Enter the question and answer.</li>
                        <li>
                          Host can change question type for each question.
                        </li>
                        <li>
                          After adding a question, the host can either add more
                          questions or save the quiz.
                        </li>
                        <li>Click the "Save Quiz" button.</li>
                      </ol>
                      <p>
                        After creating the quiz, the host can click the "Copy
                        URL to Clipboard" button to copy the room link and share
                        it with friends and family.
                      </p>
                    </div>
                  </li>

                  <li>
                    <h5>Step 2: Participants Join the Room</h5>
                    <p>
                      Participants can join the room by accessing the game URL .
                      They will need to provide their name and click the
                      "Submit" button. Once a participant joins, they need to
                      click "Ready" button.
                    </p>
                  </li>
                  <li>
                    <h5>Step 3: Host Starts the Game</h5>
                    <p>
                      The host can start the game by clicking the "Start" button
                      when all participants are ready.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="previousQuizHeader">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#previousQuiz"
                aria-expanded="false"
                aria-controls="previousQuiz"
              >
                Previous Quiz
              </button>
            </h2>
            <div
              id="previousQuiz"
              className="accordion-collapse collapse"
              aria-labelledby="previousQuiz"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                  If host want to view results or details of previous quizzes,
                  select the 'Previous Quiz' option.
                </p>
                <p>
                  Upon selecting a previous quiz, host will have the option to
                  choose between viewing questions or participants.
                </p>
                <p>
                  If host select participants, host will see the answers to each
                  question provided by the participants.
                </p>
                <p>
                  If host select questions, participant will see the answers
                  provided by each participant for each question.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="joinQuizHeader">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#joinQuiz"
                aria-expanded="false"
                aria-controls="joinQuiz"
              >
                Join Quiz
              </button>
            </h2>
            <div
              id="joinQuiz"
              className="accordion-collapse collapse"
              aria-labelledby="joinQuiz"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                  To join an ongoing quiz, select the 'Join Quiz' option and
                  follow the instructions.
                </p>
                <p>Participant will see a list of available quizzes.</p>
                <p>
                  Once participant have selected a quiz, participant will be
                  redirected to join that quiz.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RulesSection;
