import React from 'react';
import { Helmet } from 'react-helmet';

const HomePageMetas = () => {
  return (
    <div>
      <Helmet>
        <title>MathSpeedUp | Way to increase math calculation speed.</title>
        <meta charSet="UTF-8" /> 
        <meta name="title" content="Improve your Skills | Math Speed Up | Practice Maths" />
        <meta name="description" content="Math Speed Up is the application that will help you to practice maths problems. You can choose type of math questions for competitive exam preparation." />
        <meta name="keywords" content="random missing number series, how to increase calculation speed, increase math calculation speed, math Speed Up, Quick calculations, Maths games, Memory based games, math practice, Math, Speed, Speed Maths, Practice, Shortcuts, Mental maths, Special Techniques" />
        <meta property="article:published_time" content="2023-08-13T19:43:57+00:00" />
      </Helmet>
    </div>
  );
};

export default HomePageMetas;
