import React from "react";
import { getAuth, signInAnonymously } from "firebase/auth";
import { handleError } from "../components/common/error_method";
import { useNavigate } from "react-router-dom";
import "../assets/css/google-guest-login.css";
import { sendNotification } from "../components/notification_ref";
import { trackButtonClick } from "./event_track";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";

const LoginAsGuest = () => {
  const navigate = useNavigate();

  const handleGuestLogin = () => {
    trackButtonClick("Guest Login");

    const auth = getAuth();
    signInAnonymously(auth)
      .then(async (userCredential) => {
        const user = userCredential.user;
        const uid = user.uid;
        localStorage.setItem("userName", uid);
        localStorage.setItem("toastMessage", "login successful!");
        await sendNotification(
          uid,
          "welcome! You've successfully logged in as guest."
        );

        navigate("/practice");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        handleError("Error signing in anonymously:" + errorCode + errorMessage);
      });
  };

  return (
    <div className="google-guest-login">
      <span className="google-guest-login-text" onClick={handleGuestLogin}>
        <FontAwesomeIcon icon={faUserAlt} />
        <span> Login as Guest</span>
      </span>
    </div>
  );
};

export default LoginAsGuest;
