import React, { useEffect } from "react";
import { databaseKey } from "../common/database_key";
import { fetchData, subscribeToData } from "../firebase/firebase_methods";

const Countdown = ({ roomID, roomStatus, setCountdown, countdown }) => {
  let collectionName = databaseKey();
  useEffect(() => {
    const countdownRef = fetchData(
      `${collectionName}/rooms/${roomID}/countdown`
    );

    const unsubscribe = subscribeToData(countdownRef, (snapshot) => {
      const countdownValue = snapshot;
      if (countdownValue !== null) {
        setCountdown(countdownValue);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [roomID]);

  useEffect(() => {
    if (roomStatus === "inProgress" && countdown > 0) {
      const timer = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [roomStatus, countdown]);

  return (
    <div className="countdown">
      {roomStatus === "inProgress" && countdown > 0 ? (
        <h2>Get Ready! Starting in {countdown} seconds...</h2>
      ) : null}
    </div>
  );
};

export default Countdown;
