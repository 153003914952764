import React, { useEffect } from "react";

const CurrentQuestion = ({
  currentQuestion,
  setUserAnswer,
  userAnswer,
  ansInputRef,
  handleCheckAnswer,
  countdown,
}) => {
  useEffect(() => {
    if (countdown === 0 && ansInputRef.current) {
      ansInputRef.current.focus();
    }
  }, [countdown, ansInputRef]);

  const handleChangeAnswer = (e) => {
    setUserAnswer(e.target.value);
    handleCheckAnswer(e.target.value);
  };

  return (
    <>
      {currentQuestion && countdown === 0 && (
        <>
          <h1>Question: {currentQuestion}</h1>
          <input
            className="form-control"
            type="text"
            placeholder="Enter your answer"
            value={userAnswer}
            ref={ansInputRef}
            onChange={(e) => handleChangeAnswer(e)}
          />
          <br />
          <button
            className="btn btn-success"
            onClick={() => handleCheckAnswer(userAnswer)}
          >
            Submit Answer
          </button>
        </>
      )}
    </>
  );
};

export default CurrentQuestion;
