import React from "react";
import { Helmet } from "react-helmet";

const QuizMeta = () => {
  return (
    <div>
      <Helmet>
        <title>Quiz Time - Test Your Knowledge!</title>
        <meta charSet="UTF-8" />
        <meta name="title" content="Quiz Time - Test Your Knowledge!" />
        <meta
          name="description"
          content="Join our quiz to test your knowledge and challenge yourself! Answer questions, compete with others, and have fun learning."
        />
        <meta
          name="keywords"
          content="quiz, test, knowledge, challenge, learning"
        />
        <meta
          property="article:published_time"
          content="2023-09-17T01:30:57+00:00"
        />
      </Helmet>
    </div>
  );
};

export default QuizMeta;
