import React, { useState, useEffect } from "react";
import { query, limitToLast } from "firebase/database";
import { databaseKey } from "../common/database_key";
import "../../assets/css/join_quiz.css";
import { fetchData, subscribeToData } from "../firebase/firebase_methods";

const JoinQuiz = () => {
  const [messages, setMessages] = useState([]);
  const collectionName = databaseKey();

  useEffect(() => {
    const joinQuizRef = fetchData(`${collectionName}/join_quize/`);
    const lastFiveMessagesQuery = query(joinQuizRef, limitToLast(5));
    const unsubscribe = subscribeToData(lastFiveMessagesQuery, (snapshot) => {
      if (snapshot) {
        const messagesArray = snapshotToArray(snapshot);
        setMessages(messagesArray.reverse());
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const snapshotToArray = (snapshot) => {
    return snapshot ? Object.values(snapshot) : [];
  };

  return (
    <div className="join-quiz-container">
      <div className="links-container">
        {messages.length > 0 ? (
          <>
            <h1 className="join-quiz-heading">Join Quiz Links</h1>
            {messages.map((message, index) => (
              <div key={index} className="link-item">
                <a
                  href={message}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="join-link"
                >
                  Join Quiz {index + 1}
                </a>
              </div>
            ))}
          </>
        ) : (
          <h3>No Join Links Available</h3>
        )}
      </div>
    </div>
  );
};

export default JoinQuiz;
