import { useState } from "react";
import Swal from "sweetalert2";
import Email from "../assets/img/signup/email.webp";
import {
  getAuth,
  updateProfile,
  verifyBeforeUpdateEmail,
  signOut,
  updatePassword,
} from "firebase/auth";
import { handleError } from "./common/error_method";
import { useNavigate } from "react-router-dom";
import ProfileMetas from "../metas/user_profile";

const UpdateEmail = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isAnonymous, setIsAnonymous] = useState();
  const [password, setPassword] = useState("");
  const [name, setName] = useState(localStorage.getItem("userName") || "");

  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[a-z0-9][\w\.]+\@\w+?(\.\w+){1,}$/gi;
    return emailRegex.test(email);
  };

  const handleSaveClick = async () => {
    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    const auth = getAuth();
    const user = auth.currentUser;

    try {
      const currentUserEmail = user.email;

      if (currentUserEmail !== email) {
        await verifyBeforeUpdateEmail(user, email);

        if (isAnonymous && password) {
          await updatePassword(user, password);
        }

        Swal.fire({
          title: "Email Verification Required",
          text: "Please check your email inbox for a verification link. Before clicking 'OK', please verify your email. You will not be able to log in otherwise.",
          icon: "info",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            signOut(auth)
              .then(() => {
                navigate("/sign-up");
              })
              .catch((error) => {
                handleError(error);
              });
          }
        });
      }

      await updateProfile(user, {
        displayName: name,
      });

      localStorage.setItem("userName", name);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <ProfileMetas />
      <main id="main">
        <div className="breadcrumbs" data-aos="fade-in">
          <div className="container">
            <h2>Update Email</h2>
            <p>
              Please enter your new email address and click " Verify Email " A
              verification email will be sent to the new email address. Click
              the URL in the verification email to complete your new email
              update.
            </p>{" "}
          </div>
        </div>
        <div className="sign-up-container">
          <div className="inputs">
            <div className="input">
              <img src={Email} alt="Email" />
              <input
                type="text"
                id="emailInput"
                name="email"
                placeholder="Email"
                value={email}
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="sign-in-error">
            {error && <p className="error-message">{error}</p>}
          </div>{" "}
        </div>
        <div className="fogot-password-submit-button">
          <button
            type="submit"
            className="verify-link"
            onClick={handleSaveClick}
          >
            Verify Email
          </button>
        </div>
      </main>
    </>
  );
};

export default UpdateEmail;
